import React from 'react';
import axios from 'axios';

import './LiveTicketModal.css';

import Persiana from '../../Components/Persiana/Persiana';

import formatNumber from 'accounting-js/lib/formatNumber.js';
import moment from 'moment';
import qs from 'qs';

class LiveTicketModal extends React.Component {

	state = {
		keyboardTicketStatus: {
			initial: null, // Key pressed to know if more, less or normal
			number: 0, // Number pressed to start
			reading: false, // Is reading from serial port
			numberFromSerial: 0, // Readed number from serial
			numberFromSerialFormatted: 0 // Readed number from serial, but formatted
		},
		formData: {},
		persianas: {
			client: false,
			provider: false,
			agricultor: false,
			article: false,
			uniffimix: false,
			internaltr: false,
			transporter: false,
			driver: false
		},
		errors: {},
		incompletedTickets: {},
		copyLiveNumbersToBottom: true
	}

	constructor(props) {
		super(props);

		// Interval
		this.interval = null;

		// Load form data defaults
		this.loadFormDataDefaults();

		// Refs
		this.liveTicketModal = React.createRef();
		this.dateInputRef = React.createRef();
		this.inputWhoRef = React.createRef();
		this.inputWhatRef = React.createRef();
		this.inputTransportersRef = React.createRef();
		this.inputDriversRef = React.createRef();
		this.ticketNumberDropdown = React.createRef();

		// Keyboard listener function bind
		this.keyboardListenerBind = this.keyboardListener.bind(this);
	}

	async componentDidMount() {
		const self = this;

		// Generate new code
		if ( !self.props.ticketId ) self.generateCode();

		// Show modal
		window.$(self.liveTicketModal.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.dateInputRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "dd-mm-yyyy",
			autoclose: true
		}).on('changeDate', (e) => {
	      	self.setState((state) => {
	      		state.formData.date = e.format(null, 'yyyy-mm-dd');
	      		return state;
	      	});
	    });

		// Init ticket keyboard listener
		if ( self.props.live ) document.addEventListener("keyup", self.keyboardListenerBind); 

		// Load data if id
		if ( self.props.ticketId ) self.loadData(self.props.ticketId);
	}

	componentWillUnmount() {
		const self = this;

		// Remove ticket keyboard listener
		document.removeEventListener("keyup", self.keyboardListenerBind);

		// Hide boostrap modal
		window.$(self.liveTicketModal.current).modal('hide');

		// Destroy date picker
		window.$(self.dateInputRef.current).datepicker('method', 'destroy');
	}

	componentDidUpdate() {
		const self = this;

		// Set date on datepicker
		if ( self.state.formData.date ) window.$(self.dateInputRef.current).datepicker('update', moment(self.state.formData.date).format('DD-MM-YYYY'));
	}

	loadFormDataDefaults(withUpdate = false) {
		const self = this;

		// Prepare data
		let data = {
			id: null,
			code: null,
			date: moment().format('YYYY-MM-DD'),
			who_id: null,
			who_input: "",
			who_number: 1,
			what_id: null,
			what_input: "",
			transporter_id: null,
			transporter_input: "",
			driver_id: null,
			driver_input: "",
			driver_mtractora: "",
			gross: "",
			tare: "",
			finished: false
		};
 		
 		// Set
		self.state.formData = data;

		// If with update
		if ( withUpdate ) self.setState((state) => {
			state.formData = data;
			return state;
		});
	}	

	keyboardListener(event) {
		const self = this;

		// If escape
		if ( event.keyCode === 27 ) {
			self.stopSerialPortReadInterval(); // Stop reading on serial port
			return false;
		}

		// If reading, exit
		if ( self.state.keyboardTicketStatus.reading ) return true;

		// Enter pressed
		if ( event.keyCode === 13 ) {
			if ( self.state.keyboardTicketStatus.initial && self.state.keyboardTicketStatus.number ) {
				self.setState((state) => {
					state.keyboardTicketStatus.reading = true
					return state;
				});
				self.initSerialPortReadInterval(); // Init reading on serial port
				return false;
			}
		}

		// Initial pressed
		if ( event.keyCode === 111 || event.keyCode === 109 || event.keyCode === 107 ) {
			self.state.keyboardTicketStatus.initial = event.keyCode; // Set key code
			self.state.keyboardTicketStatus.number = 0; // Reset number
		}
		
		// Number pressed
		if ( !isNaN(event.key) || event.key === ".") self.state.keyboardTicketStatus.number += "" + event.key;
	}

	ceil(number = 0) {
		// Convert to string
		number = number.toString();

		// Get last && anterior
		let last = parseInt(number.slice(-1));
		let anterior = parseInt(number.slice(-2).substring(0, 1));

		// Check and do things
		if ( !isNaN(anterior) ) {
			// Check if >= 5 or < 5
			if ( last >= 5 ) number = parseInt(number.substring(0, number.length - 1) + '0')+10;
			if ( last < 5 ) number = number.substring(0, number.length - 1) + '0';
		}

		// Return
		return parseInt(number);
	}

	initSerialPortReadInterval() {
		const self = this;

		// Create interval
		self.interval = setInterval(() => {
			let result = window.ipcRenderer.sendSync('readSerialPort', null);
			console.log(result);

			// Ok
			if ( result.status === 1 && result.msg !== -999 ) {
				// Number to set
				let numberToSet = parseInt(result.msg);
	
				// Check initial and number
				let numberPressed = parseFloat( parseFloat(self.state.keyboardTicketStatus.number) / 100 );
				switch(self.state.keyboardTicketStatus.initial) {
					case 109: numberToSet = parseInt(numberToSet - (numberToSet * numberPressed)); break; // -
					case 107: numberToSet = parseInt(numberToSet + (numberToSet * numberPressed)); break; // +
					default: break;
				}

				// Round to up
				numberToSet = self.ceil(numberToSet);

				// Set to gross or tare inputs
				try {
					if ( self.state.copyLiveNumbersToBottom ) {
						if ( !self.state.formData.id ) { // Gross
							self.setState((state) => {
								state.formData.gross = numberToSet;
								return state;
							});
						} else { // Tare
							self.setState((state) => {
								state.formData.tare = numberToSet;
								return state;
							});
						}
					}
				} catch (err) {}

				// Set to state
				self.setState((state) => {					
					state.keyboardTicketStatus.numberFromSerial = numberToSet;
					state.keyboardTicketStatus.numberFromSerialFormatted = formatNumber(numberToSet, {precision: 0, thousand: ".", decimal: ""});
					return state;
				});

				// If zero, stop reading
				if ( numberToSet <= 0 && self.state.formData.what !== 'semilla' ) {
					self.closeModal();
					return;
				}
			} 

			// Not working
			if ( result.status === 0 ) {
				window.alert(result.msg); // Show msg
				self.stopSerialPortReadInterval();
			}
		}, 500);
	}

	stopSerialPortReadInterval() {
		const self = this;

		// Update state
		self.setState((state) => {
			state.keyboardTicketStatus.initial = null;
			state.keyboardTicketStatus.number = 0;
			state.keyboardTicketStatus.reading = false;
			state.keyboardTicketStatus.numberFromSerial = 0;
			state.keyboardTicketStatus.numberFromSerialFormatted = 0;
			return state;
		});

		// Clear Interval
		clearInterval(self.interval);
		self.interval = null;
	}

	closeModal() {
		const self = this;

		// Stop reading
		self.stopSerialPortReadInterval();

		// Unmount
		self.props.unmount();
	}

	unmountPersiana(type) {
		const self = this;

		// Get idx by type
		let idx = document.querySelector('input[name='+type+']:checked') ? document.querySelector('input[name='+type+']:checked').value : null;
		if ( !idx ) idx = type; // If not idx, then use type because can be a normal persiana (without using input radios)

		// Change state to close persiana
		self.setState((state) => {
			state.persianas[idx] = false;
			return state;
		});
	}

	radioWhoChanged(type) {
		const self = this;

		// Reset input
		self.setState((state) => {
			state.formData.who = type;
			state.formData.who_input = "";
			state.formData.who_id = null;
			if ( state.formData.what === 'semilla' && type !== 'agricultor' ) state.formData.what = null;
			return state;
		});
	}

	radioWhatChanged(type) {
		const self = this;

		if (type === 'semilla') {
			self.generateCode();

			if ( this.state.formData.who !== 'agricultor' ) {
				alert('Primero selecciona un agricultor');
				return;
			}
		}

		self.setState((state) => {
			state.formData.what = type;
			state.formData.what_input = "";
			state.formData.what_id = null;
			return state;
		});
	}

	inputWhatClickEvent(event) {
		const self = this;

		// If right click, empty field
		if ( event && event.button === 2 ) {
			self.setState((state) => {
				state.formData.what_input = "";
				state.formData.what_id = null;
				return state;
			});
			return false;
		}

		// Get type 
		let type = document.querySelector('input[name=what]:checked') ? document.querySelector('input[name=what]:checked').value : null;
		if ( !type ) {
			window.alert('Primero selecciona una opción para saber que tipo de artículo buscar');
			self.inputWhatRef.current.blur();
			return false;
		}

		// Check who
		let who = document.querySelector('input[name=who]:checked') ? document.querySelector('input[name=who]:checked').value : null;
		if ( !who ) {
			window.alert('Primero selecciona el cliente / proveedor / agricultor');
			self.inputWhatRef.current.blur();
			return false;
		}

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	inputWhatSelectCallback(data) {
		const self = this;

		// Set data
		if ( data !== null ) {
			// Update state
			self.setState((state) => {
				state.formData.what_input = data.description;
				state.formData.what_id = data.id;
				return state;
			});
		} else { // Allow to write
			// Update state
			self.setState((state) => {
				state.formData.what_input = "";
				state.formData.what_id = null;
				return state;
			});

			// Fix timeout to focus
			setTimeout(() => self.inputWhatRef.current.focus(), 100); 
		}
	}

	inputWhatInternalTrafficSelectCallback(data) {
		const self = this;
		if ( data ) data.description += ' - ' + formatNumber(data.internaltraffic_pricebuy, {precision: 2, thousand: ".", decimal: ","}) + '€'
		self.inputWhatSelectCallback(data);
	}

	inputWhatUniffimixSelectCallback(data) {
		const self = this;
		if ( data ) data.description += ' - ' + formatNumber(data.price_total, {precision: 2, thousand: ".", decimal: ","}) + '€'
		self.inputWhatSelectCallback(data);
	}

	inputWhoClickEvent(event) {
		const self = this;

		// If right click, empty field
		if ( event && event.button === 2 ) {
			self.setState((state) => {
				state.formData.who_input = "";
				state.formData.who_id = null;
				return state;
			});
			return false;
		}

		// Get type 
		let type = document.querySelector('input[name=who]:checked') ? document.querySelector('input[name=who]:checked').value : null;
		if ( !type ) {
			window.alert('Primero selecciona una opción para saber a que tipo de persona buscar');
			self.inputWhoRef.current.blur();
			return false;
		}

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	inputWhoSelectCallback(data) {
		const self = this;

		// Set data
		if ( data !== null ) {
			// Update state
			self.setState((state) => {
				state.formData.who_input = data.name;
				state.formData.who_id = data.id;
			});

			// Get incompleted with autoOpen
			self.getIncompletedTickets(true);
		} else { // Allow to write
			// Update state
			self.setState((state) => {
				state.formData.who_input = "";
				state.formData.who_id = null;
			});

			// Fix timeout to focus
			setTimeout(() => self.inputWhoRef.current.focus(), 100);
		}
	}

	inputTransportersClickEvent(event) {
		const self = this;

		// If right click, empty field
		if ( event && event.button === 2 ) {
			self.setState((state) => {
				state.formData.transporter_input = "";
				state.formData.transporter_id = null;
				return state;
			});
			return false;
		}

		// Change state to open persiana
		self.setState((state) => {
			state.persianas['transporter'] = true;
			return state;
		});
	}

	inputTransportersSelectCallback(data) {
		const self = this;

		// Set data
		if ( data !== null ) {
			// Update state
			self.setState((state) => {
				state.formData.transporter_input = data.name;
				state.formData.transporter_id = data.id;
			});
		} else { // Allow to write
			// Update state
			self.setState((state) => {
				state.formData.transporter_input = "";
				state.formData.transporter_id = null;
			});

			// Fix timeout to focus
			setTimeout(() => self.inputTransportersRef.current.focus(), 100);
		}
	}

	inputDriversClickEvent(event) {
		const self = this;

		// If right click, empty field
		if ( event && event.button === 2 ) {
			self.setState((state) => {
				state.formData.driver_input = "";
				state.formData.driver_id = null;
				return state;
			});
			return false;
		}

		// Change state to open persiana
		self.setState((state) => {
			state.persianas['driver'] = true;
			return state;
		});
	}

	inputDriversSelectCallback(data) {
		const self = this;

		// Set data
		if ( data !== null ) {
			// Update state
			self.setState((state) => {
				state.formData.driver_input = data.name;
				state.formData.driver_mtractora = data.mtractora;
				state.formData.driver_id = data.id;
			});
		} else { // Allow to write
			// Update state
			self.setState((state) => {
				state.formData.driver_input = "";
				state.formData.driver_mtractora = "";
				state.formData.driver_id = null;
			});

			// Fix timeout to focus
			setTimeout(() => self.inputDriversRef.current.focus(), 100); 
		}
	}

	async generateCode() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		axios.get(
			window.API_URL + (self.state.formData.what !== "semilla" ? '/basculetickets/generateCode' : '/semillasreceptions/generateSemillaCode'),
			{	
				params: {},
				headers: authHeaders
			}
		).then((response) => {
			self.setState((state) => {
				if ( response.data && response.data.status ) {
					if ( self.state.formData.what !== "semilla" ) state.formData.code = response.data.code;
					else state.formData.semilla_number = response.data.code;
				}
				return state;
			});
		});
	}


	async saveData(event) {
		const self = this;	

		// Prevent
		event.preventDefault();

		// Remove errors
		self.setState((state) => {
			state.errors = {};
			return state;
		});

		// Prepare data
		let data = JSON.parse(JSON.stringify(this.state.formData)); // Copy data
		data.date = moment(data.date).format('YYYY-MM-DD');

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Fix semilla
		if ( data.what === 'semilla' ) {
			data.semilla_origin_gross /= 1000;
			data.semilla_origin_tare /= 1000;
			data.semilla_destination_gross /= 1000;
			data.semilla_destination_tare /= 1000;
		}

		// Ajax
		axios.post(
			window.API_URL + (data.what === 'semilla' ? '/semillasreceptions/save' : '/basculetickets/save'),
			qs.stringify(data),
			{headers: authHeaders}
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;

					// Fix finished
					state.formData.finished = true;

					// Remove focus from save button
					document.querySelector('#save').blur();

					// Stop read from serial if tare was set manually when 
					if ( data.tare ) self.stopSerialPortReadInterval();
				}

				return state;
			});

			// If error who_number, generate a new code because can exists
			if ( response.data.errors['who_number'] ) self.generateCode();

			// Get incompleted tickets again
			self.getIncompletedTickets();

			// If not live, reload parent parrilla
			if ( !self.props.live ) self.props.reloadParentParilla();
		});
	}

	async loadData(id = null, notStopReadSerialPort = false) {
		const self = this;	

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Hide dropdown (if was auto opened because incompleted tickets)
		self.ticketNumberDropdown.current.classList.remove('show');

		// Ajax
		axios.get(
			window.API_URL + (self.state.formData.what !== 'semilla' ? '/basculetickets/get' : '/semillasreceptions/get'),
			{
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {

			if ( response.data && response.data.status && self.state.formData.what !== 'semilla' ) {
				self.setState((state) => {
					state.formData = response.data.data;

					// Fix finished
					state.formData.finished = false;

					// Fix to fill some inputs
					state.formData.date = moment(state.formData.date).format('YYYY-MM-DD');
					state.formData.gross = !response.data.data.gross ? "" : response.data.data.gross;
					state.formData.tare = !response.data.data.tare ? "" : response.data.data.tare;
					state.formData.who_input = response.data.data.who_name;
					state.formData.what_input = response.data.data.what_name;
					if ( response.data.data.article_price ) state.formData.what_input += ' - ' + formatNumber(state.formData.article_price, {precision: 2, thousand: ".", decimal: ","}) + '€';
					state.formData.transporter_input = response.data.data.transporter_obj ? response.data.data.transporter_obj.name : null;
					state.formData.driver_input = response.data.data.driver_obj ? response.data.data.driver_obj.name : null;
					state.formData.driver_mtractora = response.data.data.driver_obj ? response.data.data.driver_obj.mtractora : null;
					
					return state;
				});
			}

			if ( response.data && response.data.status && self.state.formData.what === 'semilla' ) {
				self.setState((state) => {
					state.formData = response.data.data;

					// Fix finished
					state.formData.finished = false;

					// Fix to fill some inputs
					state.formData.id = response.data.data.id ?? '';
					state.formData.semilla_number = response.data.data.number ?? '';
					state.formData.date = moment(state.formData.date).format('YYYY-MM-DD');
					state.formData.who_number = response.data.data.weight_number ?? '';
					state.formData.semilla_origin_parcel_id = response.data.data.origin_parcel_id ?? null;
					state.formData.semilla_origin_parcel_name = response.data.data.origin_parcel ? response.data.data.origin_parcel.place : '';
					state.formData.semilla_origin_gross = (response.data.data.origin_gross ?? 0) * 1000;
					state.formData.semilla_origin_tare = (response.data.data.origin_tare ?? 0) * 1000;
					state.formData.semilla_destination_parcel_id = response.data.data.destination_parcel_id ?? null;
					state.formData.semilla_destination_parcel_name = response.data.data.destination_parcel ? response.data.data.destination_parcel.place : '';
					state.formData.semilla_destination_gross = (response.data.data.destination_gross ?? 0) * 1000;
					state.formData.semilla_destination_tare = (response.data.data.destination_tare ?? 0) * 1000;
					state.formData.who = 'agricultor';
					state.formData.who_id = response.data.data.farmer_id ?? null;
					state.formData.who_input = response.data.data.farmer ? response.data.data.farmer.name : '';
					state.formData.what = 'semilla';
					state.formData.what_id = response.data.data.article_id ?? null;
					state.formData.what_input = response.data.data.article ? response.data.data.article.description : '';
					state.formData.transporter_input = response.data.data.transporter ? response.data.data.transporter.name : '';
					state.formData.driver_input = response.data.data.driver ? response.data.data.driver.name : '';
					state.formData.driver_mtractora = response.data.data.driver ? response.data.data.driver.mtractora : '';
					
					return state;
				});
			}

			// Stop serial port
			if ( !notStopReadSerialPort ) self.stopSerialPortReadInterval();

			// Get incompleted tickets
			self.getIncompletedTickets();
		});
	}

	async getIncompletedTickets(autoOpen = false) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		axios.get(
			window.API_URL + (self.state.formData.what !== 'semilla' ? '/basculetickets/getIncompleted' : '/semillasreceptions/getIncompleted'),
			{	
				params: {
					who: self.state.formData.who,
					who_id: self.state.formData.who_id
				},
				headers: authHeaders
			}
		).then((response) => {
			// Set state
			self.setState((state) => {
				if ( response.data && response.data.status ) state.incompletedTickets = response.data.data;
				return state;
			});

			// AutoOpen
			if ( autoOpen ) {
				// Show dropdown
				if ( Object.keys(response.data.data).length > 0 ) self.ticketNumberDropdown.current.classList.add('show');
			}
		});
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	selectNewNumber(number) {
		const self = this;

		// Hide dropdown (if was auto opened because incompleted tickets)
		self.ticketNumberDropdown.current.classList.remove('show');

		// Update state
		self.setState((state) => {
			state.formData.id = null;
			state.formData.who_number = number;
			return state;
		});

		// Get ticket
		self.generateCode();
	}

	adelanteClickHandler() {
		const self = this;

		// If not live, do nothing
		if ( !self.props.live ) return false;

		// Do actions
		self.loadFormDataDefaults(true); 
		self.generateCode();
	}

	calcNet() {
		const self = this;

		// Get data
		let grossValue = parseInt(self.state.formData.gross) || 0;
		let tareValue = parseInt(self.state.formData.tare) || 0;

		// Get greater and less value
		let greater = grossValue > tareValue ? grossValue : tareValue;
		let less = grossValue < tareValue ? grossValue : tareValue;

		// Calc
		let net = greater - less;

		// Update net
		return self.formatNumberThousands(net);
	}

	formatNumberThousands(number) {
		// Delete points
		number = (number+"").replace(/\./g,'');
		
		// If number, format
		if ( number && !isNaN(number) ) return formatNumber(number, {precision: 0, thousand: ".", decimal: ""});

		// If empty, return again
		return number;
	}

	handleInputThousandsChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value.replace(/\./g,'').replace(/\D/g, '');

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	async deleteTicket(event, semilla = false) {
		const self = this;	

		// Prevent default
		event.preventDefault();

		// ask
		let c = window.confirm('¿Seguro que quieres eliminar este documento?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		axios.post(
			window.API_URL + (!semilla ? '/basculetickets/delete' : '/semillasreceptions/delete'),
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			if ( response.data && response.data.status && response.data.deleted ) {
				self.props.unmount();
				self.props.reloadParentParilla();
			} else {
				window.alert("No se ha podido eliminar el documento");
			}
		});
	}

	async printTicket(event, semilla = false) {
		const self = this;

		// prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + (!semilla ? '/basculetickets/ticketPdf' : '/semillasreceptions/pdf'), {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data.pdfData, printer: res.data.printer});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	async printLabel(event) {
		const self = this;

		// prevent
		event.preventDefault();

		// Check if uniffimix
		if ( self.state.formData.what !== "uniffimix" ) {
			window.alert('Este tipo de artículo no tiene etiqueta');
			return false;
		}

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/basculetickets/labelPdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	btnPlayHandler(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Check if has class
		let hasClass = event.currentTarget.classList.contains('fakedisabled');
		if ( !hasClass ) event.currentTarget.classList.add('fakedisabled');
		else event.currentTarget.classList.remove('fakedisabled');

		// Update state
		self.setState((state) => {
			state.copyLiveNumbersToBottom = !hasClass ? false : true;
			return state;
		});

		// Blur
		event.currentTarget.blur();
	}

	selectSemillaParcela(type, data) {
		const self = this;
		
		self.setState((state) => {
			state.formData['semilla_' + type + '_parcel_name'] = data?.place ?? '';
			state.formData['semilla_' + type + '_parcel_id'] = data?.id;
			return state;
		});
	}

	render() {
		const self = this;

		// Prepare incompleted tickets
		let ticketNumbers = [];
		for(let c=1; c<=9; c++) {
			ticketNumbers.push(
				<a 
					key={c}
					className="dropdown-item" 
					href="/" 
					onClick={(event) => { 
						event.preventDefault();
						if ( self.state.incompletedTickets.hasOwnProperty(c) ) self.loadData(self.state.incompletedTickets[c].id, true);
						else self.selectNewNumber(c);
					}}
				>
					{ c } { self.state.incompletedTickets.hasOwnProperty(c) ? ' - ' + self.state.incompletedTickets[c].what_name : '' }
				</a>
			);
		}

		// Return
		return (
			<React.Fragment>
				{ self.state.persianas.client === true &&
					<Persiana 
						title="Clientes" 
						url={window.API_URL + '/clients/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('who')} 
						selectCallback={(data) => self.inputWhoSelectCallback(data)} 
						canCreate={'clients'}
					/>
				}
				{ self.state.persianas.provider === true &&
					<Persiana 
						title="Proveedores" 
						url={window.API_URL + '/providers/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('who')} 
						selectCallback={(data) => self.inputWhoSelectCallback(data)} 
						canCreate={'providers'}
					/>
				}
				{ self.state.persianas.agricultor === true &&
					<Persiana 
						title="Agricultores" 
						url={window.API_URL + '/providers/all'} 
						params={{agro: true}}
						fields={['name']} 
						unmount={() => self.unmountPersiana('who')} 
						selectCallback={(data) => self.inputWhoSelectCallback(data)} 
						canCreate={'providers-agro'}
					/>
				}
				{ self.state.persianas.article === true &&
					<Persiana 
						title="Artículos" 
						url={window.API_URL + '/articles/all'} 
						fields={['description']} 
						unmount={() => self.unmountPersiana('what')} 
						selectCallback={(data) => self.inputWhatSelectCallback(data)} 
						canCreate={'articles'}
					/>
				}
				{ self.state.persianas.semilla === true &&
					<Persiana 
						title="Artículos" 
						url={window.API_URL + '/articles/all'} 
						params={{semilla: true}}
						fields={['description']} 
						unmount={() => self.unmountPersiana('what')} 
						selectCallback={(data) => self.inputWhatSelectCallback(data)} 
						canCreate={'semilla'}
					/>
				}
				{ self.state.persianas.uniffimix === true &&
					<Persiana 
						title="Uniffi" 
						url={window.API_URL + '/uniffimixes/all' + (self.state.formData.who === "client" && self.state.formData.who_id ? '?client_id=' + self.state.formData.who_id : '')} 
						fields={['code', 'description', 'price_total']} 
						unmount={() => self.unmountPersiana('what')} 
						fieldsAlign={{
							'price_total': 'right'
						}}
						fieldsCallback={{
							'price_total': (price) => {
								return formatNumber(price, {precision: 2, thousand: ".", decimal: ","})
							}
						}}
						selectCallback={(data) => self.inputWhatUniffimixSelectCallback(data)} 
					/>
				}
				{ self.state.persianas.internaltr === true &&
					<Persiana 
						title="Tráfico Agro" 
						url={window.API_URL + '/articles/all'} 
						params={{internal_traffic: true}}
						fields={['description', 'internaltraffic_pricebuy']} 
						fieldsAlign={{
							'internaltraffic_pricebuy': 'right'
						}}
						fieldsCallback={{
							'internaltraffic_pricebuy': (price) => {
								return formatNumber(price, {precision: 2, thousand: ".", decimal: ","})
							}
						}}
						unmount={() => self.unmountPersiana('what')} 
						selectCallback={(data) => self.inputWhatInternalTrafficSelectCallback(data)} 
						canCreate={'internaltr'}
					/>
				}
				{ self.state.persianas.transporter === true &&
					<Persiana 
						title="Transportistas" 
						url={window.API_URL + '/transporters/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('transporter')} 
						selectCallback={(data) => self.inputTransportersSelectCallback(data)} 
						canCreate={'transporters'}
					/>
				}
				{ self.state.persianas.driver === true &&
					<Persiana 
						title="Conductores" 
						url={window.API_URL + '/drivers/all' + (self.state.formData.who === 'agricultor' ? '?tractorista=true' : '')} 
						fields={['name', 'mtractora']} 
						unmount={() => self.unmountPersiana('driver')} 
						selectCallback={(data) => self.inputDriversSelectCallback(data)} 
						dataFilter={(data) => {
							return data.filter((el, idx) => {
								return el.name.trim().length > 0;
							});
						}}
						canCreate={'drivers'}
					/>
				}
				{ self.state.persianas.parcel &&
					<Persiana 
						title="Parcelas" 
						url={window.API_URL + '/parcels/all'} 
						params={{farmer_id: self.state.formData.who === 'agricultor' ? self.state.formData.who_id : ''}}
						fields={['place', 'parcel', 'polygon', 'municipality']} 
						unmount={() => self.unmountPersiana('parcel')} 
						selectCallback={(data) => self.selectSemillaParcela(self.state.persianas.parcel, data)} 
						canCreate={'parcel'}
					/>
				}
				<div className="modal" tabIndex="-1" role="dialog" id="liveTicketModal" ref={self.liveTicketModal}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.4 Ticket</h5>
								<button type="button" className="close" onClick={(event) => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									{ self.props.live !== false && 	
										<React.Fragment>
											<div className="col-md-8">
												<input type="text" className="basculeLiveText" value={self.state.keyboardTicketStatus.numberFromSerialFormatted} readOnly />
											</div>
											<div className="col-md-4 pesaje">
												<a href="/" className="btn btn-default" style={{marginRight: '50px'}} onClick={(event) => self.btnPlayHandler(event)}><i className="fa fa-play"></i></a>
												Kilos
											</div>
											<div className="clearfix"></div>
										</React.Fragment>
									}

									<div className="col-md-8" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-4 text-end">
												<div className="form-group radioWrapper">
													<label htmlFor="client">Cliente</label>
													<input type="radio" name="who" value="client" onChange={() => self.radioWhoChanged('client')} checked={self.state.formData.who === "client"} />
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group radioWrapper">
													<label htmlFor="provider">Proveedor</label>
													<input type="radio" name="who" value="provider" onChange={() => self.radioWhoChanged('provider')} checked={self.state.formData.who === "provider"} />
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group radioWrapper">
													<label htmlFor="agricultor">Agricultor</label>
													<input type="radio" name="who" value="agricultor" onChange={() => self.radioWhoChanged('agricultor')} checked={self.state.formData.who === "agricultor"} />
												</div>
											</div>
											{ self.state.errors.who && 
												<div className="col-md-12">
													<div className="error text-center">{ self.state.errors.who[Object.keys(self.state.errors.who)[0]] }</div>
												</div>
											}
										</div>
									</div>

									<div className="col-md-4" style={{marginTop: '20px'}}>
										<div className="form-group">
											<label htmlFor="date">Fecha</label>
											<input type="text" className="form-control text-right" id="date" ref={self.dateInputRef} />
											{ self.state.errors.date && 
												<div className="error">{ self.state.errors.date[Object.keys(self.state.errors.date)[0]] }</div>
											}
										</div>
									</div>

									<div className="col-md-8" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-3">
												<div className="form-group radioWrapper">
													<label htmlFor="article">Artículo</label>
													<input type="radio" name="what" value="article" onChange={() => self.radioWhatChanged('article')} checked={self.state.formData.what === "article"} />
												</div>
											</div>
											<div className="col-md-3">
												<div className="form-group radioWrapper">
													<label htmlFor="uniffimix">Uniffi</label>
													<input type="radio" name="what" value="uniffimix" onChange={() => self.radioWhatChanged('uniffimix')} checked={self.state.formData.what === "uniffimix"} />
												</div>
											</div>
											<div className="col-md-3">
												<div className="form-group radioWrapper">
													<label htmlFor="semilla">Semilla</label>
													<input type="radio" name="what" value="semilla" onChange={() => self.radioWhatChanged('semilla')} checked={self.state.formData.what === "semilla"} />
												</div>
											</div>
											<div className="col-md-3">
												<div className="form-group radioWrapper">
													<label htmlFor="internaltr">Trafico Agro</label>
													<input type="radio" name="what" value="internaltr" onChange={() => self.radioWhatChanged('internaltr')} checked={self.state.formData.what === "internaltr"} />
												</div>
											</div>
											{ self.state.errors.what && 
												<div className="col-md-12">
													<div className="error text-center">{ self.state.errors.what[Object.keys(self.state.errors.what)[0]] }</div>
												</div>
											}
										</div>
									</div>

									<div className="col-md-4" style={{marginTop: '20px', visibility: self.state.formData.what === "semilla" ? 'hidden' : 'visible'}}>
										<div className="form-group">
											<label htmlFor="ticket">Ticket</label>
											<input type="text" className="form-control text-right" id="ticket" defaultValue={self.state.formData.code} disabled />
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left" style={{fontSize: '12px'}}>Cliente / Proveedor</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control " + (self.state.formData.who_id !== null || !self.state.formData.who_input.length ? 'disabled' : '')} ref={self.inputWhoRef} onChange={(event) => self.handleInputChange(event, 'who_input')} onClick={(event) => self.inputWhoClickEvent(event)} onContextMenu={(event) => self.inputWhoClickEvent(event)} value={self.state.formData.who_input} />
													<div className="input-group-append">
														<button type="button" className="btn btn-default" style={{width: '42px'}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
															{ self.state.formData.who_number }
														</button>
														<div className="dropdown-menu dropdown-menu-right" ref={self.ticketNumberDropdown}>
															{ ticketNumbers }
														</div>
													</div>
												</div>
												{ self.state.errors.who_number && 
													<div className="error">{ self.state.errors.who_number[Object.keys(self.state.errors.who_number)[0]] }</div>
												}
												{ self.state.errors.who_id && 
													<div className="error">{ self.state.errors.who_id[Object.keys(self.state.errors.who_id)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Artículo</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control " + (self.state.formData.what_id !== null || !self.state.formData.what_input.length ? 'disabled' : '')} ref={self.inputWhatRef} onChange={(event) => self.handleInputChange(event, 'what_input')} onClick={(event) => self.inputWhatClickEvent(event)} onContextMenu={(event) => self.inputWhatClickEvent(event)}  value={self.state.formData.what_input} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={(event) => self.inputWhatClickEvent(event)}><i className="fa fa-search"></i></button>
													</div>
												</div>
												{ self.state.errors.what_id && 
													<div className="error">{ self.state.errors.what_id[Object.keys(self.state.errors.what_id)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Transportista</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control " + (self.state.formData.transporter_id !== null || !self.state.formData.transporter_input.length ? 'disabled' : '')} ref={self.inputTransportersRef} onChange={(event) => self.handleInputChange(event, 'transporter_input')} onClick={(event) => self.inputTransportersClickEvent(event)} onContextMenu={(event) => self.inputTransportersClickEvent(event)} value={self.state.formData.transporter_input} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.inputTransportersClickEvent()}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Conductor</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control " + (self.state.formData.driver_id !== null || !self.state.formData.driver_input.length ? 'disabled' : '')} style={{maxWidth: '150px'}} onChange={(event) => self.handleInputChange(event, 'driver_mtractora')} value={self.state.formData.driver_mtractora} onClick={(event) => self.inputDriversClickEvent(event)} onContextMenu={(event) => self.inputDriversClickEvent(event)} />
													<input type="text" className={"form-control " + (self.state.formData.driver_id !== null || !self.state.formData.driver_input.length ? 'disabled' : '')} ref={self.inputDriversRef} onChange={(event) => self.handleInputChange(event, 'driver_input')} onClick={(event) => self.inputDriversClickEvent(event)} onContextMenu={(event) => self.inputDriversClickEvent(event)} value={self.state.formData.driver_input} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.inputDriversClickEvent()}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="clearfix"></div>


									{ self.state.formData.what !== 'semilla' &&
										<React.Fragment>
											<div className="col-md-4 totals" style={{marginTop: '20px'}}>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">Bruto</span>
													</div>
													<input type="text" name="gross" value={self.formatNumberThousands(self.state.formData.gross)} onChange={(event) => self.handleInputThousandsChange(event, 'gross')} className="form-control text-right" id="gross" />				
												</div>
											</div>

											<div className="col-md-4 totals" style={{marginTop: '20px'}}>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">Tara</span>
													</div>
													<input type="text" name="tare" value={self.formatNumberThousands(self.state.formData.tare)} onChange={(event) => self.handleInputThousandsChange(event, 'tare')} className="form-control text-right" id="tare" />				
												</div>
											</div>

											<div className="col-md-4 totals" style={{marginTop: '20px'}}>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">Neto</span>
													</div>
													<input type="text" name="net" value={self.calcNet()} className="form-control text-right" id="net" disabled />				
												</div>
											</div>
										</React.Fragment>
									}

									{ self.state.formData.what === 'semilla' &&
										<React.Fragment>
											<div className="col-md-12" style={{marginTop: '20px'}}>
												<div className="row">
													<div className="col-md-2">
														<label>Origen</label>
														<input type="text" name="semilla_number" value={self.state.formData.semilla_number ?? ''} className="form-control text-right" readOnly />				
													</div>
													<div className="col-md-4">
														<label>Parcela</label>
														<div className="input-group">
															<input 
																type="text" 
																className="form-control" 
																value={self.state.formData.semilla_origin_parcel_name ?? ''} 
																onFocus={(event) => {
																	// Change state to open persiana
																	self.setState((state) => {
																		state.persianas['parcel'] = 'origin';
																		return state;
																	});
																}} 
																readOnly
															/>
															<div className="input-group-append">
																<button 
																	className="btn btn-default" 
																	type="button" 
																	onClick={(event) => {
																		// Change state to open persiana
																		self.setState((state) => {
																			state.persianas['parcel'] = 'origin';
																			return state;
																		});
																	}} 
																>
																	<i className="fa fa-search"></i>
																</button>
															</div>
														</div>
														{ self.state.errors.semilla_origin_parcel_id && 
															<div className="error">{ self.state.errors.semilla_origin_parcel_id[Object.keys(self.state.errors.semilla_origin_parcel_id)[0]] }</div>
														}
													</div>
													<div className="col-md-2">
														<label>Bruto</label>
														<input 
															type="text" 
															className="form-control text-right"
															name="semilla_origin_gross" 
															value={self.state.formData.semilla_origin_gross ? self.formatNumberThousands(self.state.formData.semilla_origin_gross) : 0} 
															onChange={(event) => self.handleInputThousandsChange(event, 'semilla_origin_gross')} 
															onClick={(event) => {
																event.target.value = self.state.keyboardTicketStatus.numberFromSerial;
																self.handleInputThousandsChange(event, 'semilla_origin_gross');
															}}
														/>		
													</div>
													<div className="col-md-2">
														<label>Tara</label>
														<input 
															type="text" 
															className="form-control text-right" 
															name="semilla_origin_tare" 
															value={self.state.formData.semilla_origin_tare ? self.formatNumberThousands(self.state.formData.semilla_origin_tare) : 0} 
															onChange={(event) => self.handleInputThousandsChange(event, 'semilla_origin_tare')} 
															onClick={(event) => {
																event.target.value = self.state.keyboardTicketStatus.numberFromSerial;
																self.handleInputThousandsChange(event, 'semilla_origin_tare');
															}}
														/>		
													</div>
													<div className="col-md-2">
														<label>Entrada</label>
														<input type="text" name="semilla_origin_tare" value={self.formatNumberThousands((self.state.formData.semilla_origin_gross ?? 0) - (self.state.formData.semilla_origin_tare ?? 0))} className="form-control text-right" readOnly />		
													</div>
												</div>
											</div>
											<div className="col-md-12" style={{marginTop: '20px'}}>
												<div className="row">
													<div className="col-md-2">
														<label>Destino</label>
														<input type="text" name="semilla_number" value={self.state.formData.semilla_number ?? ''} className="form-control text-right" readOnly />				
													</div>
													<div className="col-md-4">
														<label>Parcela</label>
														<div className="input-group">
															<input 
																type="text" 
																className="form-control" 
																value={self.state.formData.semilla_destination_parcel_name ?? ''} 
																onFocus={(event) => {
																	// Change state to open persiana
																	self.setState((state) => {
																		state.persianas['parcel'] = 'destination';
																		return state;
																	});
																}} 
																readOnly
															/>
															<div className="input-group-append">
																<button 
																	className="btn btn-default" 
																	type="button" 
																	onClick={(event) => {
																		// Change state to open persiana
																		self.setState((state) => {
																			state.persianas['parcel'] = 'destination';
																			return state;
																		});
																	}} 
																>
																	<i className="fa fa-search"></i>
																</button>
															</div>
														</div>
														
													</div>
													<div className="col-md-2">
														<label>Bruto</label>
														<input 
															type="text" 
															className="form-control text-right"
															name="semilla_destination_gross" 
															value={self.state.formData.semilla_destination_gross ? self.formatNumberThousands(self.state.formData.semilla_destination_gross) : 0} 
															onChange={(event) => self.handleInputThousandsChange(event, 'semilla_destination_gross')} 
															onClick={(event) => {
																event.target.value = self.state.keyboardTicketStatus.numberFromSerial;
																self.handleInputThousandsChange(event, 'semilla_destination_gross');
															}}
														/>		
													</div>
													<div className="col-md-2">
														<label>Tara</label>
														<input 
															type="text" 
															className="form-control text-right" 
															name="semilla_destination_tare" 
															value={self.state.formData.semilla_destination_tare ? self.formatNumberThousands(self.state.formData.semilla_destination_tare) : 0} 
															onChange={(event) => self.handleInputThousandsChange(event, 'semilla_destination_tare')} 
															onClick={(event) => {
																event.target.value = self.state.keyboardTicketStatus.numberFromSerial;
																self.handleInputThousandsChange(event, 'semilla_destination_tare');
															}}
														/>		
													</div>
													<div className="col-md-2">
														<label>Salida</label>
														<input type="text" name="semilla_destination_tare" value={self.formatNumberThousands((self.state.formData.semilla_destination_gross ?? 0) - (self.state.formData.semilla_destination_tare ?? 0))} className="form-control text-right" readOnly />		
													</div>
												</div>
											</div>
										</React.Fragment>
									}

								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" id="save" className="btn btn-default float-right" onClick={(event) => self.saveData(event)}><i className="fa fa-save"></i></button>

								{ self.state.formData.id &&
									<React.Fragment>
										
										{ self.state.formData.what !== 'semilla' && 
											<button type="button" className="btn btn-default float-left" tabIndex="-1" onClick={(event) => self.deleteTicket(event, self.state.formData.what === 'semilla')}><i className="fa fa-minus-circle"></i></button>
										}
										
										<button type="button" className="btn btn-default float-right" onClick={(event) => self.printTicket(event, self.state.formData.what === 'semilla')}><i className="fa fa-print"></i></button>
										
										{ self.state.formData.what !== 'semilla' &&
											<button type="button" className="btn btn-default float-right" onClick={(event) => self.printLabel(event)}><i className="fa fa-tags"></i></button>
										}

										{ self.state.formData.finished &&
											<div className="adelante" onClick={() => self.closeModal()}>ADELANTE</div>
										}
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default LiveTicketModal;