import React from 'react';
import axios from 'axios';
import moment from 'moment'

import Persiana from '../../Components/Persiana/Persiana';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

class AgriculturalReceptionsModal extends React.Component {

	dataToSave = {
		id: '',
		date: '',
		ticket: '',
		provider_id: '',
		provider_name: '',
		article_id: '',
		article_name: '',
		tons: '',
		price_buy: '',
		observations: ''
	}

	state = {
		formData: {...this.dataToSave},
		persianas: {
			provider: false,
			article: false
		},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.modalInputDateRef = React.createRef();
		this.modalInputTicketRef = React.createRef();
		this.modalInputProviderRef = React.createRef();
		this.modalInputArticleRef = React.createRef();
		this.modalInputTonsRef = React.createRef();
		this.modalInputPriceBuyRef = React.createRef();
		this.modalInputObservationsRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.modalInputDateRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "dd-mm-yyyy",
			autoclose: true
		}).on('changeDate', (e) => {
	      	self.setState((state) => {
	      		state.formData.date = e.format(null, 'yyyy-mm-dd');
	      		return state;
	      	});
	    });

		// Get data
		if ( self.props.id != null ) self.loadModalData(self.props.id);
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.modalRef.current).modal('hide');

		// Destroy date picker
		window.$(self.modalInputDateRef.current).datepicker('method', 'destroy');
	}

	componentDidUpdate() {
		const self = this;

		// Set date on datepicker
		if ( self.state.formData.date ) window.$(self.modalInputDateRef.current).datepicker('update', moment(self.state.formData.date).format('DD-MM-YYYY'));
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		await axios.get(
			window.API_URL + '/agriculturalreceptions/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// Update state
			self.setState((state) => {
				// Set data
				state.formData = response.data.data;

				// Fix to fill some inputs
				state.formData.date = moment(state.formData.date).format('YYYY-MM-DD');
				state.formData.provider_name = state.formData.provider ? state.formData.provider.name : '';
				state.formData.article_name = state.formData.article ? state.formData.article.description : '';

				return state;
			});

			// Numbers Fix
			self.modalInputTonsRef.current.value = formatNumber(response.data.data.tons, {precision: 3, thousand: ".", decimal: ","});
			self.modalInputPriceBuyRef.current.value = formatNumber(response.data.data.price_buy, {precision: 2, thousand: ".", decimal: ","});
		});
	}

	async deleteModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Ask
		let c = window.confirm('¿Seguro que quieres eliminar esta recepción?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/agriculturalreceptions/delete',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			self.closeModal();

			// Reload parrilla
			self.loadParrillaData();
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];
		data.tons = formatNumber(data.tons, {precision: 3, thousand: "", decimal: "."});
		data.price_buy = formatNumber(data.price_buy, {precision: 2, thousand: "", decimal: "."});

		// Save ajax
		axios.post(
			window.API_URL + '/agriculturalreceptions/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	handleInputTonsChange(event) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		self.modalInputTonsRef.current.value = formatNumber(value, {precision: 3, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData['tons'] = value;
			return state;
		});
	}

	handleInputPriceBuyChange(event) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		self.modalInputPriceBuyRef.current.value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData['price_buy'] = value;
			return state;
		});
	}

	mountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	inputProviderSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.provider_id = data ? data.id : '';
			state.formData.provider_name = data ? data.name : '';
			return state;
		});
	}

	inputArticleSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.article_id = data ? data.id : '';
			state.formData.article_name = data ? data.description : '';
			return state;
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.provider === true &&
					<Persiana 
						title="Proveedores" 
						url={window.API_URL + '/providers/all'} 
						fields={['name']} 
						params={{agro: true}}
						unmount={() => self.unmountPersiana('provider')} 
						selectCallback={(data) => self.inputProviderSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.article === true &&
					<Persiana 
						title="Artículos" 
						url={window.API_URL + '/articles/all'} 
						fields={['description']} 
						unmount={() => self.unmountPersiana('article')} 
						selectCallback={(data) => self.inputArticleSelectCallback(data)} 
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.1 Recepción Agro</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="date">Fecha</label>
											<input type="text" id="date" className="form-control text-right" ref={self.modalInputDateRef} onChange={(event) => self.handleInputChange(event, 'date')}  value={self.state.formData.date} />
										</div>
										{ self.state.errors.date && 
											<div className="error">{ self.state.errors.date[Object.keys(self.state.errors.date)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="ticket">Ticket</label>
											<input type="text" id="ticket" className="form-control text-right" ref={self.modalInputTicketRef} onChange={(event) => self.handleInputChange(event, 'ticket')}  value={self.state.formData.ticket} />
										</div>
										{ self.state.errors.ticket && 
											<div className="error">{ self.state.errors.ticket[Object.keys(self.state.errors.ticket)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="provider_name">Proveedor</label>
											<div className="input-group">
												<input type="text" id="provider_name" className="form-control" ref={self.modalInputProviderRef} value={self.state.formData.provider_name} onFocus={() => self.mountPersiana('provider')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('provider')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.provider_id && 
											<div className="error">{ self.state.errors.provider_id[Object.keys(self.state.errors.provider_id)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="article_name">Artículo</label>
											<div className="input-group">
												<input type="text" id="article_name" className="form-control" ref={self.modalInputArticleRef} value={self.state.formData.article_name} onFocus={() => self.mountPersiana('article')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('article')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.article_id && 
											<div className="error">{ self.state.errors.article_id[Object.keys(self.state.errors.article_id)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="row">
											<div className="col-md-6 pr-0">
												<div className="form-group">
													<label htmlFor="tons">Toneladas</label>
													<input type="text" id="tons" className="form-control text-right" ref={self.modalInputTonsRef} onBlur={(event) => self.handleInputTonsChange(event)} onClick={(event) => event.target.select()} />
													{ self.state.errors.tons && 
														<div className="error">{ self.state.errors.tons[Object.keys(self.state.errors.tons)[0]] }</div>
													}
												</div>
											</div>
											<div className="col-md-6 pl-0">
												<div className="form-group">
													<label htmlFor="price_buy">Precio</label>
													<input type="text" id="price_buy" className="form-control text-right" ref={self.modalInputPriceBuyRef} onBlur={(event) => self.handleInputPriceBuyChange(event)} onClick={(event) => event.target.select()} />
													{ self.state.errors.price_buy && 
														<div className="error">{ self.state.errors.price_buy[Object.keys(self.state.errors.price_buy)[0]] }</div>
													}
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="observations">Observaciones</label>
											<input type="text" id="observations" className="form-control" ref={self.modalInputObservationsRef} onChange={(event) => self.handleInputChange(event, 'observations')}  value={self.state.formData.observations} />
										</div>
										{ self.state.errors.observations && 
											<div className="error">{ self.state.errors.observations[Object.keys(self.state.errors.observations)[0]] }</div>
										}
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" className="btn btn-default float-right" ref={self.btnSaveRef} onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>

								{ self.state.formData.id &&
									<React.Fragment>
										<button type="button" className="btn btn-default float-left" tabIndex="-1" onClick={(event) => self.deleteModalData(event)}><i className="fa fa-minus-circle"></i></button>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AgriculturalReceptionsModal;
