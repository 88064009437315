import React from 'react';
import axios from 'axios';
import moment from 'moment'

import { withRouter } from "react-router-dom";

import formatNumber from 'accounting-js/lib/formatNumber.js'
import Parrilla from '../../Components/Parrilla/Parrilla';
import LiveTicketModal from './LiveTicketModal';

class BasculeTicketsPage extends React.Component {

	state = {
		rows: [],
		page: 1,
		nextPage: null,
		prevPage: null,
		lastPage: null,
		sort: 'BasculeTickets.code',
		direction: 'DESC',
		field: null,
		query: null,
		ticketKey: 0,
		ticketOpenedId: null
	}

	async componentDidMount() {
		const self = this;

		// Load data if showing diario
		if ( !self.props.showTicket ) await self.loadData();
	}

	async loadData() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token
		await axios.get(
			window.API_URL + '/basculetickets/list',
			{	
				params: {
					page: self.state.page,
					sort: self.state.sort,
					direction: self.state.direction,
					field: self.state.field,
					query: self.state.query
				},
				headers: authHeaders
			}
		).then((response) => {
			self.setState((state) => {
				if ( response.data ) {
					state.rows = response.data.rows;
					state.page = response.data.page;
					state.nextPage = response.data.nextPage;
					state.prevPage = response.data.prevPage;
					state.lastPage = response.data.lastPage;
				}
				return state;
			});
		});
	}

	parrillaSortCallback(key) {
		const self = this;

		// Update State
		self.state.sort = key;
		self.state.direction = self.state.direction === 'asc' ? 'desc' : 'asc';

		// Reload data
		self.loadData();
	}

	parrillaChangePageCallback(page) {
		const self = this;

		// Set page
		self.state.page = page;

		// Reload data
		self.loadData();
	}

	parrillaSearchCallback(field, query) {
		const self = this;

		// Set page
		self.state.field = field;
		self.state.query = query;

		// Reload data
		self.loadData();
	}

	openLiveTicketModal(rowIdx = null) {
		const self = this;

		// Get id if rowIdx
		let id = null;
		if ( rowIdx !== null ) id = self.state.rows[rowIdx].id;

		// Update state
		self.setState((state) => {
			state.ticketKey = state.ticketKey + 1;
			state.ticketOpenedId = id;
			return state;
		});
	}

	unmountLiveTicketModal() {
		const self = this;

		// Close
		self.setState((state) => {
			state.ticketKey = 0;
			state.ticketOpenedId = null;
			return state;
		});

		// Reload data if showing diario
		if ( !self.props.showTicket ) self.loadData();
	}

	render() {
		const self = this;

		// Prepare header
		let header = [
			{ text: 'Cliente/Proveedor', key: 'BasculeTickets.who_name', width: 100 },
			{ text: 'Artículo', key: 'BasculeTickets.what_name', width: 100 },
			{ text: 'Fecha', key: 'BasculeTickets.date', width: 100 },
			{ text: 'Ticket', key: 'BasculeTickets.code', width: 100 },
			{ text: 'Neto', key: 'BasculeTickets.net', width: 100 },
			{ text: 'Estado', key: 'finished', width: 100 },
		];

		// Prepare rows
		let rows = [];
		if ( self.state.rows ) self.state.rows.forEach((el, idx) => {
			let tmpRow = [];
			tmpRow.push(
				{text: el.who_name, align: 'left'},
				{text: el.what_name, align: 'left'},
				{text: moment(el.date).format('DD-MM-Y'), align: 'right'},
				{text: el.code, align: 'right'},
				{text: formatNumber(el.net, {precision: 0, thousand: ".", decimal: ""}), align: 'right'},
				{
					text: (() => {
						let colorClass = "danger";
						if ( parseInt(el.finished) === 2 ) colorClass = "success";
						return <span className={"btn btn-sm btn-" + colorClass}><i className={"fa fa-bullseye"}></i></span>
					})(), 
					align: 'center'
				},
			);
			rows.push(tmpRow);
		});

		// Prepare searchFields
		let searchFields = [
			{ text: 'Cliente/Proveedor', key: 'BasculeTickets.who_name' },
			{ text: 'Artículo', key: 'BasculeTickets.what_name' },
			{ text: 'Ticket', key: 'BasculeTickets.code' },
		];

		// Render
		return (
			<React.Fragment>
				<div style={{display: self.props.showTicket ? "none" : "block"}}>
					<Parrilla 
						title="50.2 Diario"

						header={header} 
						rows={rows} 

						sortCallback={(key) => self.parrillaSortCallback(key)}
						clickRowCallback={(rowIdx) => self.openLiveTicketModal(rowIdx)}

						paginator={{page: self.state.page, prevPage: self.state.prevPage, nextPage: self.state.nextPage, lastPage: self.state.lastPage}}
						pageChangeCallback={(page) => self.parrillaChangePageCallback(page)}

						btnAdd={false}

						btnSearch={true}
						searchFields={searchFields}
						clickSearchCallback={(field, query) => self.parrillaSearchCallback(field, query)}
					></Parrilla>
				</div>
				{ (self.props.showTicket || self.state.ticketOpenedId !== null) &&
					<React.Fragment>
						<div style={{width: '100%', height: '98%', cursor: 'pointer', display: self.props.showTicket ? 'block' : 'none'}} onClick={() => self.openLiveTicketModal()}>
							<img src="img/fondo-parrilla-basculetickets.jpg" alt="background" style={{width: '100%', height: '100%'}} />
						</div>
						{ self.state.ticketKey > 0 &&
							<LiveTicketModal 
								ticketId={self.state.ticketOpenedId} 
								ref={self.liveTicketModalRef} 
								// live={self.props.showTicket ? true : false} 
								live={true} 
								unmount={() => self.unmountLiveTicketModal()} 
								reloadParentParilla={() => self.loadData()}
								key={self.state.ticketKey} 
							/>
						}
					</React.Fragment>
				}
			</React.Fragment>
		);
	}
}

export default withRouter(BasculeTicketsPage);
