import React from 'react';
import axios from 'axios';
import moment from 'moment'

import Persiana from '../../Components/Persiana/Persiana';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

class UniffiMixesOrdersModal extends React.Component {

	dataToSave = {
		id: '',
		date: '',
		code: '',
		client_id: '',
		client_name: '',
		uniffimix_id: '',
		uniffimix_name: '',
		price: '',
		tons: '',
		agent_id: '',
		agent_price: '',
		agent_name: ''
	}

	state = {
		formData: {...this.dataToSave},
		persianas: {
			client: false,
			uniffimix: false,
			agent: false
		},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.modalInputCodeRef = React.createRef();
		this.modalInputClientRef = React.createRef();
		this.modalInputAgentRef = React.createRef();
		this.modalInputAgentPriceRef = React.createRef();
		this.modalInputDateRef = React.createRef();
		this.modalInputTonsRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

	    // Set default date
	    self.setState((state) => {
	    	state.formData.date = moment().format('Y-MM-DD');
	    	return state;
	    });

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.modalInputDateRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "dd-mm-yyyy",
			autoclose: true
		}).on('changeDate', (e) => {
	      	self.setState((state) => {
	      		state.formData.date = e.format(null, 'yyyy-mm-dd');
	      		return state;
	      	});
	    });

		// Get data
		if ( self.props.id != null ) self.loadModalData(self.props.id);
	}

	componentDidUpdate() {
		const self = this;

		// Tooltips
		window.$('[data-toggle=tooltip]').tooltip();

		// Set date on datepicker
		if ( self.state.formData.date ) window.$(self.modalInputDateRef.current).datepicker('update', moment(self.state.formData.date).format('DD-MM-YYYY'));
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	mountPersiana(type) {
		const self = this;

		// If id exists, then not allow to open persiana
		if ( self.state.formData.id ) return false;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		await axios.get(
			window.API_URL + '/uniffimixesorders/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// If not response, return
			if ( !response.data.data ) return;

			// Update state
			self.setState((state) => {
				// Set data
				state.formData = response.data.data;

				// Fix to fill some inputs
				state.formData.date = moment(state.formData.date).format('YYYY-MM-DD');
				state.formData.client_name = state.formData.client ? state.formData.client.name : '';
				state.formData.agent_name = state.formData.agent ? state.formData.agent.name : '';
				state.formData.uniffimix_name = state.formData.uniffi_mix ? state.formData.uniffi_mix.description : '';

				return state;
			});

			// Tons fix
			self.modalInputTonsRef.current.value = formatNumber(response.data.data.tons, {precision: 3, thousand: ".", decimal: ","});
			self.modalInputAgentPriceRef.current.value = formatNumber(response.data.data.agent_price, {precision: 2, thousand: ".", decimal: ","});
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];
		data.tons = formatNumber(data.tons, {precision: 3, thousand: "", decimal: "."});
		data.agent_price = formatNumber(data.agent_price, {precision: 2, thousand: "", decimal: "."});

		// Save ajax
		axios.post(
			window.API_URL + '/uniffimixesorders/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	async deleteModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Ask
		let c = window.confirm('¿Seguro que quieres eliminar esta recepción?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/uniffimixesorders/delete',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			self.closeModal();
		});
	}

	inputClientSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			// Client data
			state.formData.client_id = data ? data.id : '';
			state.formData.client_name = data ? data.name : '';

			// Set empty uniffimix
			state.formData.uniffimix_id = "";
			state.formData.uniffimix_name = "";
			state.formData.code = "";

			// State
			return state;
		});
	}

	inputAgentSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			// Client data
			state.formData.agent_id = data ? data.id : '';
			state.formData.agent_name = data ? data.name : '';

			// State
			return state;
		});
	}

	inputUniffimixSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			// Data
			state.formData.uniffimix_id = data ? data.id : '';
			state.formData.uniffimix_name = data ? data.description : '';
			state.formData.code = data ? data.code : '';
			state.formData.price = data ? data.price : '';
			state.formData.agent_id = data ? data.agent_id : '';
			state.formData.agent_name = data && data.agent ? data.agent.name : '';
			state.formData.agent_price = data ? data.agent_price : '';

			// Fix agent price
			self.modalInputAgentPriceRef.current.value = formatNumber(data ? data.agent_price : '', {precision: 2, thousand: ".", decimal: ","});

			// State
			return state;
		});
	}

	handleInputTonsChange(event) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		self.modalInputTonsRef.current.value = formatNumber(value, {precision: 3, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData['tons'] = value;
			return state;
		});
	}

	handleInputAgentPriceChange(event) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		self.modalInputAgentPriceRef.current.value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData['agent_price'] = value;
			return state;
		});
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	async copyOrder(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Get price by ajax and set
		let authHeaders = await window.prepareAuthHeaders();
		await axios.get(
			window.API_URL + '/uniffimixes/get',
			{ params: { id: self.state.formData.uniffimix_id }, headers: authHeaders }
		).then((response) => {
			// If not response, return
			if ( !response.data.data ) return;

			// Update state
			self.setState((state) => {
				state.formData.price = response.data.data.price;
				return state;
			});
		});
		
		// Update state
		self.setState((state) => {
			state.formData.id = '';
			state.formData.date = moment().format('Y-MM-DD');
			state.formData.printed = false;
			return state;
		});
	}

	async printOrder(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/uniffimixesorders/orderPdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data});

			// Set button to left
			self.setState((state) => {
				state.formData.printed = true;
				return state;
			});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	async printLabel(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/uniffimixesorders/labelPdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.client === true &&
					<Persiana 
						title="Clientes" 
						url={window.API_URL + '/clients/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('client')} 
						selectCallback={(data) => self.inputClientSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.uniffimix === true &&
					<Persiana 
						title="Uniffi" 
						url={window.API_URL + '/uniffimixes/all'}
						params={{client_id: self.state.formData.client_id}} 
						fields={['code', 'description']} 
						unmount={() => self.unmountPersiana('uniffimix')} 
						selectCallback={(data) => self.inputUniffimixSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.agent === true &&
					<Persiana 
						title="Agentes" 
						url={window.API_URL + '/agents/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('agent')} 
						selectCallback={(data) => self.inputAgentSelectCallback(data)} 
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.3 Pedidos</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">

									<div className="col-md-8">
										<div className="form-group">
											<label htmlFor="client_name">Cliente</label>
											<div className="input-group">
												<input type="text" id="client_name" className="form-control" ref={self.modalInputClientRef} value={self.state.formData.client_name} onFocus={() => self.mountPersiana('client')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('client')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.client_id && 
											<div className="error">{ self.state.errors.client_id[Object.keys(self.state.errors.client_id)[0]] }</div>
										}
									</div>

									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="code">Código</label>
											<input type="text" id="code" className="form-control text-right disabled" ref={self.modalInputCodeRef} value={self.state.formData.code} readOnly />
										</div>
										{ self.state.errors.code && 
											<div className="error">{ self.state.errors.code[Object.keys(self.state.errors.code)[0]] }</div>
										}
									</div>

									<div className="clearfix"></div>

									<div className="col-md-8">
										<div className="form-group">
											<label htmlFor="uniffimix_name">Artículo</label>
											<div className="input-group">
												<input type="text" id="uniffimix_name" className="form-control" ref={self.modalInputUniffimixRef} value={self.state.formData.uniffimix_name} onFocus={() => self.mountPersiana('uniffimix')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('uniffimix')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.uniffimix_id && 
											<div className="error">{ self.state.errors.uniffimix_id[Object.keys(self.state.errors.uniffimix_id)[0]] }</div>
										}
									</div>

									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="price">Precio</label>
											<input type="text" id="price" className="form-control text-right" value={formatNumber(self.state.formData.price + self.state.formData.agent_price, {precision: 0, thousand: ".", decimal: ","})} readOnly />
											{ self.state.errors.price && 
												<div className="error">{ self.state.errors.price[Object.keys(self.state.errors.price)[0]] }</div>
											}
										</div>
									</div>

									<div className="clearfix"></div>

									<div className="col-md-8">
										<div className="form-group">
											<label htmlFor="agent_name">Agente</label>
											<div className="input-group">
												<input type="text" id="agent_name" className="form-control" ref={self.modalInputAgentRef} value={self.state.formData.agent_name} onFocus={() => self.mountPersiana('agent')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('agent')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.agent_id && 
											<div className="error">{ self.state.errors.agent_id[Object.keys(self.state.errors.agent_id)[0]] }</div>
										}
									</div>

									<div className="col-md-4">
										<div className="row">
											<div className="col-md-6 pr-0">
												<div className="form-group">
													<label htmlFor="agent_price">€/Tn</label>
													<input type="text" id="agent_price" className="form-control text-right" ref={self.modalInputAgentPriceRef} onBlur={(event) => self.handleInputAgentPriceChange(event)} onClick={(event) => event.target.select()} />
													{ self.state.errors.agent_price && 
														<div className="error">{ self.state.errors.agent_price[Object.keys(self.state.errors.agent_price)[0]] }</div>
													}
												</div>
											</div>
											<div className="col-md-6 pl-0">
												<div className="form-group">
													<label htmlFor="date">Fecha</label>
													<input type="text" id="date" className="form-control text-right" ref={self.modalInputDateRef} onChange={(event) => self.handleInputChange(event, 'date')}  value={self.state.formData.date} />
												</div>
												{ self.state.errors.date && 
													<div className="error">{ self.state.errors.date[Object.keys(self.state.errors.date)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="clearfix"></div>

									<div className="d-flex w-100 justify-content-center align-items-center">
										<div className="col-md-4">
											<div className="form-group">
												<label htmlFor="tons">Toneladas</label>
												<input type="text" id="tons" className="form-control text-right" ref={self.modalInputTonsRef} onBlur={(event) => self.handleInputTonsChange(event)} onClick={(event) => event.target.select()} />
												{ self.state.errors.tons && 
													<div className="error">{ self.state.errors.tons[Object.keys(self.state.errors.tons)[0]] }</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" ref={self.btnSaveRef} className="btn btn-default float-right ml-1" onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>

								{ self.state.formData.id &&
									<React.Fragment>
										<button type="button" className="btn btn-default float-left mr-1" tabIndex="-1" onClick={(event) => self.deleteModalData(event)}><i className="fa fa-minus-circle"></i></button>
										<button type="button" className={'btn btn-default float-' + (self.state.formData.printed ? 'left' : 'right')} onClick={(event) => self.printOrder(event)} data-toggle="tooltip" data-placement="bottom" title="Imprimir pedido"><i className="fa fa-print"></i></button>
										<button type="button" className="btn btn-default float-right mr-1" onClick={(event) => self.printLabel(event)} data-toggle="tooltip" data-placement="bottom" title="Imprimir etiqueta"><i className="fa fa-tag"></i></button>
										<button type="button" className="btn btn-default float-right mr-1" onClick={(event) => self.copyOrder(event)} data-toggle="tooltip" data-placement="bottom" title="Copiar pedido"><i className="fa fa-edit"></i></button>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
				
			</React.Fragment>
		);
	}
}

export default UniffiMixesOrdersModal;
