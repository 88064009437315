import React from 'react';
import axios from 'axios';

import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

class UniffiModal extends React.Component {

	dataToSave = {
		id: '',
		code: '',
		description: '',
		price_buy: '',
		profit: '',
		price_sale: '',
		protein_percent: '',
		fiber_percent: '',
		fat_percent: '',
		ashes_percent: '',
		show_checkbox: ''
	}

	state = {
		formData: {...this.dataToSave},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.modalInputPriceBuyRef = React.createRef();
		this.modalInputProfitRef = React.createRef();
		this.modalInputProteinPercentRef = React.createRef();
		this.modalInputFiberPercentRef = React.createRef();
		this.modalInputFatPercentRef = React.createRef();
		this.modalInputAshesPercentRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Get data
		if ( self.props.id != null ) await self.loadModalData(self.props.id);
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		return axios.get(
			window.API_URL + '/uniffi/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// If not response, return
			if ( !response.data.data ) return;

			// Update state
			self.setState((state) => {
				// General data
				state.formData = response.data.data;

				// Return
				return state;
			});

			// Numbers fix
			self.modalInputPriceBuyRef.current.value = formatNumber(response.data.data.price_buy, {precision: 2, thousand: ".", decimal: ","});
			self.modalInputProfitRef.current.value = formatNumber(response.data.data.profit, {precision: 2, thousand: ".", decimal: ","});
			self.modalInputProteinPercentRef.current.value = formatNumber(response.data.data.protein_percent, {precision: 2, thousand: ".", decimal: ","});
			self.modalInputFiberPercentRef.current.value = formatNumber(response.data.data.fiber_percent, {precision: 2, thousand: ".", decimal: ","});
			self.modalInputFatPercentRef.current.value = formatNumber(response.data.data.fat_percent, {precision: 2, thousand: ".", decimal: ","});
			self.modalInputAshesPercentRef.current.value = formatNumber(response.data.data.ashes_percent, {precision: 2, thousand: ".", decimal: ","});
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];
		data.price_buy = formatNumber(data.price_buy, {precision: 2, thousand: "", decimal: "."});
		data.price_sale = formatNumber(data.price_sale, {precision: 2, thousand: "", decimal: "."});
		data.profit = formatNumber(data.profit, {precision: 2, thousand: "", decimal: "."});
		data.protein_percent = formatNumber(data.protein_percent, {precision: 2, thousand: "", decimal: "."});
		data.fiber_percent = formatNumber(data.fiber_percent, {precision: 2, thousand: "", decimal: "."});
		data.fat_percent = formatNumber(data.fat_percent, {precision: 2, thousand: "", decimal: "."});
		data.ashes_percent = formatNumber(data.ashes_percent, {precision: 2, thousand: "", decimal: "."});
		data.show_checkbox = data.show_checkbox ? 1 : 0;

		// Save ajax
		axios.post(
			window.API_URL + '/uniffi/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	async checkUniqueCode() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Check ajax
		axios.post(
			window.API_URL + '/uniffi/checkUniqueCode',
			qs.stringify({code: self.state.formData.code, id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				state.errors.code = response.data.errors.code ? response.data.errors.code : '';
				state.errors.pattern = response.data.errors.pattern ? response.data.errors.pattern : '';
				return state;
			});
		});
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	handleInputCheckboxChange(event) {
		const self = this;

		// Get status
		let status = event.target.checked;

		// Update
		self.setState((state) => {
			state.formData['show_checkbox'] = status;
			return state;
		});
	}

	handleInputNumberChange(event, input_name) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) value = 0;

		// Format number
		let numberFormatted = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Set value
		switch(input_name) {
			case 'price_buy': self.modalInputPriceBuyRef.current.value = numberFormatted; break;
			case 'profit': self.modalInputProfitRef.current.value = numberFormatted; break;
			case 'protein_percent': self.modalInputProteinPercentRef.current.value = numberFormatted; break;
			case 'fiber_percent': self.modalInputFiberPercentRef.current.value = numberFormatted; break;
			case 'fat_percent': self.modalInputFatPercentRef.current.value = numberFormatted; break;
			case 'ashes_percent': self.modalInputAshesPercentRef.current.value = numberFormatted; break;
			default: break;
		}

		// Update state
		self.setState((state) => {
			state.formData[input_name] = value;
			return state;
		});

		// Calc all (fix: call after state changed)
		setTimeout(() => {
			self.calcAll();
		}, 0);
	}

	calcAll() {
		const self = this;

		// Price sale
		let price_sale = self.state.formData.price_buy + self.state.formData.profit;
		
		// Set state
		self.setState((state) => {
			// Price
			state.formData.price_sale = price_sale;

			// Return
			return state;
		});
	}
	
	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header d-block">
								<h5 className="modal-title float-left">50.6 M.Primas Uniffi</h5>

								<button type="button" className="close float-right" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>

								<label htmlFor="show_checkbox" className="float-right mt-1 mr-3"><input type="checkbox" id="show_checkbox" onChange={(event) => self.handleInputCheckboxChange(event)} checked={self.state.formData.show_checkbox} /> Incluir en el Blog</label>
							</div>
							<div className="modal-body">
								<div className="row">

									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="code">Código</label>
											<input type="text" id="code" className="form-control text-right" onChange={(event) => self.handleInputChange(event, 'code')} onBlur={() => self.checkUniqueCode()}  value={self.state.formData.code} />
										</div>
										{ self.state.errors.code && 
											<div className="error">{ self.state.errors.code[Object.keys(self.state.errors.code)[0]] }</div>
										}
										{ self.state.errors.pattern && 
											<div className="error">{ self.state.errors.pattern[Object.keys(self.state.errors.pattern)[0]] }</div>
										}
									</div>

									<div className="col-md-8">
										<div className="form-group">
											<label htmlFor="description">Descripción</label>
											<input type="text" id="description" className="form-control" onChange={(event) => self.handleInputChange(event, 'description')}  value={self.state.formData.description} />
										</div>
										{ self.state.errors.description && 
											<div className="error">{ self.state.errors.description[Object.keys(self.state.errors.description)[0]] }</div>
										}
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="price_buy">Precio Compra</label>
											<input type="text" ref={self.modalInputPriceBuyRef} className="form-control text-right" onBlur={(event) => self.handleInputNumberChange(event, 'price_buy')} onClick={(event) => event.target.select()} />
										</div>
										{ self.state.errors.price_buy && 
											<div className="error">{ self.state.errors.price_buy[Object.keys(self.state.errors.price_buy)[0]] }</div>
										}
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="profit">Beneficios</label>
											<input type="text" ref={self.modalInputProfitRef} className="form-control text-right" onBlur={(event) => self.handleInputNumberChange(event, 'profit')} onClick={(event) => event.target.select()} />
										</div>
										{ self.state.errors.profit && 
											<div className="error">{ self.state.errors.profit[Object.keys(self.state.errors.profit)[0]] }</div>
										}
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="price_sale">Precio Venta</label>
											<input type="text" ref={self.modalInputPriceSaleRef} className="form-control text-right" value={formatNumber(self.state.formData.price_sale, {precision: 2, thousand: '.', decimal: ','})} readOnly />
										</div>
										{ self.state.errors.price_sale && 
											<div className="error">{ self.state.errors.price_sale[Object.keys(self.state.errors.price_sale)[0]] }</div>
										}
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="protein_percent">% Proteina Bruta</label>
											<input type="text" ref={self.modalInputProteinPercentRef} className="form-control text-right" onBlur={(event) => self.handleInputNumberChange(event, 'protein_percent')} onClick={(event) => event.target.select()} />
										</div>
										{ self.state.errors.protein_percent && 
											<div className="error">{ self.state.errors.protein_percent[Object.keys(self.state.errors.protein_percent)[0]] }</div>
										}
									</div>
									
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="fiber_percent">% Fibra Bruta</label>
											<input type="text" ref={self.modalInputFiberPercentRef} className="form-control text-right" onBlur={(event) => self.handleInputNumberChange(event, 'fiber_percent')} onClick={(event) => event.target.select()} />
										</div>
										{ self.state.errors.fiber_percent && 
											<div className="error">{ self.state.errors.fiber_percent[Object.keys(self.state.errors.fiber_percent)[0]] }</div>
										}
									</div>
									
									<div className="col-md-4">
										<div className="row">
											<div className="col-md-6 pr-0">
												<div className="form-group">
													<label htmlFor="fat_percent">% Act. y Gra. Bruta</label>
													<input type="text" ref={self.modalInputFatPercentRef} className="form-control text-right" onBlur={(event) => self.handleInputNumberChange(event, 'fat_percent')} onClick={(event) => event.target.select()} />
												</div>
												{ self.state.errors.fat_percent && 
													<div className="error">{ self.state.errors.fat_percent[Object.keys(self.state.errors.fat_percent)[0]] }</div>
												}
											</div>
											<div className="col-md-6 pl-0">
												<div className="form-group">
													<label htmlFor="ashes_percent">% Cenizas Bruta</label>
													<input type="text" ref={self.modalInputAshesPercentRef} className="form-control text-right" onBlur={(event) => self.handleInputNumberChange(event, 'ashes_percent')} onClick={(event) => event.target.select()} />
												</div>
												{ self.state.errors.ashes_percent && 
													<div className="error">{ self.state.errors.ashes_percent[Object.keys(self.state.errors.ashes_percent)[0]] }</div>
												}
											</div>
										</div>
									</div>
									
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" ref={self.btnSaveRef} className="btn btn-default float-right ml-1" onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default UniffiModal;
