import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import styled from 'styled-components';
import Persiana from '../Persiana/Persiana';

const Modal = styled.div`
    z-index: 3000;
    background: rgba(1,1,1,0.5);
`;

export default function Providers(props) {

    const modalRef = useRef(null);

    const [data, setData] = useState({
        noinvoice_checkbox: false,
        nosettlement_checkbox: false,
        payment_method: {
            id: null,
            name: null
        },
        internaltraffic_checkbox: props.agro ? true : false,
        traffic_agro_checkbox: props.agro ? true : false,

    });
    const [errors, setErrors] = useState(undefined);
    const [showPersianaMetodoPago, setShowPersianaMetodoPago] = useState(false);

    useEffect(() => {
        // Show modal
		window.$(modalRef.current).modal({backdrop: false, keyboard: false});
    }, []);

    const setDataField = (field, value) => {
        setData((prev) => ({
            ...prev,
            [field]: value
        }));
    }

    const saveData = async () => {
        // Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

        let toSave = {...data};
        delete toSave.payment_method;

		// Ajax
		axios.post(
			window.API_URL + '/providers/save',
			qs.stringify(toSave),
			{headers: authHeaders}
		).then((response) => {
            if ( response.data.status ) {
		        window.$(modalRef.current).modal('hide');
                props.closeCallback(response.data.provider);
            } else {
                setErrors({...response.data.errors});
            }
		});
    }

    const closeModal = () => {
        window.$(modalRef.current).modal('hide');
        props.closeCallback();
    }

    return (
        <Modal className="modal" tabIndex="-1" role="dialog" ref={modalRef}>
            { showPersianaMetodoPago === true &&
                <Persiana 
                    title="Método de pago" 
                    url={window.API_URL + '/paymentmethod/all'} 
                    fields={['name']}
                    params={{range: 5000}}
                    unmount={() => setShowPersianaMetodoPago(false)} 
                    selectCallback={(data) => {
                        setData((prev) => ({
                            ...prev,
                            payment_method: {
                                id: data?.id,
                                name: data?.name
                            },
                            paymentmethod_id: data?.id
                        }));
                    }} 
                />
            }
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.agro ? 'Agricultor' : 'Proveedor'}</h5>
                        <button type="button" className="close" onClick={(e) => closeModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <label htmlFor="internaltraffic_checkbox" className="float-left">T. Interno</label>
                                <label htmlFor="code" className="float-right">Código</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.internaltraffic_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    if ( props.agro ) return;
                                                    setDataField('internaltraffic_checkbox', e.target.checked ? 1 : 0);
                                                    setDataField('traffic_agro_checkbox', e.target.checked ? 1 : 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                    <input type="text" className="form-control text-right" id="code" value={data.code ?? ''} onChange={(e) => setDataField('code', e.target.value)} />
                                </div>

                                { (errors && errors['code']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['code'][Object.keys(errors['code'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="name">Nombre</label>
                                <input type="text" className="form-control" id="name" value={data.name ?? ''} onChange={(e) => setDataField('name', e.target.value)} />
                                { (errors && errors['name']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['name'][Object.keys(errors['name'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="vatnumber">Dni/Cif</label>
                                <input type="text" className="form-control" id="vatnumber" value={data.vatnumber ?? ''} onChange={(e) => setDataField('vatnumber', e.target.value)} />
                                { (errors && errors['vatnumber']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['vatnumber'][Object.keys(errors['vatnumber'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="address">Dirección</label>
                                <input type="text" className="form-control" id="address" value={data.address ?? ''} onChange={(e) => setDataField('address', e.target.value)} />
                                { (errors && errors['address']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['address'][Object.keys(errors['address'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="postalcode">C. Postal</label>
                                <input type="text" className="form-control text-right" id="postalcode" value={data.postalcode ?? ''} onChange={(e) => setDataField('postalcode', e.target.value)} />
                                { (errors && errors['postalcode']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['postalcode'][Object.keys(errors['postalcode'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="city">Ciudad</label>
                                <input type="text" className="form-control" id="city" value={data.city ?? ''} onChange={(e) => setDataField('city', e.target.value)} />
                                { (errors && errors['city']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['city'][Object.keys(errors['city'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="province">Provincia</label>
                                <input type="text" className="form-control" id="province" value={data.province ?? ''} onChange={(e) => setDataField('province', e.target.value)} />
                                { (errors && errors['province']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['province'][Object.keys(errors['province'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="country">País</label>
                                <input type="text" className="form-control" id="country" value={data.country ?? ''} onChange={(e) => setDataField('country', e.target.value)} />
                                { (errors && errors['country']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['country'][Object.keys(errors['country'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="phone">Teléfono</label>
                                <div className="input-group">
                                    <input type="text" className="form-control text-right" id="phone" value={data.phone ?? ''} onChange={(e) => setDataField('phone', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.phone_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('phone_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) setDataField('mobile_checkbox', 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['phone']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['phone'][Object.keys(errors['phone'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="mobile">Teléfono</label>
                                <div className="input-group">
                                    <input type="text" className="form-control text-right" id="mobile" value={data.mobile ?? ''} onChange={(e) => setDataField('mobile', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.mobile_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('mobile_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) setDataField('phone_checkbox', 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['mobile']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['mobile'][Object.keys(errors['mobile'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="email">Email</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" id="email" value={data.email ?? ''} onChange={(e) => setDataField('email', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.email_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('email_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) {
                                                        setDataField('email2_checkbox', 0);
                                                        setDataField('mail_checkbox', 0);
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['email']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['email'][Object.keys(errors['email'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="email2">Email</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" id="email2" value={data.email2 ?? ''} onChange={(e) => setDataField('email2', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.email2_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('email2_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) {
                                                        setDataField('email_checkbox', 0);
                                                        setDataField('mail_checkbox', 0);
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['email2']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['email2'][Object.keys(errors['email2'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="mail">Correo</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="mail" value={data.mail ?? ''} onChange={(e) => setDataField('mail', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.mail_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('mail_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) {
                                                        setDataField('email_checkbox', 0);
                                                        setDataField('email2_checkbox', 0);
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['mail']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['mail'][Object.keys(errors['mail'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="web">Web</label>
                                <input type="text" className="form-control" id="web" value={data.web ?? ''} onChange={(e) => setDataField('web', e.target.value)} />
                                { (errors && errors['web']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['web'][Object.keys(errors['web'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="contable_account">Cuenta Contable</label>
                                <input type="text" className="form-control text-right" id="contable_account" value={data.contable_account ?? ''} onChange={(e) => setDataField('contable_account', e.target.value)} />
                                { (errors && errors['contable_account']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['contable_account'][Object.keys(errors['contable_account'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="iban">Iban</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="iban" value={data.iban ?? ''} onChange={(e) => setDataField('iban', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.iban_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('iban_checkbox', e.target.checked ? 1 : 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['iban']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['iban'][Object.keys(errors['iban'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="bic">Bic</label>
                                <input type="text" className="form-control" id="bic" value={data.bic ?? ''} onChange={(e) => setDataField('bic', e.target.value)} />
                                { (errors && errors['bic']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['bic'][Object.keys(errors['bic'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="bank_name">Banco</label>
                                <input type="text" className="form-control" id="bank_name" value={data.bank_name ?? ''} onChange={(e) => setDataField('bank_name', e.target.value)} />
                                { (errors && errors['bank_name']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['bank_name'][Object.keys(errors['bank_name'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="provider_name">Método de pago</label>
                                <div className="input-group">
                                    <input type="text" id="payment_method" className="form-control" value={data.payment_method?.name ?? ''} onFocus={() => setShowPersianaMetodoPago(true)} readOnly />
                                    <div className="input-group-append">
                                        <div className="input-group-append">
                                            <button className="btn btn-default" type="button" onClick={() => setShowPersianaMetodoPago(true)}><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['payment_method_id']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['payment_method_id'][Object.keys(errors['payment_method_id'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="exploitation_code">Código Explotación</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="exploitation_code" value={data.exploitation_code ?? ''} onChange={(e) => setDataField('exploitation_code', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.exploitation_code_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('exploitation_code_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) setDataField('silun_exploitation', 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['exploitation_code']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['exploitation_code'][Object.keys(errors['exploitation_code'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="silun_code">Código Silun</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="silun_code" value={data.silun_code ?? ''} onChange={(e) => setDataField('silun_code', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.silun_exploitation ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('silun_exploitation', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) setDataField('exploitation_code_checkbox', 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['silun_code']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['silun_code'][Object.keys(errors['silun_code'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-12 mb-2">
                                <div className="row">
                                    <div className="col-md-2 pr-0">
                                        <label htmlFor="general_regime_checkbox">R. Profesional</label>
                                        <input 
                                            type="checkbox" 
                                            checked={data.general_regime_checkbox ?? 0} 
                                            onChange={(e) => {
                                                setDataField('general_regime_checkbox', e.target.checked ? 1 : 0);
                                                if ( e.target.checked === true ) {
                                                    setDataField('agricultural_regime_checkbox', 0);
                                                    setDataField('sp_checkbox', 0);
                                                    setDataField('ex_checkbox', 0);
                                                    setDataField('community_checkbox', 0);
                                                }
                                            }} 
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="agricultural_regime_checkbox">R. Agrícola</label>
                                        <input 
                                            type="checkbox" 
                                            checked={data.agricultural_regime_checkbox ?? 0} 
                                            onChange={(e) => {
                                                setDataField('agricultural_regime_checkbox', e.target.checked ? 1 : 0);
                                                if ( e.target.checked === true ) {
                                                    setDataField('general_regime_checkbox', 0);
                                                    setDataField('sp_checkbox', 0);
                                                    setDataField('ex_checkbox', 0);
                                                    setDataField('community_checkbox', 0);
                                                }
                                            }} 
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="sp_checkbox">S. Pasivo</label>
                                        <input 
                                            type="checkbox" 
                                            checked={data.sp_checkbox ?? 0} 
                                            onChange={(e) => {
                                                setDataField('sp_checkbox', e.target.checked ? 1 : 0);
                                                if ( e.target.checked === true ) {
                                                    setDataField('general_regime_checkbox', 0);
                                                    setDataField('agricultural_regime_checkbox', 0);
                                                    setDataField('ex_checkbox', 0);
                                                    setDataField('community_checkbox', 0);
                                                }
                                            }} 
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="ex_checkbox">Exento</label>
                                        <input 
                                            type="checkbox" 
                                            checked={data.ex_checkbox ?? 0} 
                                            onChange={(e) => {
                                                setDataField('ex_checkbox', e.target.checked ? 1 : 0);
                                                if ( e.target.checked === true ) {
                                                    setDataField('general_regime_checkbox', 0);
                                                    setDataField('agricultural_regime_checkbox', 0);
                                                    setDataField('sp_checkbox', 0);
                                                    setDataField('community_checkbox', 0);
                                                }
                                            }} 
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="community_checkbox">Comunitario</label>
                                        <input 
                                            type="checkbox" 
                                            checked={data.community_checkbox ?? 0} 
                                            onChange={(e) => {
                                                setDataField('community_checkbox', e.target.checked ? 1 : 0);
                                                if ( e.target.checked === true ) {
                                                    setDataField('general_regime_checkbox', 0);
                                                    setDataField('agricultural_regime_checkbox', 0);
                                                    setDataField('sp_checkbox', 0);
                                                    setDataField('ex_checkbox', 0);
                                                }
                                            }} 
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="nosettlement_checkbox">No LQ</label>
                                        <input 
                                            type="checkbox" 
                                            checked={data.nosettlement_checkbox ?? 0} 
                                            onChange={(e) => {
                                                setDataField('nosettlement_checkbox', e.target.checked ? 1 : 0);
                                            }} 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="traffic_agro_checkbox">Tráfico Interno</label>
                                <input 
                                    type="checkbox" 
                                    checked={data.traffic_agro_checkbox ?? 0} 
                                    onChange={(e) => {
                                        if ( props.agro ) return;
                                        setDataField('traffic_agro_checkbox', e.target.checked ? 1 : 0);
                                        setDataField('internaltraffic_checkbox', e.target.checked ? 1 : 0);
                                    }} 
                                />
                            </div>

                            <div className="col-md-4 mb-2">
                                <div className="row">
                                    <div className="col-md-6 pr-0">
                                        <label htmlFor="irpf">IRPF</label>
                                        <input type="text" className="form-control" id="irpf" value={data.irpf ?? ''} onChange={(e) => setDataField('irpf', e.target.value)} />
                                        { (errors && errors['irpf']) &&
                                            <div className="invalid-feedback d-block">
                                                { errors['irpf'][Object.keys(errors['irpf'])[0]] ?? '' }    
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-6 pl-0">
                                        <label htmlFor="cod">Cod</label>
                                        <input type="text" className="form-control" id="cod" value={data.cod ?? ''} onChange={(e) => setDataField('cod', e.target.value)} />
                                        { (errors && errors['cod']) &&
                                            <div className="invalid-feedback d-block">
                                                { errors['cod'][Object.keys(errors['cod'])[0]] ?? '' }    
                                            </div>
                                        }
                                    </div>
                                </div>
                                
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="irpf_account">Cuenta IRPF</label>
                                <input type="text" className="form-control" id="irpf_account" value={data.irpf_account ?? ''} onChange={(e) => setDataField('irpf_account', e.target.value)} />
                                { (errors && errors['irpf_account']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['irpf_account'][Object.keys(errors['irpf_account'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-12 mb-2">
                                <label htmlFor="observations">Observaciones</label>
                                <textarea className="form-control" id="observations" value={data.observations ?? ''} onChange={(e) => setDataField('observations', e.target.value)} />
                                { (errors && errors['observations']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['observations'][Object.keys(errors['observations'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer d-block">
                        <button type="button" id="save" className="btn btn-default float-right" onClick={(e) => saveData(e)}><i className="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </Modal>
    );  
}