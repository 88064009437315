import React from 'react';
import axios from 'axios';
import moment from 'moment';

import Persiana from '../../Components/Persiana/Persiana';

class SemillasReceptionsModalReport extends React.Component {

	state = {
		formData: {
			provider_id: null,
			provider_name: '',
			article_id: null,
			article_name: '',
			date: ''
		},
		persianas: {
			provider: false,
			article: false
		}
	}

	constructor(props) {
		super(props);

		// Refs
		this.modalRef = React.createRef();
		this.modalInputDateRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.modalInputDateRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "dd-mm-yyyy",
			autoclose: true
		}).on('changeDate', (e) => {
	      	self.setState((state) => {
	      		state.formData.date = e.format(null, 'yyyy-mm-dd');
	      		return state;
	      	});
	    });
	}

	componentDidUpdate() {
		const self = this;

		// Set date on datepicker
		if ( self.state.formData.date ) window.$(self.modalInputDateRef.current).datepicker('update', moment(self.state.formData.date).format('DD-MM-YYYY'));
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.modalRef.current).modal('hide');

		// Destroy date picker
		window.$(self.modalInputDateRef.current).datepicker('method', 'destroy');
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	mountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	inputProviderSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.provider_id = data ? data.id : '';
			state.formData.provider_name = data ? data.name : '';
			return state;
		});
	}

	inputArticleSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.article_id = data ? data.id : '';
			state.formData.article_name = data ? data.description : '';
			return state;
		});
	}

	async printPdf() {
		const self = this;

		// Check
		if ( !self.state.formData.date ) {
			alert('Selecciona una fecha');
			return;
		}

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/semillasreceptions/report_pdf', {
			params: { 
				farmer_id: self.state.formData.provider_id, 
				article_id: self.state.formData.article_id, 
				date: self.state.formData.date
			},
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data.pdfData});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.provider === true &&
					<Persiana 
						title="Agricultores" 
						url={window.API_URL + '/providers/all'} 
						fields={['name']} 
						params={{agro: true, pending_semillas_receptions: true, article_id: self.state.formData.article_id}}
						unmount={() => self.unmountPersiana('provider')} 
						selectCallback={(data) => self.inputProviderSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.article === true &&
					<Persiana 
						title="Artículos" 
						url={window.API_URL + '/articles/all'} 
						fields={['description']} 
						params={{semilla: true, associated_to_semillasreceptions: true, provider_id: self.state.formData.provider_id}}
						unmount={() => self.unmountPersiana('article')} 
						selectCallback={(data) => self.inputArticleSelectCallback(data)} 
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.0 Recepción Semillas</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="provider_name">Agricultor</label>
											<div className="input-group">
												<input type="text" id="provider_name" className="form-control" value={self.state.formData.provider_name} onFocus={() => self.mountPersiana('provider')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('provider')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="article_name">Semilla</label>
											<div className="input-group">
												<input type="text" id="article_name" className="form-control" value={self.state.formData.article_name} onFocus={() => self.mountPersiana('article')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('article')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 pt-4">
										<h5 className="mt-1">Fecha Informe</h5>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="date">Fecha</label>
											<input type="text" id="date" className="form-control text-right" ref={self.modalInputDateRef} onChange={(event) => self.handleInputChange(event, 'date')}  value={self.state.date ?? ''} />
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" className="btn btn-default float-right" onClick={(event) => self.printPdf(event)}><i className="fa fa-print"></i></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default SemillasReceptionsModalReport;
