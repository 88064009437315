import React from 'react';
import axios from 'axios';

import './TpvModal.css';

import Persiana from '../../Components/Persiana/Persiana';

import formatNumber from 'accounting-js/lib/formatNumber.js';

class TpvModal extends React.Component {

	state = {
		keyboardTicketStatus: {
			initial: null, // Key pressed to know if more, less or normal
			number: 0, // Number pressed to start
			reading: false, // Is reading from serial port
			numberFromSerial: 0, // Readed number from serial
			numberFromSerialFormatted: 0 // Readed number from serial, but formatted
		},
		formData: {
			date_input: '',
			client_input: '',
			client_id: '',
			article_input: '',
			gross_input: '',
			tare_input: '',
			net_input: '',
			price_input: '',
		},
		errors: {},
		persianas: {
			client: false,
			article: false,
		},
	}

	constructor(props) {
		super(props);

		// Refs
		this.tpvModal = React.createRef();
		this.dateInputRef = React.createRef();
		this.dateFakeInputRef = React.createRef();
		this.inputClientRef = React.createRef();
		this.inputArticleRef = React.createRef();
		this.inputGrossRef = React.createRef();
		this.inputTareRef = React.createRef();
		this.inputNetRef = React.createRef();
		this.inputPriceRef = React.createRef();

		// Keyboard listener function bind
		this.keyboardListenerBind = this.keyboardListener.bind(this);
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.tpvModal.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.dateInputRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "dd-mm-yyyy",
			autoclose: true,
			todayHighlight: true
		}).on('changeDate', (e) => {
			// Format date
			let date_text = "";
			let months = {1:"Enero", 2:"Febrero", 3:"Marzo", 4:"Abril", 5:"Mayo", 6:"Junio", 7:"Julio", 8:"Agosto", 9:"Septiembre", 10:"Octubre", 11:"Noviembre", 12:"Diciembre"};
			let day = e.format(null, 'd');
			let month = e.format(null, 'm');
			let year = e.format(null, 'yyyy');
			date_text = day + " " + months[month] + " " + year;
				
			// Update ref
			self.dateFakeInputRef.current.innerHTML = date_text;

			// Update state
	      	self.setState((state) => {
	      		state.formData.date_input = date_text;
	      		return state;
	      	});
	    });

		// Init keyboard listener
		document.addEventListener("keyup", self.keyboardListenerBind); 
	}

	componentWillUnmount() {
		const self = this;

		// Remove ticket keyboard listener
		document.removeEventListener("keyup", self.keyboardListenerBind);

		// Hide boostrap modal
		window.$(self.tpvModal.current).modal('hide');

		// Destroy date picker
		window.$(self.dateInputRef.current).datepicker('method', 'destroy');
	}

	keyboardListener(event) {
		const self = this;

		// If escape
		if ( event.keyCode === 27 ) {
			self.stopSerialPortReadInterval(); // Stop reading on serial port
			return false;
		}

		// If reading, exit
		if ( self.state.keyboardTicketStatus.reading ) return true;

		// Enter pressed
		if ( event.keyCode === 13 ) {
			if ( self.state.keyboardTicketStatus.initial && self.state.keyboardTicketStatus.number ) {
				self.setState((state) => {
					state.keyboardTicketStatus.reading = true
					return state;
				});
				self.initSerialPortReadInterval(); // Init reading on serial port
				return false;
			}
		}

		// Initial pressed
		if ( event.keyCode === 111 || event.keyCode === 109 || event.keyCode === 107 ) {
			self.state.keyboardTicketStatus.initial = event.keyCode; // Set key code
			self.state.keyboardTicketStatus.number = 0; // Reset number
		}
		
		// Number pressed
		if ( !isNaN(event.key) || event.key === ".") self.state.keyboardTicketStatus.number += "" + event.key;
	}

	initSerialPortReadInterval() {
		const self = this;

		// Create interval
		self.interval = setInterval(() => {
			let result = window.ipcRenderer.sendSync('readSerialPort', null);
			console.log(result);

			// Ok
			if ( result.status === 1 && result.msg !== -999 ) {
				// Number to set
				let numberToSet = parseInt(result.msg);
	
				// Check initial and number
				let numberPressed = parseFloat( parseFloat(self.state.keyboardTicketStatus.number) / 100 );
				switch(self.state.keyboardTicketStatus.initial) {
					case 109: numberToSet = parseInt(numberToSet - (numberToSet * numberPressed)); break; // -
					case 107: numberToSet = parseInt(numberToSet + (numberToSet * numberPressed)); break; // +
					default: break;
				}

				// Round to up
				numberToSet = self.ceil(numberToSet);

				// Set to gross or tare inputs
				try {
					if ( self.state.copyLiveNumbersToBottom ) {
						if ( !self.state.formData.id ) { // Gross
							self.setState((state) => {
								state.formData.gross = numberToSet;
								return state;
							});
						} else { // Tare
							self.setState((state) => {
								state.formData.tare = numberToSet;
								return state;
							});
						}
					}
				} catch (err) {}

				// Set to state
				self.setState((state) => {					
					state.keyboardTicketStatus.numberFromSerial = numberToSet;
					state.keyboardTicketStatus.numberFromSerialFormatted = formatNumber(numberToSet, {precision: 0, thousand: ".", decimal: ""});
					return state;
				});

				// If zero, stop reading
				if ( numberToSet <= 0 ) {
					self.closeModal();
					return;
				}
			} 

			// Not working
			if ( result.status === 0 ) {
				window.alert(result.msg); // Show msg
				self.stopSerialPortReadInterval();
			}
		}, 500);
	}

	stopSerialPortReadInterval() {
		const self = this;

		// Update state
		self.setState((state) => {
			state.keyboardTicketStatus.initial = null;
			state.keyboardTicketStatus.number = 0;
			state.keyboardTicketStatus.reading = false;
			state.keyboardTicketStatus.numberFromSerial = 0;
			state.keyboardTicketStatus.numberFromSerialFormatted = 0;
			return state;
		});

		// Clear Interval
		clearInterval(self.interval);
		self.interval = null;
	}

	ceil(number = 0) {
		// Convert to string
		number = number.toString();

		// Get last && anterior
		let last = parseInt(number.slice(-1));
		let anterior = parseInt(number.slice(-2).substring(0, 1));

		// Check and do things
		if ( !isNaN(anterior) ) {
			// Check if >= 5 or < 5
			if ( last >= 5 ) number = parseInt(number.substring(0, number.length - 1) + '0')+10;
			if ( last < 5 ) number = number.substring(0, number.length - 1) + '0';
		}

		// Return
		return parseInt(number);
	}

	formatNumberThousands(number) {
		// Delete points
		number = (number+"").replace(/\./g,'');
		
		// If number, format
		if ( number && !isNaN(number) ) return formatNumber(number, {precision: 0, thousand: ".", decimal: ""});

		// If empty, return again
		return number;
	}

	calcNet() {
		const self = this;

		// Get data
		let grossValue = parseInt(self.state.formData.gross_input ? (""+self.state.formData.gross_input).replace('.', '') : 0);
		let tareValue = parseInt(self.state.formData.tare_input ? (""+self.state.formData.tare_input).replace('.', '') : 0);

		// Get greater and less value
		let greater = grossValue > tareValue ? grossValue : tareValue;
		let less = grossValue < tareValue ? grossValue : tareValue;

		// Calc
		let net = greater - less;

		// Set state fix
		self.state.formData.net_input = net;

		// If empty
		if ( !(""+net).length || (parseFloat(net) === 0 && !grossValue && !tareValue) ) return "";

		// Ok
		return self.formatNumberThousands(net);
	}

	handleInputThousandsChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value.replace(/\./g,'').replace(/\D/g, '');

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	handleInputDecimalsChange(event, field) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		event.target.value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	closeModal() {
		const self = this;

		// Stop reading
		self.stopSerialPortReadInterval();

		// Unmount
		self.props.unmount();
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	inputClientClickEvent(event) {
		const self = this;

		// If right click, empty field
		self.setState((state) => {
			state.formData.client_input = "";
			state.formData.client_id = "";
			return state;
		});

		// Change state to open persiana
		self.setState((state) => {
			state.persianas['client'] = true;
			return state;
		});
	}

	inputClientSelectCallback(data) {
		const self = this;

		// Set data
		if ( data !== null ) {
			// Update state
			self.setState((state) => {
				state.formData.client_input = data.name;
				state.formData.client_id = data.id;
			});
		} else { // Allow to write
			// Update state
			self.setState((state) => {
				state.formData.client_input = "";
				state.formData.client_id = "";
			});

			// Fix timeout to focus
			setTimeout(() => self.inputClientRef.current.focus(), 100);
		}
	}

	inputArticleClickEvent(event) {
		const self = this;

		// If right click, empty field
		self.setState((state) => {
			state.formData.article_input = "";
			return state;
		});

		// Change state to open persiana
		self.setState((state) => {
			state.persianas['article'] = true;
			return state;
		});
	}

	inputArticleSelectCallback(data) {
		const self = this;

		// Set data
		if ( data !== null ) {
			// Update state
			self.setState((state) => {
				state.formData.article_input = data.description;
			});
		} else { // Allow to write
			// Update state
			self.setState((state) => {
				state.formData.article_input = "";
			});

			// Fix timeout to focus
			setTimeout(() => self.inputArticleRef.current.focus(), 100);
		}
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to close persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	inputGrossClickEvent() {
		const self = this;

		self.setState((state) =>  {
			state.formData.gross_input = state.keyboardTicketStatus.numberFromSerial;
			return state;
		});
	}

	inputTareClickEvent() {
		const self = this;

		self.setState((state) =>  {
			state.formData.tare_input = state.keyboardTicketStatus.numberFromSerial;
			return state;
		});
	}

	async print(event) {
		const self = this;

		// prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/tpv/pdf',
			{
				params: {
					date: self.state.formData.date_input,
					client: self.state.formData.client_input,
					client_id: self.state.formData.client_id,
					article: self.state.formData.article_input,
					gross: self.state.formData.gross_input,
					tare: self.state.formData.tare_input,
					net: self.state.formData.net_input,
					price: self.state.formData.price_input,
				},
				headers: authHeaders
			}
		).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data, printer: 'tpv'});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	render() {
		const self = this;

		// Return
		return (
			<React.Fragment>
				{ self.state.persianas.client === true &&
					<Persiana 
						title="Clientes" 
						url={window.API_URL + '/clients/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('client')} 
						selectCallback={(data) => self.inputClientSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.article === true &&
					<Persiana 
						title="Artículos" 
						url={window.API_URL + '/articles/all'} 
						fields={['description']} 
						unmount={() => self.unmountPersiana('article')} 
						selectCallback={(data) => self.inputArticleSelectCallback(data)} 
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" id="tpvModal" ref={self.tpvModal}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.7 TPV</h5>
								<button type="button" className="close" onClick={(event) => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									{ self.props.live !== false && 	
										<React.Fragment>
											<div className="col-md-8">
												<input type="text" className="basculeLiveText" value={self.state.keyboardTicketStatus.numberFromSerialFormatted} readOnly />
											</div>
											<div className="col-md-4 pesaje">
												<a href="/" className="btn btn-default" style={{marginRight: '50px'}} onClick={(event) => event.preventDefault()}><i className="fa fa-play"></i></a>
												Kilos
											</div>
											<div className="clearfix"></div>
										</React.Fragment>
									}

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Fecha</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<div className="dateContainer form-control" readOnly onClick={(event) => window.$(self.dateInputRef.current).datepicker('show')}>
														<input type="text" className="form-control text-right" id="date" ref={self.dateInputRef} readOnly />
														<span id="dateFake" ref={self.dateFakeInputRef}></span>
													</div>
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={(event) => window.$(self.dateInputRef.current).datepicker('show')}><i className="fa fa-search"></i></button>
													</div>
												</div>
												{ self.state.errors.date && 
													<div className="error">{ self.state.errors.date[Object.keys(self.state.errors.date)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Cliente</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control " + (self.state.formData.client_id !== null || !self.state.formData.client_input.length ? 'disabled' : '')} ref={self.inputClientRef} onChange={(event) => self.handleInputChange(event, 'client_input')} value={self.state.formData.client_input} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={(event) => self.inputClientClickEvent(event)}><i className="fa fa-search"></i></button>
													</div>
												</div>
												{ self.state.errors.client_id && 
													<div className="error">{ self.state.errors.client_id[Object.keys(self.state.errors.client_id)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Artículo</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control " + (self.state.formData.article_id !== null || !self.state.formData.article_input.length ? 'disabled' : '')} ref={self.inputArticleRef} onChange={(event) => self.handleInputChange(event, 'article_input')} value={self.state.formData.article_input} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={(event) => self.inputArticleClickEvent(event)}><i className="fa fa-search"></i></button>
													</div>
												</div>
												{ self.state.errors.article_id && 
													<div className="error">{ self.state.errors.article_id[Object.keys(self.state.errors.article_id)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Bruto</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control disabled"} ref={self.inputGrossRef} onChange={(event) => self.handleInputThousandsChange(event, 'gross_input')} value={self.state.formData.gross_input ? formatNumber(self.state.formData.gross_input, {precision: 0, thousand: ".", decimal: ""}) : ''} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.inputGrossClickEvent()}><i className="fa fa-caret-down" style={{width: '16px'}}></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Tara</label>
											</div>
											<div className="col-md-10">
												<div className="input-group">
													<input type="text" className={"form-control disabled"} ref={self.inputTareRef} onChange={(event) => self.handleInputThousandsChange(event, 'tare_input')} value={self.state.formData.tare_input ? formatNumber(self.state.formData.tare_input, {precision: 0, thousand: ".", decimal: ""}) : ''} />
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.inputTareClickEvent()}><i className="fa fa-caret-down" style={{width: '16px'}}></i></button>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label className="label-left">Neto</label>
											</div>
											<div className="col-md-10">
												<div className="row">
													<div className="col-md-6 pr-0">
														<input type="text" className={"form-control disabled"} ref={self.inputNetRef} value={self.calcNet()} readOnly />
													</div>
													<div className="col-md-1 pl-0 pr-0 pt-1 text-center">
														<b>x</b>
													</div>
													<div className="col-md-5 pl-0">
														<input type="text" className={"form-control disabled"} ref={self.inputPriceRef} onBlur={(event) => self.handleInputDecimalsChange(event, 'price_input')} />
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
							<div className="modal-footer d-block">
								<button className="btn btn-default" onClick={(event) => self.closeModal()}><i className="fa fa-minus-circle"></i></button>

								<button type="button" className="btn btn-default float-right" onClick={(event) => self.print(event)}><i className="fa fa-print"></i></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default TpvModal;