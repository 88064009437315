import React from 'react';
import axios from 'axios';

import './Persiana.css';

import ClientsModal from '../CreateModals/Clients';
import ProvidersModal from '../CreateModals/Providers';
import ArticlesModal from '../CreateModals/Articles';
import TransportersModal from '../CreateModals/Transporters';
import DriversModal from '../CreateModals/Drivers';
import ParcelsModal from '../CreateModals/Parcels';

class Persiana extends React.Component {

	state = {
		rows: [],
		filter: "",
		createModal: null,
	}

	constructor(props) {
		super(props);

		// Refs
		this.persianaModal = React.createRef();
		this.inputSearchRef = React.createRef();
		this.rowsWrapperRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.persianaModal.current).modal({backdrop: false});

		// Fix zindex
		window.$(self.persianaModal.current).css('z-index', 2000);

		// Set focus
		self.inputSearchRef.current.focus();

		// Get data
		await self.getData();
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.persianaModal.current).modal('hide');

		// Fix
		document.querySelectorAll('.modal').forEach(el => el.style.overflowY = 'scroll');
	}

	async getData() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		return axios.get(
			self.props.url,
			{	
				params: self.props.params || {},
				headers: authHeaders
			}
		).then((response) => {
			let data = response.data.rows;
			if ( self.props.dataFilter ) data = self.props.dataFilter(data);
			self.setState((state) => {
				if ( data && response.data.status ) state.rows = data;
				return state;
			});
		});
	}

	closeModal() {
		const self = this;

		// Unmount
		self.props.unmount();
	}

	filterResults() {
		const self = this;

		self.setState((state) => {
			state.filter = self.inputSearchRef.current.value;
			return state;
		})
	}

	selectRow(idx) {
		const self = this;

		// Get data
		let data = null;
		if ( self.state.rows[idx] ) data = self.state.rows[idx];

		// Callback
		self.props.selectCallback(data);

		// Hide
		self.closeModal();
	}

	inputSearchKeyHandler(event) {
		const self = this;

		// Check if tab
		if ( event.keyCode === 9) {
			event.preventDefault();
			self.rowsWrapperRef.current.querySelector('tr:first-of-type').focus();
		}
	}

	rowKeyHandler(event, idx) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Row up
		if ( event.keyCode === 38 ) {
			let prev = event.target.previousSibling;
			if ( prev ) event.target.previousSibling.focus();
		}

		// Row down
		if ( event.keyCode === 40 ) {
			let prev = event.target.nextSibling;
			if ( prev ) event.target.nextSibling.focus();
		}

		// Enter, select row
		if (event.keyCode === 13 ) self.selectRow(idx);
	}

	openCreateModal(type) {
		const self = this;

		self.setState((state) => {
			state.createModal = type;
			return state;
		})
	} 

	render() {
		const self = this;

		// Prepare rows
		let rows = [];
		self.state.rows.forEach((el, idx) => {
			// Prepare td and text to filter
			let rowTmp = [];
			let textTmp = "";
			self.props.fields.forEach((fel, fidx) => {
				let fieldValue = el[fel] ? el[fel] : '';
				if ( self.props.fieldsCallback && self.props.fieldsCallback[fel] ) fieldValue = self.props.fieldsCallback[fel](fieldValue);
				textTmp += (fieldValue + " ").toLowerCase();
				let tdAlign =  self.props.fieldsAlign && self.props.fieldsAlign[fel] ? self.props.fieldsAlign[fel] : 'left';
				rowTmp.push(<td style={ fidx === 0 ? {width: '10px'} : {} } className={'text-' + tdAlign} key={fidx}>{fieldValue}</td>);
			});

			// If text is empty, not push
			if ( textTmp.trim().length <= 0 ) return;

			// Check filter
			if ( (self.state.filter.length > 0 && textTmp.indexOf(self.state.filter.toLocaleLowerCase()) !== -1) || self.state.filter.length <= 0 ) {
				rows.push(<tr key={idx} tabIndex={idx+1} onClick={() => self.selectRow(idx)} onKeyDown={(event) => self.rowKeyHandler(event, idx)}>{rowTmp}</tr>);
			}
		});

		// Return
		return (
			<React.Fragment>
				<div className="modal" tabIndex="-1" role="dialog" id="persianaModal" ref={self.persianaModal}>
					<div className="modal-dialog modal-md modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{self.props.title}</h5>
								<button type="button" className="close" onClick={(event) => self.selectRow(null)}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<table><tbody ref={self.rowsWrapperRef}>{rows}</tbody></table>
							</div>
							<div className="modal-footer">
								<div className="input-group">
									<div className="input-group-prepend">
										<button className="btn btn-danger" type="button" onClick={() => self.selectRow(null)}><i className="fa fa-minus-circle"></i></button>
									</div>
									<input type="text" className="form-control" ref={self.inputSearchRef} placeholder="Buscar" onKeyDown={(event) => self.inputSearchKeyHandler(event)} onChange={() => self.filterResults()} />
									{ this.props.canCreate &&
										<div className="input-group-append">
											<button className="btn btn-default" type="button" onClick={() => self.openCreateModal(self.props.canCreate)}><i className="fa fa-plus-circle"></i></button>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
				{ self.state.createModal === 'clients' &&
					<ClientsModal
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'providers' &&
					<ProvidersModal
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'providers-agro' &&
					<ProvidersModal
						agro={true}
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'articles' &&
					<ArticlesModal
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'internaltr' &&
					<ArticlesModal
						title="Tráfico Agro"
						internalTraffic={true}
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'semilla' &&
					<ArticlesModal
						title="Semilla"
						semilla={true}
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'parcel' &&
					<ParcelsModal
						title="Parcela"
						farmerId={this.props.params.farmer_id}
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'transporters' &&
					<TransportersModal
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
				{ self.state.createModal === 'drivers' &&
					<DriversModal
						closeCallback={(data) => {
							// Close create modal
							this.openCreateModal(null);

							// Close and set data
							if ( data ) {
								this.props.selectCallback(data);
								this.closeModal();
							}
						}}
					/>
				}
			</React.Fragment>
		);
	}
}

export default Persiana;