import React from 'react';
import axios from 'axios';

import Persiana from '../../Components/Persiana/Persiana';
import qs from 'qs';

class ParcelsModal extends React.Component {

	dataToSave = {
		id: '',
		place: '',
		municipality: '',
		polygon: '',
		parcel: '',
	}

	state = {
		formData: {...this.dataToSave},
		errors: {},
		persianas: {
			provider: false
		},
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.farmersDropdownRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Get data
		if ( self.props.id != null ) self.loadModalData(self.props.id);
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.modalRef.current).modal('hide');
	}

	componentDidUpdate() {

	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		await axios.get(
			window.API_URL + '/parcels/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// Update state
			self.setState((state) => {
				// Set data
				state.formData = {
					id: response.data.data.id,
					place: response.data.data.place,
					municipality: response.data.data.municipality,
					polygon: response.data.data.polygon,
					parcel: response.data.data.parcel,
					farmers: response.data.data.farmers
				};
				return state;
			});
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];

		// Save ajax
		axios.post(
			window.API_URL + '/parcels/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	handleInputChange(event, field, value) {
		const self = this;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	async deleteModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Ask
		let c = window.confirm('¿Seguro que quieres eliminar esta parcela?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/parcels/delete',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			self.closeModal();
		});
	}

	async unlinkFarmer(e, farmer_id) {
		const self = this;
		
		e.preventDefault();

		let c = window.confirm('¿Seguro que quieres eliminar este agricultor?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/parcels/delete-farmer',
			qs.stringify({id: self.state.formData.id, farmer_id: farmer_id}),
			{ headers: authHeaders }
		).then((response) => {
			self.loadModalData(self.props.id);
		});
	}

	linkFarmer(e) {
		const self = this;

		e.preventDefault();

		self.setState((state) => {
			state.persianas['provider'] = true;
			return state;
		});
	}

	async inputProviderSelectCallback(provider) {
		const self = this;
		
		if ( !provider?.id ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/parcels/associate-farmer-id',
			qs.stringify({id: self.state.formData.id, farmer_id: provider.id}),
			{ headers: authHeaders }
		).then((response) => {
			self.loadModalData(self.props.id);
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.provider === true &&
					<Persiana 
						title="Agricultores" 
						url={window.API_URL + '/providers/all'} 
						fields={['name']} 
						params={{agro: true}}
						unmount={() => {
							self.setState((state) => {
								state.persianas['provider'] = false;
								return state;
							});
						}} 
						selectCallback={(data) => self.inputProviderSelectCallback(data)} 
					/>
				}
				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.8 Parcelas</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="place">Paraje</label>
											<input type="text" id="place" className="form-control" onChange={(event) => self.handleInputChange(event, 'place', event.target.value)}  value={self.state.formData.place} />
										</div>
										{ self.state.errors.place && 
											<div className="error">{ self.state.errors.place[Object.keys(self.state.errors.place)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="municipality">Municipio</label>
											<input type="text" id="municipality" className="form-control text-right" onChange={(event) => self.handleInputChange(event, 'municipality', event.target.value)}  value={self.state.formData.municipality} />
										</div>
										{ self.state.errors.municipality && 
											<div className="error">{ self.state.errors.municipality[Object.keys(self.state.errors.municipality)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="polygon">Polígono</label>
											<input type="text" id="polygon" className="form-control text-right" onChange={(event) => self.handleInputChange(event, 'polygon', event.target.value)}  value={self.state.formData.polygon} />
										</div>
										{ self.state.errors.polygon && 
											<div className="error">{ self.state.errors.polygon[Object.keys(self.state.errors.polygon)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="parcel">Parcela</label>
											<input type="text" id="parcel" className="form-control text-right" onChange={(event) => self.handleInputChange(event, 'parcel', event.target.value)}  value={self.state.formData.parcel} />
										</div>
										{ self.state.errors.parcel && 
											<div className="error">{ self.state.errors.parcel[Object.keys(self.state.errors.parcel)[0]] }</div>
										}
									</div>
									<div className="col-md-8">
										<label htmlFor="parcel">Agricultores que utilizan esta parcela</label>
										<div className="dropdown">
											<button className="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown" style={{width: '200px', 'textAlign': 'left'}}>
												{self.state.formData.farmers?.length ?? 0} agricultores
											</button>
											<div className="dropdown-menu" ref={self.farmersDropdownRef}>
												{self.state.formData.farmers?.map((el, idx) => {
													return (
														<a key={idx} className="dropdown-item" href="." onClick={(e) => el.is_deletable ? self.unlinkFarmer(e, el.id) : e.preventDefault()}>
															{el.name} {el.is_deletable && <i className="fa fa-minus-circle float-right mt-1 ml-3"></i>}
														</a>
													);
												})}
												<a className="dropdown-item text-center" href="." onClick={(e) => self.linkFarmer(e)}>
													<i className="fa fa-plus-circle"></i>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								{ (self.state.formData.id && (!self.state.formData.farmers || self.state.formData.farmers?.length === 0)) &&
									<button type="button" className="btn btn-default float-left mr-1" tabIndex="-1" onClick={(event) => self.deleteModalData(event)}><i className="fa fa-minus-circle"></i></button>
								}

								<button type="button" className="btn btn-default float-right" ref={self.btnSaveRef} onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ParcelsModal;
