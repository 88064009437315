import React from 'react';
import './Parrilla.css';

class Parrilla extends React.Component {

	state = {
		searching: null
	}

	constructor(props) {
		super(props);

		// Refs
		this.paginatorTextareaRef = React.createRef();
		this.modalSearchRef = React.createRef();
		this.modalSearchInputRef = React.createRef();
		this.modalSearchSelectRef = React.createRef();
	}

	handlePaginatorOnKeyUp(event) {
		const self = this;
		if (event.keyCode === 13) { 
			event.preventDefault(); 
			self.props.pageChangeCallback(event.currentTarget.value)
			event.currentTarget.blur();
			event.currentTarget.value = null;
		}
	}

	openModalSearch(event) {
		const self = this;
		event.preventDefault();
		window.$(self.modalSearchRef.current).modal();
		self.modalSearchInputRef.current.focus();
	}

	modalSearch(event, clear = false) {
		const self = this;
		event.preventDefault();

		let field = self.modalSearchSelectRef.current.value;
		let query = self.modalSearchInputRef.current.value;

		if ( clear ) {
			field = null;
			query = null;
		}

		self.setState((state) => {
			state.searching = query;
		});

		self.props.clickSearchCallback(field, query);

		window.$(self.modalSearchRef.current).modal('hide');
	}

	render() {
		const self = this;

		// Prepare header
		let headerTh = []
		if ( self.props.header ) self.props.header.forEach((el, idx) => {
			headerTh.push(
				<th 
					key={idx} 
					className={(el.align ? el.align : '')} 
					style={{width: (el.width ? el.width : ''), minWidth: (el.width ? el.width : ''), color: (el.color ? el.color : '')}}>
						<a 
							href="/" 
							onClick={(event) => { event.preventDefault(); return el.key ? self.props.sortCallback(el.key) : false; }}>{el.text}
						</a>
				</th>
			);	
		});

		// Prepare body
		let bodyRows = [];
		if ( self.props.rows ) self.props.rows.forEach((el, idx) => {
			let trTmp = [];
			el.forEach((el2, idx2) => {
				trTmp.push(
					<td 
						key={idx2} 
						className={(el2.align ? el2.align : '')}
						style={{color: (el2.color ? el2.color : ''), fontWeight: (el2.fontWeight ? el2.fontWeight : '')}}
					>
						{el2.text}
					</td>
				);
			});
			bodyRows.push(<tr key={idx} onClick={() => self.props.clickRowCallback(idx)}>{trTmp}</tr>);
		});

		// Prepare searchfields
		let searchFields = [];
		if ( self.props.searchFields ) self.props.searchFields.forEach((el, idx) => {
			searchFields.push(<option key={idx} value={el.key}>{el.text}</option>);	
		});

		// Prepare paginator placeholder
		let paginator_placeholder = "";
		let paginator_placeholder_rows = 0;
		if ( self.props.paginator.page && self.props.paginator.lastPage ) {
			for (var i = 0; i < (""+self.props.paginator.page).length; i++) paginator_placeholder += (""+self.props.paginator.page).charAt(i) + "\n";
			paginator_placeholder += "de" + "\n";
			for (var i = 0; i < (""+self.props.paginator.lastPage).length; i++) paginator_placeholder += (""+self.props.paginator.lastPage).charAt(i) + "\n";
		}
		paginator_placeholder_rows = paginator_placeholder.split("\n").length - 1;

		// Render
		return (
			<React.Fragment>
				<div id="parrilla">
					<div className="parrilla-table-container">
						{ self.props.title.length > 0 && 
							<div className="parrilla-title">{self.props.title}</div>
						}
						{ self.state.searching != null && 
							<div className="parrilla-searching" onClick={(event) => self.modalSearch(event, true)}>
								<i className="fa fa-times fa-fw"></i> Buscando: <span className="label label-default">{self.state.searching}</span>
							</div>
						}
						<div className="table-responsive">
							<table className="table table-bordered table-hover">
								{
									headerTh.length > 0 &&
									<thead><tr>{headerTh}</tr></thead>
								}
								{
									bodyRows.length > 0 &&
									<tbody>{bodyRows}</tbody>
								}
							</table>
						</div>
					</div>
					<div className="parrilla-floating-buttons">
						<div className="parrilla-floating-buttons-inner">
							{ self.props.btnsCustom && self.props.btnsCustom }
							
							{ self.props.btnAdd &&
								<a href="/" onClick={(event) => self.props.btnAddCallback(event)} className="btn btn-default"><i className="fa fa-plus-circle"></i></a>
							}
							{ self.props.btnSearch &&
								<a href="/" className="btn btn-default" onClick={(event) => self.openModalSearch(event)}><i className="fa fa-search"></i></a>
							}
							<div className="paginator">
								<div className="pagination-floating">
									<div className="input-group">
										<span className="input-group-btn">
											<a href="/" onClick={(event) => { event.preventDefault(); self.props.pageChangeCallback(self.props.paginator.page-1); }} className="btn btn-default">«</a>	
										</span>
										<textarea 
											ref={self.paginatorTextareaRef}
											className="form-control" 
											onChange={(event) => { event.preventDefault() }}
											onKeyUp={(event) => self.handlePaginatorOnKeyUp(event)} 
											onFocus={(event) => event.currentTarget.value = ''} 
											placeholder={paginator_placeholder}
											rows={paginator_placeholder_rows}
										></textarea>
										<span className="input-group-btn">
											<a href="/" onClick={(event) => { event.preventDefault(); self.props.pageChangeCallback(self.props.paginator.page+1); }} className="btn btn-default">»</a>	
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal" tabIndex="-1" role="dialog" id="parrilla-modal-search" ref={self.modalSearchRef}>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Buscar</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="form-group">
									<label>Campo</label>
									<select ref={self.modalSearchSelectRef} className="form-control">
										{searchFields}
									</select>
								</div>
								<div className="form-group">
									<label>Búsqueda</label>
									<input type="text" ref={self.modalSearchInputRef} className="form-control" />
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-default" onClick={(event) => self.modalSearch(event)}>
									<i className="fa fa-search"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Parrilla;