import React from 'react';
import axios from 'axios';

import ArticlesModal from './ArticlesModal';
import Parrilla from '../../Components/Parrilla/Parrilla';
import formatNumber from 'accounting-js/lib/formatNumber.js'

class ArticlesPage extends React.Component {

	state = {
		rows: [],
		totals: {},
		page: 1,
		nextPage: null,
		prevPage: null,
		lastPage: null,
		sort: 'Articles.description',
		direction: 'ASC',
		field: null,
		query: null,
		modalOpened: false,
		modalId: ''
	}

	async componentDidMount() {
		const self = this;

		// Execute initial actions
		await self.loadParrillaData();
	}

	async loadParrillaData() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		await axios.get(
			window.API_URL + '/articles/list',
			{	
				params: {
					page: self.state.page,
					sort: self.state.sort,
					direction: self.state.direction,
					field: self.state.field,
					query: self.state.query
				},
				headers: authHeaders
			}
		).then((response) => {
			self.setState((state) => {
				if ( response.data ) {
					state.rows = response.data.rows;
					state.totals = response.data.totals;
					state.page = response.data.page;
					state.nextPage = response.data.nextPage;
					state.prevPage = response.data.prevPage;
					state.lastPage = response.data.lastPage;
				}
				return state;
			});
		});
	}

	parrillaSortCallback(key) {
		const self = this;

		// Update State
		self.state.sort = key;
		self.state.direction = self.state.direction === 'asc' ? 'desc' : 'asc';

		// Reload data
		self.loadParrillaData();
	}

	parrillaChangePageCallback(page) {
		const self = this;

		// Set page
		self.state.page = page;

		// Reload data
		self.loadParrillaData();
	}

	parrillaSearchCallback(field, query) {
		const self = this;

		// Set page
		self.state.field = field;
		self.state.query = query;

		// Reload data
		self.loadParrillaData();
	}

	openModal(event, idx = null) {
		const self = this;

		// Prevent
		if ( event ) event.preventDefault();

		// Set modal opened
		self.setState((state) => {
			state.modalOpened = true;
			state.modalId = idx !== null ? self.state.rows[idx].id : null;
			return state;
		});
	}

	closeModal() {
		const self = this;

		// Set modal opened
		self.setState((state) => {
			state.modalOpened = false;
			state.modalId = '';
			return state;
		});

		// Reload parrilla
		self.loadParrillaData();
	}

	render() {
		const self = this;

		// Prepare header
		let header = [
			{ text: 'Descripción', key: 'Articles.description', width: 200 },
			{ text: 'Compras', key: 'article_tonsbuy(Articles.id)', width: 100 },
			{ text: 'Ventas', key: 'article_tonssell(Articles.id)', width: 100 },
			{ text: '€.Compras', key: 'article_totaleurobuy(Articles.id)', width: 100 },
			{ text: '€.Ventas', key: 'article_totaleurosell(Articles.id)', width: 100 },
			{ text: 'P.Compra', key: 'article_pricebuy(Articles.id)', width: 100 },
			{ text: 'P.Venta', key: 'article_pricesell(Articles.id)', width: 100 },
			{ text: 'Existencias', key: 'article_stock(Articles.id)', width: 100 },
			{ text: '€uros +\\-', key: 'article_subtraction(Articles.id)', width: 100 },
		];

		// Prepare rows
		let rows = [];
		if ( self.state.rows ) self.state.rows.forEach((el, idx) => {
			let tmpRow = [];
			tmpRow.push(
				{text: el.description, align: 'left'},
				{text: formatNumber(el.tons_buy, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.tons_buy) < 0 ? 'red' : ''},
				{text: formatNumber(el.tons_sell, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.tons_sell) < 0 ? 'red' : ''},
				{text: formatNumber(el.total_euro_buy, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.total_euro_buy) < 0 ? 'red' : ''},
				{text: formatNumber(el.total_euro_sell, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.total_euro_sell) < 0 ? 'red' : ''},
				{text: formatNumber(el.price_buy, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.price_buy) < 0 ? 'red' : ''},
				{text: formatNumber(el.price_sell, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.price_sell) < 0 ? 'red' : ''},
				{text: formatNumber(el.stock, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.stock) < 0 ? 'red' : ''},
				{text: formatNumber(el.subtraction, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(el.subtraction) < 0 ? 'red' : ''},
			);
			rows.push(tmpRow);
		});

		// Totals
		if ( self.state.totals ) rows.push([
			{text: 'Total', align: 'right', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.sum_tons_buy, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.sum_tons_buy) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.sum_tons_sell, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.sum_tons_sell) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.sum_total_euro_buy, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.sum_total_euro_buy) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.sum_total_euro_sell, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.sum_total_euro_sell) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.avg_price_buy, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.avg_price_buy) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.avg_price_sell, {precision: 2, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.avg_price_sell) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.sum_stock, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.sum_stock) < 0 ? 'red' : '', 'fontWeight': 'bold'},
			{text: formatNumber(self.state.totals.sum_subtraction, {precision: 3, thousand: ".", decimal: ","}), align: 'right', color: parseFloat(self.state.totals.sum_subtraction) < 0 ? 'red' : '', 'fontWeight': 'bold'},
		]);

		// Prepare searchFields
		let searchFields = [
			{ text: 'Descripción', key: 'Articles.description' },
			{ text: 'Grupo 7', key: 'GroupFours.code' }
		];

		// Render
		return (
			<React.Fragment>
				<Parrilla 
					title="50.0 Tráfico Agro"

					header={header} 
					rows={rows} 

					sortCallback={(key) => self.parrillaSortCallback(key)}
					clickRowCallback={(idx) => self.openModal(null, idx)}

					paginator={{page: self.state.page, prevPage: self.state.prevPage, nextPage: self.state.nextPage, lastPage: self.state.lastPage}}
					pageChangeCallback={(page) => self.parrillaChangePageCallback(page)}

					btnAdd={false}
					// btnAddCallback={(event) => self.openModal(event)}

					btnSearch={true}
					searchFields={searchFields}
					clickSearchCallback={(field, query) => self.parrillaSearchCallback(field, query)}
				></Parrilla>

				{ self.state.modalOpened === true &&
					<ArticlesModal 
						id={self.state.modalId}
						close={() => self.closeModal()}
					/>
				}
			</React.Fragment>
		);
	}
}

export default ArticlesPage;
