import React from 'react';
import axios from 'axios';
import {Panel, PanelHeader, PanelBody, PanelFooter} from '../Components/Panel/Panel';

class ConfigPage extends React.Component {

	state = {
		config: {},
		companies: [],
		printers: []
	}

	constructor(props) {
    	super(props);

    	// Refs
	    this.velocidadRef = React.createRef();
	    this.puertoRef = React.createRef();
	    this.companyRef = React.createRef();
	    this.printerRef = React.createRef();
	    this.printerTpvRef = React.createRef();
	    this.printerPeso1Ref = React.createRef();
  	}

	async componentDidMount() {
		const self = this;

		// Execute initial actions
		await self.loadParams();
		await self.getCompaniesFromApi();
		await self.getPrinters();
	}

	async loadParams() {
		const self = this;

		// Read file
		const data = window.ipcRenderer.sendSync('readFile', {file: 'config.json'});
		if ( !data ) return;

		// Convert to json
		let json = JSON.parse(data);
		if ( !json ) return;
		
		// Set state
		self.setState((state) => {
			state.config = json;
			return state;
		});
	}

	async getPrinters() {
		const self = this;

		// Read printers
		const printers = window.ipcRenderer.sendSync('getPrinters');

		// Check if errors
		if ( printers.status === 0 ) {
			window.alert('Error al obtener listado de impresoras: ' + (printers.error));
			return false;
		}
		
		// Set state
		self.setState((state) => {
			state.printers = printers;
			return state;
		});

		// Reselect printers select
			if ( self.state.config && 
					self.state.config.printer_id &&
						this.printerRef.current.querySelector("option[value='"+self.state.config.printer_id+"']")
			) {
				this.printerRef.current.querySelector("option[value='"+self.state.config.printer_id+"']").selected = true;
			}

		// Reselect printers tpv select
			if ( self.state.config && 
					self.state.config.printer_tpv_id &&
						this.printerTpvRef.current.querySelector("option[value='"+self.state.config.printer_tpv_id+"']")
			) {
				this.printerTpvRef.current.querySelector("option[value='"+self.state.config.printer_tpv_id+"']").selected = true;
			}

		// Reselect printers peso1 select
			if ( self.state.config && 
					self.state.config.printer_peso1_id &&
						this.printerPeso1Ref.current.querySelector("option[value='"+self.state.config.printer_peso1_id+"']")
			) {
				this.printerPeso1Ref.current.querySelector("option[value='"+self.state.config.printer_peso1_id+"']").selected = true;
			}
	}

	saveParams() {
		const self = this;

		// Prepare data
		let data = {
			"velocidad": self.velocidadRef.current.value,
			"puerto": self.puertoRef.current.value,
			"company_id": self.companyRef.current.value,
			"printer_id": self.printerRef.current.value,
			"printer_tpv_id": self.printerTpvRef.current.value,
			"printer_peso1_id": self.printerPeso1Ref.current.value,
		};

		// Save
		window.ipcRenderer.sendSync('writeFile', {file: 'config.json', data: JSON.stringify(data)}); 

		// Alert
		alert('Configuración guardada');

		// Reload
		window.location.reload();
	}

	async getCompaniesFromApi() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		await axios.post(
			window.API_URL + '/companies/list',
			{},
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				state.companies = response.data ? response.data.companies : [];
				return state;
			});

			// Reselect companies select
			if ( self.state.config && 
					self.state.config.company_id &&
						this.companyRef.current.querySelector("option[value='"+self.state.config.company_id+"']")
			) {
				this.companyRef.current.querySelector("option[value='"+self.state.config.company_id+"']").selected = true;
			}
		});   
	}

	render() {
		const self = this;

		// Prepare companies
		let companies = [];
		for(let i in self.state.companies) companies.push(<option key={i} value={self.state.companies[i].id}>{self.state.companies[i].name}</option>);

		// Prepare printers
		let printers = [];
		if ( self.state.printers.status ) for(let i in self.state.printers.data) printers.push(<option key={i} value={self.state.printers.data[i]}>{self.state.printers.data[i]}</option>);


		// Render
		return (
			<Panel>
				<PanelHeader><b>Parametrización</b></PanelHeader>
				<PanelBody>
					<div className="row">
						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="company_id">Compañía</label>
								<select id="company_id" ref={self.companyRef} defaultValue={self.state.config.company_id} className="form-control">
									<option defaultValue=""></option>
									{companies}
								</select>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="velocidad">Velocidad</label>
								<input type="text" id="velocidad" defaultValue={self.state.config.velocidad} ref={self.velocidadRef} className="form-control text-right" />
							</div>
						</div>
						
						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="puerto">Puerto</label>
								<input type="text" id="puerto" defaultValue={self.state.config.puerto} ref={self.puertoRef} className="form-control text-right" />
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="printer_id">Impresora</label>
								<select id="printer_id" ref={self.printerRef} defaultValue={self.state.config.printer_id} className="form-control">
									<option defaultValue=""></option>
									{printers}
								</select>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="printer_peso1_id">Peso 1</label>
								<select id="printer_peso1_id" ref={self.printerPeso1Ref} defaultValue={self.state.config.printer_peso1_id} className="form-control">
									<option defaultValue=""></option>
									{printers}
								</select>
							</div>
						</div>

						<div className="col-md-4">
							<div className="form-group">
								<label htmlFor="printer_tpv_id">TPV</label>
								<select id="printer_tpv_id" ref={self.printerTpvRef} defaultValue={self.state.config.printer_tpv_id} className="form-control">
									<option defaultValue=""></option>
									{printers}
								</select>
							</div>
						</div>

						
					</div>
				</PanelBody>
				<PanelFooter className="text-right">
					<button onClick={() => self.saveParams()} className="btn btn-default"><i className="fa fa-save"></i></button>
				</PanelFooter>
			</Panel>
		);
	}
}

export default ConfigPage;
