import React from 'react';

import { withRouter } from "react-router-dom";

import TpvModal from './TpvModal';

class TpvPage extends React.Component {

	state = {
		ticketKey: 0,
	}

	async componentDidMount() {
		
	}

	openTpvModal() {
		const self = this;

		// Update state
		self.setState((state) => {
			state.ticketKey = state.ticketKey + 1;
			return state;
		});
	}

	unmountTpvModal() {
		const self = this;

		// Close
		self.setState((state) => {
			state.ticketKey = 0;
			return state;
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				<div style={{width: '100%', height: '98%', cursor: 'pointer', display: 'block'}} onClick={() => self.openTpvModal()}>
					<img src="img/fondo-parrilla-tpv.jpg" alt="background" style={{width: '100%', height: '100%'}} />
				</div>
				{ self.state.ticketKey > 0 &&
					<TpvModal 
						unmount={() => self.unmountTpvModal()} 
						key={self.state.ticketKey}  
					/>
				}
			</React.Fragment>
		);
	}
}

export default withRouter(TpvPage);
