import React from 'react';
import axios from 'axios';
import qs from 'qs';

import './LoginModal.css';

class LoginModal extends React.Component {

	async doLogin() {
		// Get auth params from python
		let authParams = window.ipcRenderer.sendSync('getAuthParams', null);

		// Do login
		axios.post(
			window.API_URL + '/login',
			qs.stringify(authParams)
		).then((response) => {
			// Save token and reload
			if ( response.data.token ) {
				window.ipcRenderer.sendSync('writeFile', {file: 'apitoken.json', data: JSON.stringify(response.data)}); 
				window.location.reload();
			}
		});      
	}

	render() {
		const self = this;

		return (
			<div id="loginModal">
				<div className="message">Este mensaje esta mostrandose por que hay que reestablecer el puente con la base de datos. Simplemente pulsa reestablecer y todo continuará funcionando con normalidad.</div>
				<div className="button"><button className="btn btn-primary btn-lg" onClick={() => self.doLogin()}>Reestablecer puente</button></div>
			</div>
		);
	}
}

export default LoginModal;