import React from 'react';
import axios from 'axios';

import './UniffiMixesModal.css';

import Persiana from '../../Components/Persiana/Persiana';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

class UniffiMixesModal extends React.Component {

	dataToSave = {
		id: '',
		code: '',
		description: '',
		agent_id: '',
		agent_name: '',
		agent_price: '',
		client_id: '',
		client_name: '',
		show_checkbox: false,
		percent: 0,
		parts: [],
		protein_percent: 0,
		fiber_percent: 0,
		fat_percent: 0,
		ashes_percent: 0,
		price: 0,
		date_last_order: ''
	}

	state = {
		uniffi: [],
		dynamicTableSelectedRow: null,
		formData: {...this.dataToSave},
		persianas: {
			client: false,
			agent: false
		},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.dynamicTableWrapperRef = React.createRef();
		this.modalInputAgentPriceRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Load all uniffi to populate table
		await self.loadAllUniffi();

		// Get data
		if ( self.props.id != null ) await self.loadModalData(self.props.id);

		// Show dynamic table wrapper
		self.dynamicTableWrapperRef.current.style.display = "block";
	}

	componentDidUpdate() {
		// Tooltips
		window.$('[data-toggle=tooltip]').tooltip();
	}

	async loadAllUniffi() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		return axios.get(
			window.API_URL + '/uniffi/all',
			{	
				headers: authHeaders
			}
		).then((response) => {
			// If not response, return
			if ( !response.data.data ) return;

			// Update state
			self.setState((state) => {
				state.uniffi = response.data.data;
				return state;
			});		

			// Create refs
			response.data.data.forEach((el, idx) => {
				self['dynamicTableRowRef' + el.id] = React.createRef();	
			});
		});
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	mountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		return axios.get(
			window.API_URL + '/uniffimixes/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// If not response, return
			if ( !response.data.data ) return;

			// Update state
			self.setState((state) => {
				// General data
				state.formData = response.data.data;

				// Associated
				state.formData.client_name = state.formData.client ? state.formData.client.name : '';
				state.formData.agent_name = state.formData.agent ? state.formData.agent.name : '';

				// Return
				return state;
			});

			// Numbers fix
			self.modalInputAgentPriceRef.current.value = formatNumber(response.data.data.agent_price, {precision: 2, thousand: ".", decimal: ","});

			// Set parts on dynamic table
			self.state.uniffi.forEach((uel, uidx) => {
				// Get value
				let value = null;
				response.data.data.parts.forEach((el, idx) => {
					if ( uel.id === el.uniffi_id ) value = el.percent;
				});
				if ( value === 0 ) value = null;

				// Update and (show or hide)
				if ( value !== null ) {
					self['dynamicTableRowRef' + uel.id].current.style.display = "";
					self['dynamicTableRowRef' + uel.id].current.querySelector('input').value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});
				} else {
					self['dynamicTableRowRef' + uel.id].current.style.display = "none";
				}
			});

			// Calc all to show percent an price
			setTimeout(() => {
				self.calcAll();
			}, 0);
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Check if percent invalid
		if ( parseFloat(self.state.formData.percent) !== 100.00 ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];
		data.agent_price = formatNumber(data.agent_price, {precision: 2, thousand: "", decimal: "."});
		data.show_checkbox = data.show_checkbox ? 1 : 0;

		// Save ajax
		axios.post(
			window.API_URL + '/uniffimixes/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	async deleteModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Ask
		let c = window.confirm('¿Seguro que quieres eliminar esta recepción?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/uniffimixes/delete',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			self.closeModal();
		});
	}

	async checkUniqueCode() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/uniffimixes/checkUniqueCode',
			qs.stringify({code: self.state.formData.code, id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				state.errors.code = response.data.errors.code ? response.data.errors.code : '';
				state.errors.pattern = response.data.errors.pattern ? response.data.errors.pattern : '';
				return state;
			});
		});
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	handleInputCheckboxChange(event) {
		const self = this;

		// Get status
		let status = event.target.checked;

		// Update
		self.setState((state) => {
			state.formData['show_checkbox'] = status;
			return state;
		});
	}

	inputClientSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			// Client data
			state.formData.client_id = data ? data.id : '';
			state.formData.client_name = data ? data.name : '';

			// State
			return state;
		});
	}

	inputAgentSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			// Client data
			state.formData.agent_id = data ? data.id : '';
			state.formData.agent_name = data ? data.name : '';

			// State
			return state;
		});
	}

	handleInputAgentPriceChange(event) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) value = 0;

		// Set value
		self.modalInputAgentPriceRef.current.value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData['agent_price'] = value;
			return state;
		});

		// Calc all (fix: call after state changed)
		setTimeout(() => {
			self.calcAll();
		}, 0);
	}

	selectDynamicTableRow(idx) {
		const self = this;

		self.setState((state) => {
			state.dynamicTableSelectedRow = idx;
			return state;
		});
	}

	inputKeyListenerDynamicTableRow(event) {
		const self = this;

		event.persist()

		// Get key code
		let keyCode = event.keyCode;

		// Position
		let newPosition = self.state.dynamicTableSelectedRow;

		// Actions
		switch(keyCode) {
			case 38: // Up
				// Prevent
				event.preventDefault();

				// Move
				if ( self.state.dynamicTableSelectedRow > 0 ) {
					// Calc position
					newPosition = self.state.dynamicTableSelectedRow - 1;

					// Set position
					self.selectDynamicTableRow(newPosition);

					// Check if hidden row
					let tmpId = self.state.uniffi[newPosition].id;
					if ( self['dynamicTableRowRef' + tmpId].current.style.display === "none" ) {
						setTimeout(() => {
							self.inputKeyListenerDynamicTableRow(event);
						}, 0);
						return false;
					}

					// Set focus to input and fix scrolltop on container
					self.setFocusInputDynamicTable(self.state.uniffi[newPosition].id, keyCode);
				}
			break;

			case 40: // Down
			case 13: // Enter
				// Prevent
				event.preventDefault();
				
				// Move
				if ( self.state.dynamicTableSelectedRow < self.state.uniffi.length-1 ) {
					// Calc position
					newPosition = self.state.dynamicTableSelectedRow + 1;

					// Set position
					self.selectDynamicTableRow(newPosition);

					// Check if hidden row
					let tmpId = self.state.uniffi[newPosition].id;
					if ( self['dynamicTableRowRef' + tmpId].current.style.display === "none" ) {
						setTimeout(() => {
							self.inputKeyListenerDynamicTableRow(event);
						}, 0);
						return false;
					}

					// Set focus to input and fix scrolltop on container
					self.setFocusInputDynamicTable(self.state.uniffi[newPosition].id, keyCode);
				}
			break;
			default: break;
		}
	}

	setFocusInputDynamicTable(id, keyCode) {
		const self = this;

		// Get before container scrolltop
		let beforeContainerScrollTop = self.dynamicTableWrapperRef.current.scrollTop;

		// Focus and select text
		self['dynamicTableRowRef' + id].current.querySelector('input').focus();	
		self['dynamicTableRowRef' + id].current.querySelector('input').select();

		// Fix Set scrolltop to before
		self.dynamicTableWrapperRef.current.scrollTop = beforeContainerScrollTop;

		// Get properties to check
		let elTop = self['dynamicTableRowRef' + id].current.offsetTop;
		let elHeight = self['dynamicTableRowRef' + id].current.clientHeight;
		let containerHeight = self.dynamicTableWrapperRef.current.clientHeight;
		
		// If movement is up
		if ( keyCode === 38 && elTop < beforeContainerScrollTop ) self.dynamicTableWrapperRef.current.scrollTop = elTop;

		// If movement is down
		if ( (keyCode === 40 || keyCode === 13) && elTop + elHeight > containerHeight ) self.dynamicTableWrapperRef.current.scrollTop = Math.abs( containerHeight - (elTop + elHeight) );
	}

	handleInputDynamicTableChange(id) {
		const self = this;

		// Get input
		let input = self['dynamicTableRowRef' + id].current.querySelector('input');

		// Get value
		let value = parseFloat( input.value.replace(/\,/g,'.') );
		if ( isNaN(value) || value < 0 || value > 100 ) value = 0;

		// Set value
		input.value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Get part position
		let position = (() => {
			let pos = null;
			self.state.formData.parts.forEach((el, idx) => {
				if ( el.uniffi_id === parseInt(id) ) pos = idx;
			});
			return pos !== null ? pos : self.state.formData.parts.length;
		})();

		// Update state
		self.setState((state) => {
			state.formData.parts[position] = {
				uniffi_id: id,
				percent: value
			};
			return state;
		});
		
		// Calc all (fix timeout to update state before calc)
		setTimeout(() => {
			self.calcAll();
		}, 0);
	}

	calcAll() {
		const self = this;

		// Percent
		let percent = 0.00;
		self.state.formData.parts.forEach((el, idx) => {
			if ( !isNaN(el.percent) ) percent += el.percent;
		});
		percent = formatNumber(percent, {precision: 2, thousand: "", decimal: "."});
		if ( percent < 100 ) { // Show all rows (if there are hidden rows)
			self.state.uniffi.forEach((uel, uidx) => {
				self['dynamicTableRowRef' + uel.id].current.style.display = "";
			});
		}

		// Price
		let price = 0.00;
		self.state.formData.parts.forEach((el, idx) => {
			let row_percent = el.percent;
			let row_price = (() => {
				let price = 0;
				self.state.uniffi.forEach((uel, uidx) => {
					if ( uel.id == el.uniffi_id ) price = uel.price_sale;
				});
				return price;
			})();
			let uniffi_final_price = row_price * (row_percent / 100);
			price += uniffi_final_price;
		});
		price = formatNumber(price + self.state.formData.agent_price, {precision: 0, thousand: "", decimal: ""});

		// Percents
		let percent_keys = [
			{name: 'protein_percent', qty: 0.0}, 
			{name: 'fiber_percent', qty: 0.0}, 
			{name: 'fat_percent', qty: 0.0}, 
			{name: 'ashes_percent', qty: 0.0}
		];
		self.state.formData.parts.forEach((el, idx) => {
			let row_percent = el.percent;
			percent_keys.forEach((el2, idx2) => {
				let key_percent = (() => {
					let percent = 0;
					self.state.uniffi.forEach((uel, uidx) => {
						if ( uel.id == el.uniffi_id ) percent = uel[el2.name];
					});
					return percent;
				})();
				let final_key_percent = key_percent * (row_percent / 100);
				el2.qty += final_key_percent;
			});
		});

		// Set state
		self.setState((state) => {
			// Percent
			state.formData.percent = percent;
			if ( parseFloat(percent) !== 100.00 ) state.errors.percent = {wrongPercent: 'Porcentaje incorrecto'};
			else state.errors.percent = null;

			// Price
			state.formData.price = price;

			// Percents
			percent_keys.forEach((el, idx) => {
				state.formData[el.name] = el.qty;
			});
			
			// Return
			return state;
		});
	}


	async printOrder(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/uniffimixes/pdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data});

			// Set button to left
			self.setState((state) => {
				state.formData.printed = true;
				return state;
			});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	async printLabel(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/uniffimixes/labelPdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	
	render() {
		const self = this;

		// Prepare dynamic table
		let dynamicTableRows = [];
		self.state.uniffi.forEach((el, idx) => {
			dynamicTableRows.push(
				<tr ref={self['dynamicTableRowRef' + el.id]} key={idx} className={self.state.dynamicTableSelectedRow === idx ? 'selected' : ''}>
					<td>{el.description}</td>
					<td className="input_wrapper">
						<input type="text" defaultValue="0,00" onFocus={(event) => self.selectDynamicTableRow(idx)} onBlur={(event) => self.handleInputDynamicTableChange(el.id)} onKeyDown={(event) => self.inputKeyListenerDynamicTableRow(event)} onClick={(event) => event.target.select()} />
					</td>
					<td>{ formatNumber(el.price_sale, {precision: 2, thousand: ".", decimal: ","}) }</td>
				</tr>
			);
		});

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.client === true &&
					<Persiana 
						title="Clientes" 
						url={window.API_URL + '/clients/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('client')} 
						selectCallback={(data) => self.inputClientSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.agent === true &&
					<Persiana 
						title="Agentes" 
						url={window.API_URL + '/agents/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('agent')} 
						selectCallback={(data) => self.inputAgentSelectCallback(data)} 
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header d-block">
								<h5 className="modal-title float-left">50.5 Uniffi</h5>

								<button type="button" className="close float-right" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>

								<label htmlFor="show_checkbox" className="float-right mt-1 mr-3"><input type="checkbox" id="show_checkbox" onChange={(event) => self.handleInputCheckboxChange(event)} checked={self.state.formData.show_checkbox} /> Incluir en el Blog</label>
							</div>
							<div className="modal-body">
								<div className="row">

									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="code">Código</label>
											<input type="text" id="code" className="form-control text-right" onChange={(event) => self.handleInputChange(event, 'code')} onBlur={() => self.checkUniqueCode()}  value={self.state.formData.code} />
										</div>
										{ self.state.errors.code && 
											<div className="error">{ self.state.errors.code[Object.keys(self.state.errors.code)[0]] }</div>
										}
										{ self.state.errors.pattern && 
											<div className="error">{ self.state.errors.pattern[Object.keys(self.state.errors.pattern)[0]] }</div>
										}
									</div>

									<div className="col-md-8">
										<div className="form-group">
											<label htmlFor="description">Descripción</label>
											<input type="text" id="description" className="form-control" onChange={(event) => self.handleInputChange(event, 'description')}  value={self.state.formData.description} />
										</div>
										{ self.state.errors.description && 
											<div className="error">{ self.state.errors.description[Object.keys(self.state.errors.description)[0]] }</div>
										}
									</div>

									<div className="col-md-7">
										<div className="form-group">
											<label htmlFor="agent_name">Agente</label>
											<div className="input-group">
												<input type="text" id="client_name" className="form-control" value={self.state.formData.agent_name} onFocus={() => self.mountPersiana('agent')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('agent')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.agent_id && 
											<div className="error">{ self.state.errors.agent_id[Object.keys(self.state.errors.agent_id)[0]] }</div>
										}
									</div>
							
									<div className="col-md-5">
										<div className="row">
											<div className="col-md-6 pr-0">
												<div className="form-group">
													<label htmlFor="agent_price">€/Tn Agente</label>
													<input type="text" id="agent_price" className="form-control text-right" ref={self.modalInputAgentPriceRef} onBlur={(event) => self.handleInputAgentPriceChange(event)} onClick={(event) => event.target.select()} />
													{ self.state.errors.agent_price && 
														<div className="error">{ self.state.errors.agent_price[Object.keys(self.state.errors.agent_price)[0]] }</div>
													}
												</div>
											</div>
											<div className="col-md-6 pl-0">
												<div className="form-group">
													<label htmlFor="date">Fecha Pedido</label>
													<input type="text" id="date" className="form-control text-right" value={self.state.formData.date_last_order ? self.state.formData.date_last_order : ''} readOnly />
												</div>
												{ self.state.errors.date && 
													<div className="error">{ self.state.errors.date[Object.keys(self.state.errors.date)[0]] }</div>
												}
											</div>
										</div>
									</div>

									<div className="col-md-7">
										<div className="form-group">
											<label htmlFor="client_name">Cliente</label>
											<div className="input-group">
												<input type="text" id="client_name" className="form-control" value={self.state.formData.client_name} onFocus={() => self.mountPersiana('client')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('client')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.client_id && 
											<div className="error">{ self.state.errors.client_id[Object.keys(self.state.errors.client_id)[0]] }</div>
										}
									</div>

									<div className="col-md-5">
										<div className="row">
											<div className="col-md-6 pr-0">
												<div className="form-group">
													<label htmlFor="percent">% Porcentaje</label>
													<input type="text" id="percent" className="form-control text-right" value={formatNumber(self.state.formData.percent, {precision: 2, thousand: "", decimal: "."})} readOnly />
													{ self.state.errors.percent && 
														<div className="error">{ self.state.errors.percent[Object.keys(self.state.errors.percent)[0]] }</div>
													}
												</div>
											</div>
											<div className="col-md-6 pl-0">
												<div className="form-group">
													<label htmlFor="price">€ Precio</label>
													<input type="text" id="price" className="form-control text-right" value={self.state.formData.price} readOnly />
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12 mb-2">
										<div id="dynamicTableWrapper" ref={self.dynamicTableWrapperRef}>
											<table className="table table-bordered" id="dynamicTable">
												<tbody>
													{ dynamicTableRows }
												</tbody>
											</table>
										</div>
									</div>

									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="protein_percent">% Proteína Bruta</label>
											<input type="text" id="protein_percent" className="form-control text-right" value={formatNumber(self.state.formData.protein_percent, {precision: 2, thousand: '.', decimal: ','})} readOnly />
										</div>
									</div>

									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="fiber_percent">% Fibra Bruta</label>
											<input type="text" id="fiber_percent" className="form-control text-right" value={formatNumber(self.state.formData.fiber_percent, {precision: 2, thousand: '.', decimal: ','})} readOnly />
										</div>
									</div>

									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="fat_percent">% Act. y Gra. Bruta</label>
											<input type="text" id="fat_percent" className="form-control text-right" value={formatNumber(self.state.formData.fat_percent, {precision: 2, thousand: '.', decimal: ','})} readOnly />
										</div>
									</div>

									<div className="col-md-3">
										<div className="form-group">
											<label htmlFor="ashes_percent">% Ceniza Bruta</label>
											<input type="text" id="ashes_percent" className="form-control text-right" value={formatNumber(self.state.formData.ashes_percent, {precision: 2, thousand: '.', decimal: ','})} readOnly />
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" ref={self.btnSaveRef} className="btn btn-default float-right ml-1" onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>

								{ self.state.formData.id &&
									<React.Fragment>
										<button type="button" className="btn btn-default float-left mr-1" tabIndex="-1" onClick={(event) => self.deleteModalData(event)}><i className="fa fa-minus-circle"></i></button>
										<button type="button" className="btn btn-default float-right" onClick={(event) => self.printOrder(event)} data-toggle="tooltip" data-placement="bottom" title="Imprimir pedido"><i className="fa fa-print"></i></button>
										<button type="button" className="btn btn-default float-right mr-1" onClick={(event) => self.printLabel(event)} data-toggle="tooltip" data-placement="bottom" title="Imprimir etiqueta"><i className="fa fa-tag"></i></button>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default UniffiMixesModal;
