import React from 'react';
import axios from 'axios';

import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";

import LoginModal from './Components/LoginModal/LoginModal';
import ConfigPage from './Pages/ConfigPage';
import UniffiPage from './Pages/UniffiPage/UniffiPage';
import BasculeTicketsPage from './Pages/BasculeTicketsPage/BasculeTicketsPage';
import UniffiMixesPage from './Pages/UniffiMixesPage/UniffiMixesPage';
import UniffiMixesOrdersPage from './Pages/UniffiMixesOrdersPage/UniffiMixesOrdersPage';
import AgriculturalReceptionsPage from './Pages/AgriculturalReceptionsPage/AgriculturalReceptionsPage';
import SemillasReceptionsPage from './Pages/SemillasReceptionsPage/SemillasReceptionsPage';
import ArticlesPage from './Pages/ArticlesPage/ArticlesPage';
import UniffiPricesPage from './Pages/UniffiPricesPage/UniffiPricesPage';
import TpvPage from './Pages/TpvPage/TpvPage';
import ParcelsPage from './Pages/ParcelsPage/ParcelsPage';

class App extends React.Component {

	state = {
		'dateString': null,
		'loginModalVisible': false,
		'online': true,
		'company_id': null
	}

	constructor(props) {
		super(props);

		// Global variables
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') window.API_URL = "http://grupoverbo.local/api";
		else window.API_URL = "https://grupoverbo.es/api";		

		// Global functions
		window.prepareAuthHeaders = () => this.prepareAuthHeaders();
		window.showLoginModal = () => this.showLoginModal();
		window.alert = (msg) => this.alert(msg);
		window.confirm = (msg) => this.confirm(msg);

		// Loader interval
		this.loaderInterval = null;

		// Refs
		this.companyLogoRef = React.createRef();
		this.loginModalRef = React.createRef();
		this.loaderRef = React.createRef();
		this.loaderCounterRef = React.createRef();
		this.dateRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Init time
		self.updateTime();
		setInterval(() => self.updateTime(), 1000);

		// Axios interceptors
		self.setAxiosInterceptors();

		// Check navigator online
		self.checkNavigatorOnline();

		// Get Company id
		self.getCompanyId();
	}

	async getCompanyId() {
		const self = this;
		let data = await self.prepareAuthHeaders();
		self.setState((state) => {
			state.company_id = data['x-gv-company'];
			return state;
		});
	}

	alert(msg) {
		window.ipcRenderer.sendSync('alert', {message: msg});
		return false;
	}

	confirm(msg) {
		return window.ipcRenderer.sendSync('confirm', {message: msg});
	}

	checkNavigatorOnline() {
		const self = this;

		setInterval(function(){
			// Check if state changed
			if ( self.state.online === navigator.onLine ) return;

			// Update
			self.setState((state) => {
				state.online = navigator.onLine;
				return state;
			});
		}, 100);
	}

	setAxiosInterceptors() {
		const self = this;

		axios.defaults.headers.common['Cache-Control'] = 'no-cache';

		axios.interceptors.request.use(
	        conf => {
	        	// Show loader
		       	self.loaderRef.current.style.display = 'flex';
		       	if ( !self.loaderInterval ) {
		       		self.loaderInterval = setInterval(() => {
		       			let number = parseInt(self.loaderCounterRef.current.innerHTML);
		       			number += 1;
		       			self.loaderCounterRef.current.innerHTML = number;
		       		}, 1000);
		       	}

	            return conf;
	        },
	        error => {
	            return Promise.reject(error);
	        }
	    );

		axios.interceptors.response.use((response) => {
			// Hide loader
	       	self.loaderRef.current.style.display = 'none'; 
		    clearInterval(self.loaderInterval); 
		    self.loaderInterval = null;
		    self.loaderCounterRef.current.innerHTML = 0;

		    // Responses
		    if ( response.data.status === 0 ) self.showLoginModal();
		    if ( response.data.status === -1 ) {
		    	window.location.href="#/config";
		    	return false;
		    }
		    return response;
	  	}, (error) => {
	  		document.getElementById('loader').style.display = 'none'; // Hide loader
		    return Promise.reject(error);
	  	});
	}

	async prepareAuthHeaders() {
		return {
			'x-gv-token': (() => {
				// Read file
				const data = window.ipcRenderer.sendSync('readFile', {file: 'apitoken.json'});
				if ( !data ) return '';

				// Convert to json
				let json = JSON.parse(data);
				if ( !json ) return '';

				// Return
				return json.token;
			})(),
			'x-gv-company': (() => {
				// Read file
				const data = window.ipcRenderer.sendSync('readFile', {file: 'config.json'});
				if ( !data ) return '';

				// Convert to json
				let json = JSON.parse(data);
				if ( !json ) return '';

				// Return
				return json.company_id;
			})(),
		}
	}

	showLoginModal() {
		const self = this;
		self.setState((state) => {
			state.loginModalVisible = true;
			return state;
		});
	}

	updateTime() {
		const self = this;

		// Get date
		let date = new Date();

		// Texts
        const weekdays = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado", "Domingo"];
        const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

		// Prepare string
		let date_string = weekdays[date.getDay()-1] + ' ' + date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() + ' Horas';

		// Update state
		self.dateRef.current.innerHTML = date_string;
	}

	render() {
		const self = this;

		// Render
		return (
			<Router>
				<div ref={self.loaderRef} id="loader">
					<img src="/img/ajax-loader.gif" alt="loader" />
					<span ref={self.loaderCounterRef}>0</span>
				</div>

				{
					!self.state.online &&
					<div id="offline">Este programa no puede funcionar sin conexión a Internet. Por favor, revisa la conexión. En cuanto vuelva la conexión desaparecerá este mensaje.</div>
				}

				{
					self.state.loginModalVisible &&
					<LoginModal />
				}

				<div id="wrapper">
				    <ul className="navbar-nav sidebar sidebar-dark accordion">
					    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/" onClick={(event) => event.preventDefault()} >
						    <div className="sidebar-brand-text mx-3">
						    	<div>Gv Báscula</div>
						    	<img src={window.API_URL + '/../img/companies/' + self.state.company_id + '/logo_circle.png'} alt="logo" />
						    </div>
					    </a>
					    {/* <li className="nav-item">
					    	<NavLink to="/articles" className="nav-link">
							    <span>50.0 Tráfico Agro</span>
						    </NavLink>
					    </li> */}
					    <li className="nav-item">
					    	<NavLink to="/semillasreceptions" className="nav-link">
							    <span>50.0 Recep. Semillas</span>
						    </NavLink>
					    </li>
					    <li className="nav-item">
					    	<NavLink to="/agriculturalreceptions" className="nav-link">
							    <span>50.1 Recepción Agro</span>
						    </NavLink>
					    </li>
					    <li className="nav-item">
						    <NavLink to="/basculetickets" className="nav-link">
							    <span>50.2 Diario</span>
						    </NavLink>
					    </li>
					    <li className="nav-item">
						    <NavLink to="/uniffimixesorders" className="nav-link">
							    <span>50.3 Pedidos</span>
						    </NavLink>
					    </li>
					    <li className="nav-item">
						    <NavLink to="/basculetickets/true" className="nav-link">
							    <span>50.4 Ticket</span>
						    </NavLink>
					    </li>
					    <li className="nav-item">
						    <NavLink to="/uniffimixes" className="nav-link">
							    <span>50.5 Uniffi</span>
						    </NavLink>
					    </li>
					    <li className="nav-item">
						    <NavLink to="/uniffi" className="nav-link">
							    <span>50.6 M. Primas Uniffi</span>
						    </NavLink>
					    </li>
					    {/* <li className="nav-item">
						    <NavLink to="/uniffiprices" className="nav-link">
							    <span>50.7 Blog</span>
						    </NavLink>
					    </li> */}
					    <li className="nav-item" style={{cursor: 'pointer'}}>
					        { /*
						    <a onClick={() => { window.location.href='/#/tpv'; window.location.reload(); return false; }} className="nav-link">
							    <span>28.7 TPV</span>
						    </a>
						    */}
						   	<NavLink to="/tpv" className="nav-link">  
						   		<span>50.7 TPV</span>
						    </NavLink>
					    </li>
						<li className="nav-item">
						    <NavLink to="/parcels" className="nav-link">
							    <span>50.8 Parcelas</span>
						    </NavLink>
					    </li>
				    </ul>

				    <div id="content-wrapper" className="d-flex flex-column">
				      	<div id="content">
				        	<nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top">
						        <ul className="navbar-nav ml-auto">
						        	<li className="nav-item time">
						        		<a className="nav-link" href="/" onClick={(event) => event.preventDefault()} ref={self.dateRef}></a>
						            </li>
						        	<li className="nav-item">
							        	<Link to="/config" className="nav-link">
										   	<i className="fas fa-cog fa-fw"></i>
									    </Link>
						            </li>
					          	</ul>
					        </nav>
					        <div className="container-fluid" id="pages-wrapper">
					        	<Switch>
									<Route path="/config">
										<ConfigPage />
									</Route>
									<Route path="/basculetickets/true">
										<BasculeTicketsPage showTicket={true} key="showTicket" />
									</Route>
									<Route path="/basculetickets">
										<BasculeTicketsPage showTicket={false} key="notShowTicket" />
									</Route>
									<Route path="/uniffimixesorders">
										<UniffiMixesOrdersPage />
									</Route>
									<Route path="/uniffimixes">
										<UniffiMixesPage />
									</Route>
									<Route path="/uniffiprices">
										<UniffiPricesPage />
									</Route>
									<Route path="/uniffi">
										<UniffiPage />
									</Route>
									<Route path="/agriculturalreceptions">
										<AgriculturalReceptionsPage />
									</Route>
									<Route path="/semillasreceptions">
										<SemillasReceptionsPage />
									</Route>
									<Route path="/articles">
										<ArticlesPage />
									</Route>
									<Route path="/tpv">
										<TpvPage />
									</Route>
									<Route path="/parcels">
										<ParcelsPage />
									</Route>
								</Switch>
			        		</div>
					    </div>
					</div>
				</div>
			</Router>
		);
	}
}

export default App;
