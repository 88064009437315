import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';
import styled from 'styled-components';

const Modal = styled.div`
    z-index: 3000;
    background: rgba(1,1,1,0.5);
`;

export default function Parcels(props) {

    const modalRef = useRef(null);

    const [data, setData] = useState({
        farmer_id: props.farmerId
    });
    const [errors, setErrors] = useState(undefined);

    useEffect(() => {
        // Show modal
		window.$(modalRef.current).modal({backdrop: false, keyboard: false});
    }, []);

    const setDataField = (field, value) => {
        setData((prev) => ({
            ...prev,
            [field]: value
        }));
    }

    const saveData = async () => {
        // Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

        let toSave = {...data};
        delete toSave.payment_method;

		// Ajax
		axios.post(
			window.API_URL + '/parcels/save',
			qs.stringify(toSave),
			{headers: authHeaders}
		).then((response) => {
            if ( response.data.status ) {
		        window.$(modalRef.current).modal('hide');
                props.closeCallback(response.data.parcel);
            } else {
                setErrors({...response.data.errors});
            }
		});
    }

    const closeModal = () => {
        window.$(modalRef.current).modal('hide');
        props.closeCallback();
    }

    const associateParcelToFarmer = async () => {
        let toSave = {
            parcel: data.parcel,
            farmer_id: props.farmerId
        };

		let authHeaders = await window.prepareAuthHeaders();
        
		axios.post(
			window.API_URL + '/parcels/associate-farmer',
			qs.stringify(toSave),
			{headers: authHeaders}
		).then((response) => {
            if ( response.data.status ) {
		        window.$(modalRef.current).modal('hide');
                props.closeCallback(response.data.parcel);
            } else {
                setErrors({...response.data.errors});
            }
		});
    }

    return (
        <Modal className="modal" tabIndex="-1" role="dialog" ref={modalRef}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Conductor</h5>
                        <button type="button" className="close" onClick={(e) => closeModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="place">Paraje</label>
                                    <input type="text" id="place" className="form-control" onChange={(event) => setDataField('place', event.target.value)}  value={data.place ?? ''} />
                                </div>
                                { errors?.place && 
                                    <div className="error">{ errors.place[Object.keys(errors.place)[0]] }</div>
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="municipality">Municipio</label>
                                    <input type="text" id="municipality" className="form-control" onChange={(event) => setDataField('municipality', event.target.value)}  value={data.municipality ?? ''} />
                                </div>
                                { errors?.municipality && 
                                    <div className="error">{ errors.municipality[Object.keys(errors.municipality)[0]] }</div>
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="polygon">Polígono</label>
                                    <input type="text" id="polygon" className="form-control" onChange={(event) => setDataField('polygon', event.target.value)}  value={data.polygon ?? ''} />
                                </div>
                                { errors?.polygon && 
                                    <div className="error">{ errors.polygon[Object.keys(errors.polygon)[0]] }</div>
                                }
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label htmlFor="parcel">Parcela</label>
                                    <input type="text" id="parcel" className="form-control" onChange={(event) => setDataField('parcel', event.target.value)}  value={data.parcel ?? ''} />
                                </div>
                                { errors?.parcel && 
                                    <React.Fragment>
                                        { (errors.parcel['unique'] && props.farmerId) ?
                                            <div>
                                                <span className="error">Ya existe una parcela con el mismo código</span>
                                                <div>
                                                    <button className="btn btn-default btn-sm mt-2" onClick={associateParcelToFarmer}>Asociar parcela existente</button>
                                                </div>
                                            </div>
                                            :
                                            <div className="error">{ errors.parcel[Object.keys(errors.parcel)[0]] }</div>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-block">
                        <button type="button" id="save" className="btn btn-default float-right" onClick={(e) => saveData(e)}><i className="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </Modal>
    );  
}