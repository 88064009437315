import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';
import styled from 'styled-components';
import Persiana from '../Persiana/Persiana';

const Modal = styled.div`
    z-index: 3000;
    background: rgba(1,1,1,0.5);
`;

const VATS = [10, 11, 12, 15, 18, 21, 19, 20, 24, 22, 23];

export default function Articles(props) {

    const modalRef = useRef(null);

    const [data, setData] = useState({
        internaltraffic_checkbox: 0,
    });
    const [errors, setErrors] = useState(undefined);
    const [showPersianaGroupFour, setShowPersianaGroupFour] = useState(false);
    const [showPersianaVat, setShowPersianaVat] = useState(false);

    useEffect(() => {
        // Show modal
		window.$(modalRef.current).modal({backdrop: false, keyboard: false});
    }, []);

    useEffect(() => {
        setDataField('internaltraffic_checkbox', props.internalTraffic ? 1 : 0);
        setDataField('semilla_checkbox', props.semilla ? 1 : 0);
    }, [props.internalTraffic, props.semilla]);

    useEffect(() => {
        const generateCode = async () => {
            // Auth Headers
            let authHeaders = await window.prepareAuthHeaders();

            // Get data
            axios.get(
                window.API_URL + '/articles/generate-code',
                {	
                    params: { groupfour_id: data.groupfour_id },
                    headers: authHeaders
                }
            ).then((response) => {
                setData((prev) => ({...prev, code: response.data.code}));
            });
        }
        generateCode();
    }, [data.groupfour_id]);

    const setDataField = (field, value) => {
        setData((prev) => ({
            ...prev,
            [field]: value
        }));
    }

    const saveData = async () => {
        // Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

        let toSave = {...data};
        Object.keys(toSave).forEach((el) => {
            if ( typeof toSave[el] === 'object' ) delete toSave[el];
        });

		// Ajax
		axios.post(
			window.API_URL + '/articles/save',
			qs.stringify(toSave),
			{headers: authHeaders}
		).then((response) => {
            if ( response.data.status ) {
		        window.$(modalRef.current).modal('hide');
                props.closeCallback(response.data.article);
            } else {
                setErrors({...response.data.errors});
            }
		});
    }

    const closeModal = () => {
        window.$(modalRef.current).modal('hide');
        props.closeCallback();
    }

    return (
        <Modal className="modal" tabIndex="-1" role="dialog" ref={modalRef}>
            { showPersianaGroupFour === true &&
                <Persiana 
                    title="Grupo 4" 
                    url={window.API_URL + '/groupfours/all'} 
                    fields={['code', 'description']}
                    unmount={() => setShowPersianaGroupFour(false)} 
                    selectCallback={(data) => {
                        setData((prev) => ({
                            ...prev,
                            groupfour: {
                                id: data?.id,
                                name: (data?.code ?? '') + ' ' + (data?.description ?? '')
                            },
                            groupfour_id: data?.id
                        }));
                    }} 
                />
            }
            { showPersianaVat !== false &&
                <Persiana 
                    title={'IVA ' + showPersianaVat} 
                    url={window.API_URL + '/vats/all'} 
                    fields={['code', 'name']}
                    unmount={() => setShowPersianaVat(false)} 
                    selectCallback={(data) => {
                        setData((prev) => ({
                            ...prev,
                            ['vat' + showPersianaVat]: {
                                id: data?.id,
                                name: (data?.code ?? '') + ' ' + (data?.name ?? '')
                            },
                            ['vat' + showPersianaVat + '_id']: data?.id
                        }));
                    }} 
                />
            }
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ props.title ?? 'Artículo' }</h5>
                        <button type="button" className="close" onClick={(e) => closeModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <label htmlFor="internaltraffic_checkbox" className="float-left">T.Agro</label>
                                <label htmlFor="semilla_checkbox" className="float-left">Semilla</label>
                                <label htmlFor="code" className="float-right">Código</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.internaltraffic_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    if ( !props.internalTraffic ) setDataField('internaltraffic_checkbox', e.target.checked ? 1 : 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.semilla_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    if ( !props.semilla ) setDataField('semilla_checkbox', e.target.checked ? 1 : 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                    <input type="text" className="form-control text-right" id="code" value={data.code ?? ''} onChange={(e) => null} readOnly />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="row">
                                    <div className="col-md-7 pr-0">
                                        <label htmlFor="description">Descripción</label>
                                        <input type="text" className="form-control" id="description" value={data.description ?? ''} onChange={(e) => setDataField('description', e.target.value)} />
                                        { (errors && errors['description']) &&
                                            <div className="invalid-feedback d-block">
                                                { errors['description'][Object.keys(errors['description'])[0]] ?? '' }    
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-5 pl-0">
                                        <label htmlFor="royalty" className="float-right">Royalty</label>
                                        <input 
                                            type="text" 
                                            className="form-control text-right input-numeric-formatted" 
                                            id="royalty" 
                                            value={data.royalty_formatted ?? 0} 
                                            onChange={(e) => {
                                                setDataField('royalty', e.target.value);
                                                setDataField('royalty_formatted', e.target.value);
                                            }}
                                            onFocus={() => setDataField('royalty_formatted', data.royalty ?? 0)}
                                            onBlur={() => {
                                                setDataField('royalty', parseFloat(data.royalty).toFixed(3));
                                                setDataField('royalty_formatted', formatNumber(data.royalty ?? 0, {precision: 3, thousand: ".", decimal: ","}));
                                            }}
                                        />
                                        { (errors && errors['royalty']) &&
                                            <div className="invalid-feedback d-block">
                                                { errors['royalty'][Object.keys(errors['royalty'])[0]] ?? '' }    
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Tdas Compra</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Tdas Venta</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Existencias</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Total €uros Compra</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Precio de Compra</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Total €uros Venta</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Precio de Venta</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label>Renta Bruta</label>
                                <input type="text" className="form-control" readOnly />
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="account_buy">Cuenta Compras</label>
                                <input type="text" className="form-control" id="account_buy" value={data.account_buy ?? ''} onChange={(e) => setDataField('account_buy', e.target.value)} />
                                { (errors && errors['account_buy']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['account_buy'][Object.keys(errors['account_buy'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="account_sell">Cuenta Ventas</label>
                                <input type="text" className="form-control" id="account_sell" value={data.account_sell ?? ''} onChange={(e) => setDataField('account_sell', e.target.value)} />
                                { (errors && errors['account_sell']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['account_sell'][Object.keys(errors['account_sell'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="provider_name">Grupo 4</label>
                                <div className="input-group">
                                    <input type="text" id="groupfour_id" className="form-control" value={data.groupfour?.name ?? ''} onFocus={() => setShowPersianaGroupFour(true)} readOnly />
                                    <div className="input-group-append">
                                        <div className="input-group-append">
                                            <button className="btn btn-default" type="button" onClick={() => setShowPersianaGroupFour(true)}><i className="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['groupfour_id']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['groupfour_id'][Object.keys(errors['groupfour_id'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            { VATS.map((el) => {
                                return (
                                    <div className="col-md-4 mb-2" key={el}>
                                        <label htmlFor={'vat' + el + '_id'}>IVA {el}</label>
                                        <div className="input-group">
                                            <input type="text" id={'vat' + el + '_id'} className="form-control" value={data['vat' + el]?.name ?? ''} onFocus={() => setShowPersianaVat(el)} readOnly />
                                            <div className="input-group-append">
                                                <div className="input-group-append">
                                                    <button className="btn btn-default" type="button" onClick={() => setShowPersianaVat(el)}><i className="fa fa-search"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        { (errors && errors['vat' + el + '_id']) &&
                                            <div className="invalid-feedback d-block">
                                                { errors['vat' + el + '_id'][Object.keys(errors['vat' + el + '_id'])[0]] ?? '' }    
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="modal-footer d-block">
                        <button type="button" id="save" className="btn btn-default float-right" onClick={(e) => saveData(e)}><i className="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </Modal>
    );  
}