import React from 'react';
import axios from 'axios';
import moment from 'moment'

import './UniffiPricesModal.css';
import Persiana from '../../Components/Persiana/Persiana';
import qs from 'qs';

class UniffiPricesModal extends React.Component {

	dataToSave = {
		id: '',
		monthyear: '',
		type: '',
		agent_id: '',
		agent_name: '',
	}

	state = {
		formData: {...this.dataToSave},
		persianas: {
			agent: false,
		},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.modalInputDateRef = React.createRef();
		this.modalInputAgentRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.modalInputDateRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "mm-yyyy",
			autoclose: true,
			minViewMode: 1,
    		maxViewMode: 2
		}).on('changeDate', (e) => {
	      	self.setState((state) => {
	      		state.formData.monthyear = e.format(null, 'yyyy-mm');
	      		return state;
	      	});
	    });

		// Get data
		if ( self.props.id != null ) self.loadModalData(self.props.id);
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.modalRef.current).modal('hide');

		// Destroy date picker
		window.$(self.modalInputDateRef.current).datepicker('method', 'destroy');
	}

	componentDidUpdate() {
		const self = this;

		// Set date on datepicker
		if ( self.state.formData.monthyear ) window.$(self.modalInputDateRef.current).datepicker('update', moment(self.state.formData.monthyear).format('MM-YYYY'));
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		await axios.get(
			window.API_URL + '/uniffiprices/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// Update state
			self.setState((state) => {
				// Set data
				state.formData = response.data.data;

				// Fix to fill some inputs
				state.formData.agent_name = state.formData.agent ? state.formData.agent.name : '';

				return state;
			});
		});
	}

	async deleteModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Ask
		let c = window.confirm('¿Seguro que quieres eliminar esta recepción?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/uniffiprices/delete',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			self.closeModal();
		});
	}

	async sendEmail(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/uniffiprices/sendEmail',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Alert
			if ( response.data.error ) window.alert(response.data.error);
			else window.alert('Email enviado');
		});
	}

	async printPdf(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/uniffiprices/pdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];

		// Save ajax
		axios.post(
			window.API_URL + '/uniffiprices/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	mountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	inputAgentSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.agent_id = data ? data.id : '';
			state.formData.agent_name = data ? data.name : '';
			return state;
		});
	}

	handleInputRadioChange(target) {
		const self = this;

		self.setState((state) => {
			state.formData.type = target.value;
			return state;
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.agent === true &&
					<Persiana 
						title="Agentes" 
						url={window.API_URL + '/agents/all'} 
						fields={['name']}
						unmount={() => self.unmountPersiana('agent')} 
						selectCallback={(data) => self.inputAgentSelectCallback(data)} 
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef} id="uniffiPricesModal">
					<div className="modal-dialog modal-md" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.7 Blog</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-6 text-center">
										<div className="form-group">
											<label htmlFor="type_uniffimix">Uniffi</label>
											<input type="radio" id="type_uniffimix" name="type" value="uniffimix" checked={self.state.formData.type === "uniffimix" ? true : false} onChange={(event) => self.handleInputRadioChange(event.target)} />
										</div>
										{ self.state.errors.type && 
											<div className="error">{ self.state.errors.type[Object.keys(self.state.errors.type)[0]] }</div>
										}
									</div>
									<div className="col-md-6">
										<div className="form-group text-center">
											<label htmlFor="type_uniffi">M. Prima</label>
											<input type="radio" id="type_uniffi" name="type" value="uniffi" checked={self.state.formData.type === "uniffi" ? true : false} onChange={(event) => self.handleInputRadioChange(event.target)} />
										</div>
										{ self.state.errors.type && 
											<div className="error">{ self.state.errors.type[Object.keys(self.state.errors.type)[0]] }</div>
										}
									</div>

									<div className="col-md-12">
										<div className="form-group">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text">Fecha</span>
												</div>
												<input type="text" className="form-control text-right" ref={self.modalInputDateRef} />
											</div>
										</div>
										{ self.state.errors.provider_id && 
											<div className="error">{ self.state.errors.provider_id[Object.keys(self.state.errors.provider_id)[0]] }</div>
										}
									</div>

									<div className="col-md-12">
										<div className="form-group">
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text">Agente</span>
												</div>
												<input type="text" id="agent_name" className="form-control" ref={self.modalInputAgentRef} value={self.state.formData.agent_name} onFocus={() => self.mountPersiana('agent')} readOnly />
												<div className="input-group-append">
													<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('agent')}><i className="fa fa-search"></i></button>
												</div>
											</div>
										</div>
										{ self.state.errors.provider_id && 
											<div className="error">{ self.state.errors.provider_id[Object.keys(self.state.errors.provider_id)[0]] }</div>
										}
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								{ !self.state.formData.id &&
									<button type="button" className="btn btn-default float-right" ref={self.btnSaveRef} onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>
								}

								{ self.state.formData.id &&
									<React.Fragment>
										<button type="button" className="btn btn-default float-left" tabIndex="-1" onClick={(event) => self.deleteModalData(event)}><i className="fa fa-minus-circle"></i></button>
										<button type="button" className="btn btn-default float-right" onClick={(event) => self.printPdf(event)}><i className="fa fa-file"></i></button>
										<button type="button" className="btn btn-default float-right" onClick={(event) => self.sendEmail(event)}><i className="fa fa-envelope"></i></button>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default UniffiPricesModal;
