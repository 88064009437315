import React from 'react';
import axios from 'axios';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

import Parrilla from './UniffiParrilla/Parrilla';
import UniffiModal from './UniffiModal';

class UniffiPage extends React.Component {

	state = {
		rows: [],
		page: 1,
		nextPage: null,
		prevPage: null,
		lastPage: null,
		sort: 'code',
		direction: 'asc',
		field: null,
		query: null,
		modalOpened: false,
		modalId: ''
	}

	async componentDidMount() {
		const self = this;

		// Execute initial actions
		await self.loadParrillaData();
	}

	async loadParrillaData() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		await axios.get(
			window.API_URL + '/uniffi/list',
			{	
				params: {
					page: self.state.page,
					sort: self.state.sort,
					direction: self.state.direction,
					field: self.state.field,
					query: self.state.query
				},
				headers: authHeaders
			}
		).then((response) => {
			self.setState((state) => {
				if ( response.data ) {
					state.rows = response.data.rows;
					state.page = response.data.page;
					state.nextPage = response.data.nextPage;
					state.prevPage = response.data.prevPage;
					state.lastPage = response.data.lastPage;
				}
				return state;
			});
		});
	}

	parrillaSortCallback(key) {
		const self = this;

		// Update State
		self.state.sort = key;
		self.state.direction = self.state.direction === 'asc' ? 'desc' : 'asc';

		// Reload data
		self.loadParrillaData();
	}

	parrillaChangePageCallback(page) {
		const self = this;

		// Set page
		self.state.page = page;

		// Reload data
		self.loadParrillaData();
	}

	parrillaSearchCallback(field, query) {
		const self = this;

		// Set page
		self.state.field = field;
		self.state.query = query;

		// Reload data
		self.loadParrillaData();
	}

	openModal(event, idx = null) {
		const self = this;

		// Prevent
		if ( event ) event.preventDefault();

		// Set modal opened
		self.setState((state) => {
			state.modalOpened = true;
			state.modalId = idx !== null ? self.state.rows[idx].id : null;
			return state;
		});
	}

	closeModal() {
		const self = this;

		// Set modal opened
		self.setState((state) => {
			state.modalOpened = false;
			state.modalId = '';
			return state;
		});

		// Reload parrilla
		self.loadParrillaData();
	}

	async updatePriceBuyOnRow(value, idx) {
		const self = this;

		// Get id and update
		let id = self.state.rows[idx].id;
		if ( !id ) return;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Update
		axios.post(
			window.API_URL + '/uniffi/updatePriceBuy',
			qs.stringify({id: id, price: value}),
			{headers: authHeaders}
		).then((response) => {
			self.loadParrillaData();
		});
	}

	render() {
		const self = this;

		// Prepare header
		let header = [
			{ text: 'Código', key: 'Uniffi.code', width: 100 },
			{ text: 'Descripción', key: 'Uniffi.description', width: 200 },
			{ text: 'P.Compra', key: 'Uniffi.price_buy', width: 20 },
			{ text: 'Beneficio', key: 'Uniffi.profit', width: 20 },
			{ text: 'P.Venta', key: 'Uniffi.price_sale', width: 20 },
			{ text: 'Proteina B', key: 'Uniffi.protein_percent', width: 100 },
			{ text: 'Fibra B', key: 'Uniffi.fiber_percent', width: 70 },
			{ text: 'Aceite B', key: 'Uniffi.fat_percent', width: 80 },
			{ text: 'Ceniza B', key: 'Uniffi.ashes_percent', width: 80 }
		];

		// Prepare rows
		let rows = [];
		if ( self.state.rows ) self.state.rows.forEach((el, idx) => {
			let tmpRow = [];
			tmpRow.push(
				{text: el.code, align: 'left'},
				{text: el.description, align: 'left'},
				{text: formatNumber(el.price_buy, {precision: 2, thousand: '.', decimal: ','}), align: 'right', updateable: true},
				{text: formatNumber(el.profit, {precision: 2, thousand: '.', decimal: ','}), align: 'right'},
				{text: formatNumber(el.price_sale, {precision: 2, thousand: '.', decimal: ','}), align: 'right'},
				{text: formatNumber(el.protein_percent, {precision: 2, thousand: '.', decimal: ','}), align: 'right'},
				{text: formatNumber(el.fiber_percent, {precision: 2, thousand: '.', decimal: ','}), align: 'right'},
				{text: formatNumber(el.fat_percent, {precision: 2, thousand: '.', decimal: ','}), align: 'right'},
				{text: formatNumber(el.ashes_percent, {precision: 2, thousand: '.', decimal: ','}), align: 'right'},

			);
			rows.push(tmpRow);
		});

		// Prepare searchFields
		let searchFields = [
			{ text: 'Código', key: 'Uniffi.code' },
			{ text: 'Descripción', key: 'Uniffi.description' }
		];

		// Render
		return (
			<React.Fragment>
				<Parrilla 
					title="50.6 M.P.Uniffi"

					header={header} 
					rows={rows} 

					sortCallback={(key) => self.parrillaSortCallback(key)}
					clickRowCallback={(idx) => self.openModal(null, idx)}

					paginator={{page: self.state.page, prevPage: self.state.prevPage, nextPage: self.state.nextPage, lastPage: self.state.lastPage}}
					pageChangeCallback={(page) => self.parrillaChangePageCallback(page)}

					btnAdd={true}
					btnAddCallback={(event) => self.openModal(event, null)}

					btnSearch={true}
					searchFields={searchFields}
					clickSearchCallback={(field, query) => self.parrillaSearchCallback(field, query)}

					updatePriceBuyOnRow={(value, idx) => self.updatePriceBuyOnRow(value, idx)}
				></Parrilla>
				{ self.state.modalOpened === true &&
					<UniffiModal 
						id={self.state.modalId}
						close={() => self.closeModal()}
					/>
				}	
			</React.Fragment>
		);
	}
}

export default UniffiPage;
