import React from 'react';
import axios from 'axios';
import moment from 'moment'

import Persiana from '../../Components/Persiana/Persiana';
import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

class SemillasReceptionsModal extends React.Component {

	dataToSave = {
		id: '',

		date: '',
		number: '',

		provider_id: '',
		provider_name: '',

		article_id: '',
		article_name: '',
		
		transporter_id: '',
		transporter_name: '',
		
		driver_id: '',
		driver_name: '',

		origin_parcel_id: '',
		origin_parcel_name: '',
		origin_gross: '',
		origin_tare: '',
		origin_net: '',

		destination_parcel_id: '',
		destination_parcel_name: '',
		destination_gross: '',
		destination_tare: '',
		destination_net: '',
	}

	state = {
		formData: {...this.dataToSave},
		persianas: {
			provider: false,
			article: false
		},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.modalRef = React.createRef();
		this.modalInputDateRef = React.createRef();
		this.modalInputOriginGrossRef = React.createRef();
		this.modalInputOriginTareRef = React.createRef();
		this.modalInputDestinationGrossRef = React.createRef();
		this.modalInputDestinationTareRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Init date picker
		window.$(self.modalInputDateRef.current).datepicker({
			language: "es",
			orientation: "bottom auto",
			format: "dd-mm-yyyy",
			autoclose: true
		}).on('changeDate', (e) => {
	      	self.setState((state) => {
	      		state.formData.date = e.format(null, 'yyyy-mm-dd');
	      		return state;
	      	});
	    });

		// Get data
		if ( self.props.id != null ) self.loadModalData(self.props.id);
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.modalRef.current).modal('hide');

		// Destroy date picker
		window.$(self.modalInputDateRef.current).datepicker('method', 'destroy');
	}

	componentDidUpdate() {
		const self = this;

		// Set date on datepicker
		if ( self.state.formData.date ) window.$(self.modalInputDateRef.current).datepicker('update', moment(self.state.formData.date).format('DD-MM-YYYY'));
	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		await axios.get(
			window.API_URL + '/semillasreceptions/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// Update state
			self.setState((state) => {
				// Set data
				state.formData = response.data.data;

				// Fix to fill some inputs
				state.formData.date = moment(state.formData.date).format('YYYY-MM-DD');
				state.formData.provider_name = state.formData.farmer ? state.formData.farmer.name : '';
				state.formData.provider_id = state.formData.farmer ? state.formData.farmer.id : '';
				state.formData.article_name = state.formData.article ? state.formData.article.description : '';
				state.formData.transporter_name = state.formData.transporter ? state.formData.transporter.name : '';
				state.formData.driver_name = state.formData.driver ? state.formData.driver.name + ' ' + state.formData.driver.mtractora : '';
				state.formData.origin_parcel_name = state.formData.origin_parcel ? state.formData.origin_parcel.place : '';
				state.formData.destination_parcel_name = state.formData.destination_parcel ? state.formData.destination_parcel.place : '';

				return state;
			});

			self.modalInputOriginGrossRef.current.value = formatNumber(response.data.data.origin_gross, {precision: 3, thousand: ".", decimal: ","});
			self.modalInputOriginTareRef.current.value = formatNumber(response.data.data.origin_tare, {precision: 3, thousand: ".", decimal: ","});
			self.modalInputDestinationGrossRef.current.value = formatNumber(response.data.data.destination_gross, {precision: 3, thousand: ".", decimal: ","});
			self.modalInputDestinationTareRef.current.value = formatNumber(response.data.data.destination_tare, {precision: 3, thousand: ".", decimal: ","});
		});
	}

	async deleteModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Ask
		let c = window.confirm('¿Seguro que quieres eliminar esta recepción?');
		if ( !c ) return false;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Delete ajax
		axios.post(
			window.API_URL + '/semillasreceptions/delete',
			qs.stringify({id: self.state.formData.id}),
			{ headers: authHeaders }
		).then((response) => {
			// Close modal
			self.closeModal();
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {
			'id':								self.state.formData.id,
			'who_id': 							self.state.formData.provider_id,
			'what_id': 							self.state.formData.article_id,
			'transporter_id': 					self.state.formData.transporter_id,
			'driver_id': 						self.state.formData.driver_id,
			'date': 							self.state.formData.date,
			'semilla_origin_parcel_id': 		self.state.formData.origin_parcel_id,
			'semilla_origin_gross':				self.state.formData.origin_gross,
			'semilla_origin_tare': 				self.state.formData.origin_tare,
			'semilla_destination_parcel_id': 	self.state.formData.destination_parcel_id,
			'semilla_destination_gross': 		self.state.formData.destination_gross,
			'semilla_destination_tare':			self.state.formData.destination_tare,
			'semilla_destination_net': 			self.state.formData.destination_net,
		};

		// Save ajax
		axios.post(
			window.API_URL + '/semillasreceptions/save',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		document.activeElement.blur();
	}

	handleInputChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}


	mountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = true;
			return state;
		});
	}

	unmountPersiana(type) {
		const self = this;

		// Change state to open persiana
		self.setState((state) => {
			state.persianas[type] = false;
			return state;
		});
	}

	inputProviderSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.provider_id = data ? data.id : '';
			state.formData.provider_name = data ? data.name : '';
			return state;
		});
	}

	inputArticleSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.article_id = data ? data.id : '';
			state.formData.article_name = data ? data.description : '';
			return state;
		});
	}

	inputTransportersSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.transporter_id = data ? data.id : '';
			state.formData.transporter_name = data ? data.name : '';
			return state;
		});
	}

	inputDriversSelectCallback(data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData.driver_name = data ? data.name + ' ' + data.mtractora : '';
			state.formData.driver_id = data?.id;
			return state;
		});
	}

	inputParcelaSelectCallback(type, data) {
		const self = this;

		// Set value to state
		self.setState((state) => {
			state.formData[type + '_parcel_name'] = data ? data.place + ' ' + data.parcel : '';
			state.formData[type + '_parcel_id'] = data?.id;
			return state;
		});
	}

	formatNumberThousands(number) {
		// Delete points
		number = (number+"").replace(/\./g,'');
		
		// If number, format
		if ( number && !isNaN(number) ) return formatNumber(number, {precision: 0, thousand: ".", decimal: ""});

		// If empty, return again
		return number;
	}

	handleInputThousandsChange(event, field) {
		const self = this;

		// Get value
		let value = event.target.value.replace(/\./g,'').replace(/\D/g, '');

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	formatNumberTons(number, ref) {
		const self = this;

		// Delete points
		number = number.replace(/\./g,'').replace(',', '.');
		number = parseFloat(number);
		
		// If number, format
		if ( isNaN(number) || number === 0 ) return;
		
		// Set value
		self[ref].current.value = formatNumber(number, {precision: 3, thousand: "", decimal: "."});
	}

	handleInputTonsChange(event, field, ref) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		self[ref].current.value = formatNumber(value, {precision: 3, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	async printPdf() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Look for closest on api
		axios.get(window.API_URL + '/semillasreceptions/pdf', {
			params: { id: self.state.formData.id },
			headers: authHeaders
		}).then(async (res) => {
			window.ipcRenderer.sendSync('printPdf', {data: res.data.pdfData});
		}).catch((error) => {
			window.alert("Ha ocurrido un error, por favor inténtelo de nuevo");
		});
	}

	render() {
		const self = this;

		// Render
		return (
			<React.Fragment>
				{ self.state.persianas.provider === true &&
					<Persiana 
						title="Agricultores" 
						url={window.API_URL + '/providers/all'} 
						fields={['name']} 
						params={{agro: true}}
						unmount={() => self.unmountPersiana('provider')} 
						selectCallback={(data) => self.inputProviderSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.article === true &&
					<Persiana 
						title="Artículos" 
						url={window.API_URL + '/articles/all'} 
						fields={['description']} 
						params={{semilla: true}}
						unmount={() => self.unmountPersiana('article')} 
						selectCallback={(data) => self.inputArticleSelectCallback(data)} 
					/>
				}

				{ self.state.persianas.transporter === true &&
					<Persiana 
						title="Transportistas" 
						url={window.API_URL + '/transporters/all'} 
						fields={['name']} 
						unmount={() => self.unmountPersiana('transporter')} 
						selectCallback={(data) => self.inputTransportersSelectCallback(data)} 
						canCreate={'transporters'}
					/>
				}
				{ self.state.persianas.driver === true &&
					<Persiana 
						title="Conductores" 
						url={window.API_URL + '/drivers/all' + (self.state.formData.who === 'agricultor' ? '?tractorista=true' : '')} 
						fields={['name', 'mtractora']} 
						unmount={() => self.unmountPersiana('driver')} 
						selectCallback={(data) => self.inputDriversSelectCallback(data)} 
						dataFilter={(data) => {
							return data.filter((el, idx) => {
								return el.name.trim().length > 0;
							});
						}}
						canCreate={'drivers'}
					/>
				}
				{ self.state.persianas.parcel &&
					<Persiana 
						title="Parcelas" 
						url={window.API_URL + '/parcels/all'} 
						params={{farmer_id: self.state.formData.provider_id}}
						fields={['place', 'parcel', 'polygon', 'municipality']} 
						unmount={() => self.unmountPersiana('parcel')} 
						selectCallback={(data) => self.inputParcelaSelectCallback(self.state.persianas.parcel, data)} 
						canCreate={'parcel'}
					/>
				}

				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.0 Recepción Semillas</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="provider_name">Agricultor</label>
											<div className="input-group">
												<input type="text" id="provider_name" className="form-control" value={self.state.formData.provider_name} onFocus={() => self.mountPersiana('provider')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('provider')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.provider_id && 
											<div className="error">{ self.state.errors.provider_id[Object.keys(self.state.errors.provider_id)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="article_name">Semilla</label>
											<div className="input-group">
												<input type="text" id="article_name" className="form-control" value={self.state.formData.article_name} onFocus={() => self.mountPersiana('article')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('article')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.article_id && 
											<div className="error">{ self.state.errors.article_id[Object.keys(self.state.errors.article_id)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="date">Fecha</label>
											<input type="text" id="date" className="form-control text-right" ref={self.modalInputDateRef} onChange={(event) => self.handleInputChange(event, 'date')}  value={self.state.formData.date} />
										</div>
										{ self.state.errors.date && 
											<div className="error">{ self.state.errors.date[Object.keys(self.state.errors.date)[0]] }</div>
										}
									</div>
									{/* <div className="col-md-4">
										<div className="form-group">
											<label htmlFor="transporter_name">Transportista</label>
											<div className="input-group">
												<input type="text" id="transporter_name" className="form-control" value={self.state.formData.transporter_name} onFocus={() => self.mountPersiana('transporter')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('transporter')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.transporter_id && 
											<div className="error">{ self.state.errors.transporter_id[Object.keys(self.state.errors.transporter_id)[0]] }</div>
										}
									</div> */}
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="driver_name">Conductor</label>
											<div className="input-group">
												<input type="text" id="driver_name" className="form-control" value={self.state.formData.driver_name} onFocus={() => self.mountPersiana('driver')} readOnly />
												<div className="input-group-append">
													<div className="input-group-append">
														<button className="btn btn-default" type="button" onClick={() => self.mountPersiana('driver')}><i className="fa fa-search"></i></button>
													</div>
												</div>
											</div>
										</div>
										{ self.state.errors.driver_id && 
											<div className="error">{ self.state.errors.driver_id[Object.keys(self.state.errors.driver_id)[0]] }</div>
										}
									</div>

									<div className="col-md-4">
										<div className="form-group">
											<label>LQ Administracion</label>
											<div className="form-control text-center bg-light">
												<input type="checkbox" />
											</div>
										</div>
									</div>

									<div className="col-md-4">
										<div className="form-group">
											<label>LQ Agricultor</label>
											<div className="form-control text-center bg-light">
												<input type="checkbox" />
											</div>
										</div>
									</div>
									
									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label>Origen</label>
												<input type="text" name="semilla_number" value={self.state.formData.number ?? ''} className="form-control text-right" readOnly />				
											</div>
											<div className="col-md-4">
												<label>Parcela</label>
												<div className="input-group">
													<input 
														type="text" 
														className="form-control" 
														value={self.state.formData.origin_parcel_name ?? ''} 
														onFocus={(event) => {
															// Change state to open persiana
															self.setState((state) => {
																state.persianas['parcel'] = 'origin';
																return state;
															});
														}} 
														readOnly
													/>
													<div className="input-group-append">
														<button 
															className="btn btn-default" 
															type="button" 
															onClick={(event) => {
																// Change state to open persiana
																self.setState((state) => {
																	state.persianas['parcel'] = 'origin';
																	return state;
																});
															}} 
														>
															<i className="fa fa-search"></i>
														</button>
													</div>
												</div>
												{ self.state.errors.origin_parcel_id && 
													<div className="error">{ self.state.errors.origin_parcel_id[Object.keys(self.state.errors.origin_parcel_id)[0]] }</div>
												}
											</div>
											<div className="col-md-2">
												<label>Bruto</label>
												<input 
													type="text" 
													className="form-control text-right"
													name="semilla_origin_gross" 
													ref={self.modalInputOriginGrossRef}
													onBlur={(event) => self.handleInputTonsChange(event, 'origin_gross', 'modalInputOriginGrossRef')} 
													onFocus={(event) => {
														self.formatNumberTons(event.target.value, 'modalInputOriginGrossRef');
														event.target.select()
													}}
												/>		
											</div>
											<div className="col-md-2">
												<label>Tara</label>
												<input 
													type="text" 
													className="form-control text-right" 
													name="semilla_origin_tare" 
													ref={self.modalInputOriginTareRef}
													onBlur={(event) => self.handleInputTonsChange(event, 'origin_tare', 'modalInputOriginTareRef')} 
													onFocus={(event) => {
														self.formatNumberTons(event.target.value, 'modalInputOriginTareRef');
														event.target.select()
													}}
												/>		
											</div>
											<div className="col-md-2">
												<label>Entrada</label>
												<input 
													type="text" 
													name="origin_tare"
													value={formatNumber((self.state.formData.origin_gross ?? 0) - (self.state.formData.origin_tare ?? 0), {precision: 3, thousand: ".", decimal: ","})}
													className="form-control text-right" 
													readOnly 
												/>		
											</div>
										</div>
									</div>
									<div className="col-md-12" style={{marginTop: '20px'}}>
										<div className="row">
											<div className="col-md-2">
												<label>Destino</label>
												<input type="text" name="semilla_number" value={self.state.formData.number ?? ''} className="form-control text-right" readOnly />				
											</div>
											<div className="col-md-4">
												<label>Parcela</label>
												<div className="input-group">
													<input 
														type="text" 
														className="form-control" 
														value={self.state.formData.destination_parcel_name ?? ''} 
														readOnly
														onFocus={(event) => {
															// Change state to open persiana
															self.setState((state) => {
																state.persianas['parcel'] = 'destination';
																return state;
															});
														}} 
													/>
													<div className="input-group-append">
														<button 
															className="btn btn-default" 
															type="button" 
															onClick={(event) => {
																// Change state to open persiana
																self.setState((state) => {
																	state.persianas['parcel'] = 'destination';
																	return state;
																});
															}} 
														>
															<i className="fa fa-search"></i>
														</button>
													</div>
												</div>
												
											</div>
											<div className="col-md-2">
												<label>Bruto</label>
												<input 
													type="text" 
													className="form-control text-right"
													name="semilla_destination_gross" 
													ref={self.modalInputDestinationGrossRef}
													onBlur={(event) => self.handleInputTonsChange(event, 'destination_gross', 'modalInputDestinationGrossRef')} 
													onFocus={(event) => {
														self.formatNumberTons(event.target.value, 'modalInputDestinationGrossRef');
														event.target.select()
													}}
												/>		
											</div>
											<div className="col-md-2">
												<label>Tara</label>
												<input 
													type="text" 
													className="form-control text-right" 
													name="semilla_destination_tare" 
													ref={self.modalInputDestinationTareRef}
													onBlur={(event) => self.handleInputTonsChange(event, 'destination_tare', 'modalInputDestinationTareRef')} 
													onFocus={(event) => {
														self.formatNumberTons(event.target.value, 'modalInputDestinationTareRef');
														event.target.select()
													}}
												/>		
											</div>
											<div className="col-md-2">
												<label>Salida</label>
												<input 
													type="text" 
													name="destination_tare" 
													value={formatNumber((self.state.formData.destination_gross ?? 0) - (self.state.formData.destination_tare ?? 0), {precision: 3, thousand: ".", decimal: ","})}
													className="form-control text-right" 													
													readOnly 
												/>		
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" className="btn btn-default float-right" onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>

								{ self.state.formData.id &&
									<React.Fragment>
										{/* <button type="button" className="btn btn-default float-left" tabIndex="-1" onClick={(event) => self.deleteModalData(event)}><i className="fa fa-minus-circle"></i></button> */}
										<button type="button" className="btn btn-default float-right mr-2" tabIndex="-1" onClick={(event) => self.printPdf()}><i className="fa fa-print"></i></button>
									</React.Fragment>
								}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default SemillasReceptionsModal;
