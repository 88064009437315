import React from 'react';

class Panel extends React.Component {
	render() {
		const self = this;

		return (
			<div className="card">
				{self.props.children}
			</div>
		);
	}
}

class PanelHeader extends React.Component {
	render() {
		const self = this;
		return (
			<div className="card-header">
				{self.props.children}
			</div>
		);
	}
}

class PanelBody extends React.Component {
	render() {
		const self = this;
		return (
			<div className="card-body">
				{self.props.children}
			</div>
		);
	}
}

class PanelFooter extends React.Component {
	render() {
		const self = this;

		return (
			<div className={"card-footer " + self.props.className}>
				{self.props.children}
			</div>
		);
	}
}

export {
	Panel,
	PanelHeader,
	PanelBody,
	PanelFooter
}