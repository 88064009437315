import React from 'react';
import axios from 'axios';

import formatNumber from 'accounting-js/lib/formatNumber.js';
import qs from 'qs';

class ArticlesModal extends React.Component {

	dataToSave = {
		id: '',
		code: '',
		description: '',
		internaltraffic_pricebuy: '',
		internaltraffic_checkbox: false,
	}

	state = {
		formData: {...this.dataToSave},
		errors: {}
	}

	constructor(props) {
		super(props);

		// Refs
		this.btnSaveRef = React.createRef();
		this.modalRef = React.createRef();
		this.modalInputCodeRef = React.createRef();
		this.modalInputDescriptionRef = React.createRef();
		this.modalInputItPriceRef = React.createRef();
		this.modalInputItCheckboxRef = React.createRef();
	}

	async componentDidMount() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal({backdrop: 'static', keyboard: false});

		// Get data
		if ( self.props.id != null ) self.loadModalData(self.props.id);
	}

	componentWillUnmount() {
		const self = this;

		// Hide boostrap modal
		window.$(self.modalRef.current).modal('hide');
	}

	componentDidUpdate() {

	}

	closeModal() {
		const self = this;

		// Show modal
		window.$(self.modalRef.current).modal('hide');

		// Close
		self.props.close();
	}

	async loadModalData(id) {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get data
		await axios.get(
			window.API_URL + '/articles/get',
			{	
				params: { id: id },
				headers: authHeaders
			}
		).then((response) => {
			// Update state
			self.setState((state) => {
				// Set data
				state.formData = {
					id: response.data.data.id,
					code: response.data.data.code,
					description: response.data.data.description,
					internaltraffic_pricebuy: response.data.data.internaltraffic_pricebuy,
					internaltraffic_checkbox: response.data.data.internaltraffic_checkbox ? 1 : 0
				};
				return state;
			});

			// Numbers Fix
			self.modalInputItPriceRef.current.value = formatNumber(response.data.data.internaltraffic_pricebuy, {precision: 2, thousand: ".", decimal: ","});
		});
	}

	async saveModalData(event) {
		const self = this;

		// Prevent
		event.preventDefault();

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Prepare data
		let data = {};
		for (let i in self.dataToSave) data[i] = self.state.formData[i];
		data.internaltraffic_pricebuy = formatNumber(data.internaltraffic_pricebuy, {precision: 2, thousand: "", decimal: "."});

		// Save ajax
		axios.post(
			window.API_URL + '/articles/save-internaltraffic',
			qs.stringify(data),
			{ headers: authHeaders }
		).then((response) => {
			self.setState((state) => {
				// No ok, Show errors
				if ( response.data && response.data.errors ) state.errors = response.data.errors;

				// If id, set
				if ( response.data && response.data.id && !response.data.errors.length ) {
					state.formData.id = response.data.id;
				}

				return state;
			});
		});

		// Blur save button
		self.btnSaveRef.current.blur();
	}

	handleInputChange(event, field, value) {
		const self = this;

		// Update
		self.setState((state) => {
			state.formData[field] = value;
			return state;
		});
	}

	handleInputPriceBuyChange(event) {
		const self = this;

		// Get value
		let value = parseFloat(event.target.value);
		if ( isNaN(value) ) return;

		// Set value
		self.modalInputItPriceRef.current.value = formatNumber(value, {precision: 2, thousand: ".", decimal: ","});

		// Update state
		self.setState((state) => {
			state.formData['internaltraffic_pricebuy'] = value;
			return state;
		});
	}


	render() {
		const self = this;

		console.clear();
		console.table(self.state.formData)

		// Render
		return (
			<React.Fragment>
				<div className="modal" tabIndex="-1" role="dialog" ref={self.modalRef}>
					<div className="modal-dialog modal-lg" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">50.0 Tráfico Agro</h5>
								<button type="button" className="close" onClick={() => self.closeModal()}>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<div className="row">
												<div className="col-6">
													<label htmlFor="ti">Tráfico Agro</label>
												</div>
												<div className="col-6 text-right">
													<label htmlFor="code">Código</label>
												</div>
											</div>
											<div className="input-group">
												<div className="input-group-prepend">
													<div className="input-group-text">
														<input type="checkbox" id="ti" checked={self.state.formData.internaltraffic_checkbox} onChange={(event) => self.handleInputChange(event, 'internaltraffic_checkbox', event.target.checked ? 1 : 0)} />
													</div>
												</div>
												<input type="text" id="code" className="form-control text-right" defaultValue={self.state.formData.code} readOnly />
												{ self.state.errors.internaltraffic_checkbox && 
													<div className="error">{ self.state.errors.internaltraffic_checkbox[Object.keys(self.state.errors.internaltraffic_checkbox)[0]] }</div>
												}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="description">Descripción</label>
											<input type="text" id="description" className="form-control" ref={self.modalInputDescriptionRef} onChange={(event) => self.handleInputChange(event, 'description', event.target.value)}  value={self.state.formData.description} />
										</div>
										{ self.state.errors.description && 
											<div className="error">{ self.state.errors.description[Object.keys(self.state.errors.description)[0]] }</div>
										}
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label htmlFor="internaltraffic_pricebuy">Precio compra</label>
											<input type="text" id="internaltraffic_pricebuy" className="form-control text-right" ref={self.modalInputItPriceRef} onBlur={(event) => self.handleInputPriceBuyChange(event)} onClick={(event) => event.target.select()} />
											{ self.state.errors.internaltraffic_pricebuy && 
												<div className="error">{ self.state.errors.internaltraffic_pricebuy[Object.keys(self.state.errors.internaltraffic_pricebuy)[0]] }</div>
											}
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer d-block">
								<button type="button" className="btn btn-default float-right" ref={self.btnSaveRef} onClick={(event) => self.saveModalData(event)}><i className="fa fa-save"></i></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ArticlesModal;
