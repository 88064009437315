import React from 'react';
import axios from 'axios';
import moment from 'moment'

import Parrilla from '../../Components/Parrilla/Parrilla';
import UniffiMixesOrdersModal from './UniffiMixesOrdersModal';

class UniffiMixesOrdersPage extends React.Component {

	state = {
		rows: [],
		page: 1,
		nextPage: null,
		prevPage: null,
		lastPage: null,
		sort: 'UniffiMixesOrders.date',
		direction: 'DESC',
		field: null,
		query: null,
		modalOpened: false,
		modalId: ''
	}

	async componentDidMount() {
		const self = this;

		// Execute initial actions
		await self.loadParrillaData();
	}

	async loadParrillaData() {
		const self = this;

		// Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

		// Get token from python
		await axios.get(
			window.API_URL + '/uniffimixesorders/list',
			{	
				params: {
					page: self.state.page,
					sort: self.state.sort,
					direction: self.state.direction,
					field: self.state.field,
					query: self.state.query
				},
				headers: authHeaders
			}
		).then((response) => {
			self.setState((state) => {
				if ( response.data ) {
					state.rows = response.data.rows;
					state.page = response.data.page;
					state.nextPage = response.data.nextPage;
					state.prevPage = response.data.prevPage;
					state.lastPage = response.data.lastPage;
				}
				return state;
			});
		});
	}

	parrillaSortCallback(key) {
		const self = this;

		// Update State
		self.state.sort = key;
		self.state.direction = self.state.direction === 'asc' ? 'desc' : 'asc';

		// Reload data
		self.loadParrillaData();
	}

	parrillaChangePageCallback(page) {
		const self = this;

		// Set page
		self.state.page = page;

		// Reload data
		self.loadParrillaData();
	}

	parrillaSearchCallback(field, query) {
		const self = this;

		// Set page
		self.state.field = field;
		self.state.query = query;

		// Reload data
		self.loadParrillaData();
	}

	openModal(event, idx = null) {
		const self = this;

		// Prevent
		if ( event ) event.preventDefault();

		// Set modal opened
		self.setState((state) => {
			state.modalOpened = true;
			state.modalId = idx !== null ? self.state.rows[idx].id : null;
			return state;
		});
	}

	closeModal() {
		const self = this;

		// Set modal opened
		self.setState((state) => {
			state.modalOpened = false;
			state.modalId = '';
			return state;
		});

		// Reload parrilla
		self.loadParrillaData();
	}

	render() {
		const self = this;

		// Prepare header
		let header = [
			{ text: 'Fecha', key: 'UniffiMixesOrders.date', width: 100 },
			{ text: 'Código', key: 'UniffiMixesOrders.code', width: 100 },
			{ text: 'Artículo', key: 'UniffiMixesOrders.description', width: 100 },
			{ text: 'Cliente', key: 'Clients.name', width: 100 },
			{ text: 'Agente', key: 'Agents.name', width: 100 },
			{ text: 'Toneladas', key: 'UniffiMixesOrders.tons', width: 100 },
			{ text: 'Precio', key: 'UniffiMixesOrders.price_total', width: 100 },
		];

		// Prepare rows
		let rows = [];
		if ( self.state.rows ) self.state.rows.forEach((el, idx) => {
			let tmpRow = [];
			tmpRow.push(
				{text: moment(el.date).format('DD-MM-Y'), align: 'left'},
				{text: el.code, align: 'right'},
				{text: el.description, align: 'left'},
				{text: el.client ? el.client.name : '', align: 'left'},
				{text: el.agent ? el.agent.name : '', align: 'left'},
				{text: el.tons.toFixed(3), align: 'right'},
				{text: Math.round(el.price_total), align: 'right'}

			);
			rows.push(tmpRow);
		});

		// Prepare searchFields
		let searchFields = [
			{ text: 'Artículo', key: 'UniffiMixesOrders.description' },
			{ text: 'Código', key: 'UniffiMixesOrders.code' },
			{ text: 'Descripción', key: 'UniffiMixesOrders.description' },
			{ text: 'Cliente', key: 'Clients.name' },
			{ text: 'Agente', key: 'Agente.name' },
		];

		// Render
		return (
			<React.Fragment>
				<Parrilla 
					title="50.3 Pedidos"

					header={header} 
					rows={rows} 

					sortCallback={(key) => self.parrillaSortCallback(key)}
					clickRowCallback={(idx) => self.openModal(null, idx)}

					paginator={{page: self.state.page, prevPage: self.state.prevPage, nextPage: self.state.nextPage, lastPage: self.state.lastPage}}
					pageChangeCallback={(page) => self.parrillaChangePageCallback(page)}

					btnAdd={true}
					btnAddCallback={(event) => self.openModal(event, null)}

					btnSearch={true}
					searchFields={searchFields}
					clickSearchCallback={(field, query) => self.parrillaSearchCallback(field, query)}
				></Parrilla>	

				{ self.state.modalOpened === true &&
					<UniffiMixesOrdersModal 
						id={self.state.modalId}
						close={() => self.closeModal()}
					/>
				}			
			</React.Fragment>
		);
	}
}

export default UniffiMixesOrdersPage;
