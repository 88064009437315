import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import qs from 'qs';
import styled from 'styled-components';

const Modal = styled.div`
    z-index: 3000;
    background: rgba(1,1,1,0.5);
`;

export default function Drivers(props) {

    const modalRef = useRef(null);

    const [data, setData] = useState({});
    const [errors, setErrors] = useState(undefined);

    useEffect(() => {
        // Show modal
		window.$(modalRef.current).modal({backdrop: false, keyboard: false});
    }, []);

    const setDataField = (field, value) => {
        setData((prev) => ({
            ...prev,
            [field]: value
        }));
    }

    const saveData = async () => {
        // Auth Headers
		let authHeaders = await window.prepareAuthHeaders();

        let toSave = {...data};
        delete toSave.payment_method;

		// Ajax
		axios.post(
			window.API_URL + '/drivers/save',
			qs.stringify(toSave),
			{headers: authHeaders}
		).then((response) => {
            if ( response.data.status ) {
		        window.$(modalRef.current).modal('hide');
                props.closeCallback(response.data.driver);
            } else {
                setErrors({...response.data.errors});
            }
		});
    }

    const closeModal = () => {
        window.$(modalRef.current).modal('hide');
        props.closeCallback();
    }

    return (
        <Modal className="modal" tabIndex="-1" role="dialog" ref={modalRef}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Conductor</h5>
                        <button type="button" className="close" onClick={(e) => closeModal()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <label htmlFor="name" className="float-left">Nombre</label>
                                <label className="float-right">Tractorista</label>
                                <div className="input-group">
                                    <input type="text" className="form-control text-right" id="name" value={data.name ?? ''} onChange={(e) => setDataField('name', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.tractorista_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('tractorista_checkbox', e.target.checked ? 1 : 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['name']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['name'][Object.keys(errors['name'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="mtractora">M. Tractora</label>
                                <input type="text" className="form-control" id="mtractora" value={data.mtractora ?? ''} onChange={(e) => setDataField('mtractora', e.target.value)} />
                                { (errors && errors['mtractora']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['mtractora'][Object.keys(errors['mtractora'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="msremolque">M. Sremolque</label>
                                <input type="text" className="form-control" id="msremolque" value={data.msremolque ?? ''} onChange={(e) => setDataField('msremolque', e.target.value)} />
                                { (errors && errors['msremolque']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['msremolque'][Object.keys(errors['msremolque'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="vatnumber">Dni/Cif</label>
                                <input type="text" className="form-control" id="vatnumber" value={data.vatnumber ?? ''} onChange={(e) => setDataField('vatnumber', e.target.value)} />
                                { (errors && errors['vatnumber']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['vatnumber'][Object.keys(errors['vatnumber'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="address">Dirección</label>
                                <input type="text" className="form-control" id="address" value={data.address ?? ''} onChange={(e) => setDataField('address', e.target.value)} />
                                { (errors && errors['address']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['address'][Object.keys(errors['address'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="postalcode">C. Postal</label>
                                <input type="text" className="form-control text-right" id="postalcode" value={data.postalcode ?? ''} onChange={(e) => setDataField('postalcode', e.target.value)} />
                                { (errors && errors['postalcode']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['postalcode'][Object.keys(errors['postalcode'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="city">Ciudad</label>
                                <input type="text" className="form-control" id="city" value={data.city ?? ''} onChange={(e) => setDataField('city', e.target.value)} />
                                { (errors && errors['city']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['city'][Object.keys(errors['city'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="province">Provincia</label>
                                <input type="text" className="form-control" id="province" value={data.province ?? ''} onChange={(e) => setDataField('province', e.target.value)} />
                                { (errors && errors['province']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['province'][Object.keys(errors['province'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="country">País</label>
                                <input type="text" className="form-control" id="country" value={data.country ?? ''} onChange={(e) => setDataField('country', e.target.value)} />
                                { (errors && errors['country']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['country'][Object.keys(errors['country'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="phone">Teléfono</label>
                                <div className="input-group">
                                    <input type="text" className="form-control text-right" id="phone" value={data.phone ?? ''} onChange={(e) => setDataField('phone', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.phone_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('phone_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) setDataField('mobile_checkbox', 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['phone']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['phone'][Object.keys(errors['phone'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="mobile">Teléfono</label>
                                <div className="input-group">
                                    <input type="text" className="form-control text-right" id="mobile" value={data.mobile ?? ''} onChange={(e) => setDataField('mobile', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.mobile_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('mobile_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) setDataField('phone_checkbox', 0);
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['mobile']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['mobile'][Object.keys(errors['mobile'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="email">Email</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" id="email" value={data.email ?? ''} onChange={(e) => setDataField('email', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.email_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('email_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) {
                                                        setDataField('email2_checkbox', 0);
                                                        setDataField('mail_checkbox', 0);
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['email']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['email'][Object.keys(errors['email'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="email2">Email</label>
                                <div className="input-group">
                                    <input type="email" className="form-control" id="email2" value={data.email2 ?? ''} onChange={(e) => setDataField('email2', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.email2_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('email2_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) {
                                                        setDataField('email_checkbox', 0);
                                                        setDataField('mail_checkbox', 0);
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['email2']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['email2'][Object.keys(errors['email2'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>

                            <div className="col-md-4 mb-2">
                                <label htmlFor="mail">Correo</label>
                                <div className="input-group">
                                    <input type="text" className="form-control" id="mail" value={data.mail ?? ''} onChange={(e) => setDataField('mail', e.target.value)} />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <input 
                                                type="checkbox" 
                                                checked={data.mail_checkbox ?? 0} 
                                                onChange={(e) => {
                                                    setDataField('mail_checkbox', e.target.checked ? 1 : 0);
                                                    if ( e.target.checked === true ) {
                                                        setDataField('email_checkbox', 0);
                                                        setDataField('email2_checkbox', 0);
                                                    }
                                                }} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                { (errors && errors['mail']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['mail'][Object.keys(errors['mail'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            <div className="col-md-4 mb-2">
                                <label htmlFor="web">Web</label>
                                <input type="text" className="form-control" id="web" value={data.web ?? ''} onChange={(e) => setDataField('web', e.target.value)} />
                                { (errors && errors['web']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['web'][Object.keys(errors['web'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                            
                            <div className="col-md-4 mb-2">
                                <label htmlFor="observations">Observaciones</label>
                                <textarea className="form-control" id="observations" value={data.observations ?? ''} onChange={(e) => setDataField('observations', e.target.value)} />
                                { (errors && errors['observations']) &&
                                    <div className="invalid-feedback d-block">
                                        { errors['observations'][Object.keys(errors['observations'])[0]] ?? '' }    
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer d-block">
                        <button type="button" id="save" className="btn btn-default float-right" onClick={(e) => saveData(e)}><i className="fa fa-save"></i></button>
                    </div>
                </div>
            </div>
        </Modal>
    );  
}